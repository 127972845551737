<template>
  <div>
    <van-nav-bar left-arrow  @click-left="onClickLeft">
            <template #right>
                <van-icon name="cross" size=".36rem" />
            </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        onClickLeft(){
            this.$emit('goBack')
        }
    }
}
</script>

<style scoped lang="less">
/deep/ .van-icon {
    color: black;
    font-size: .36rem;
}

.van-hairline--bottom:after {
    border-bottom-width: 0;
}
</style>