<template>
  <div>
    <div class="box">
      <div class="header">
        <div class="row_1">{{ $t('app.secureCheckout') }}</div>
        <div class="row_2">{{ $t('app.secureCheckoutText') }}</div>
        <!-- <div class="row_3">{{ $t('app.Required') }} {{ buyQuantity }} {{ $t('app.human') }}</div> -->
      </div>
      <div class="par_box">
        <div class="l_sub_box">
          <el-form :model="customerObj" ref="emailForm" @submit.native.prevent label-position="top">
            <div class="traveler_one">{{ $t('app.liaisonDetail') }}</div>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('app.Email')" :rules="[{ required: true, message: $t('app.Please_email'), trigger: 'blur'}]" prop="email">
                  <div class="emailEntry">{{ $t('app.emailEntryHint') }}</div>
                  <el-input v-model="customerObj.email" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div v-for="(item,index) in orderCustomerDtoinfolist" :key="index">
            <el-form :model="item" :rules="rules" :ref="'ruleForm' + index" label-position="top">
              <div class="traveler_one">{{ $t('app.Traveler') }}{{ index + 1 }}</div>
              <el-row :gutter="20">
                <el-col :span="12" v-if="travelInfo.nameRequiredFlag === '1'">
                  <el-form-item :label="$t('app.customerName')" prop="customerName">
                    <el-input v-model="item.customerName" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.telephoneRequiredFlag === '1'">
                  <el-form-item :label="$t('app.customerTelephone')" prop="customerTelephone">
                    <div class="country_tel">
                      <el-select v-model="item.countryCode" filterable placeholder="" style="width: 83%">
                        <el-option
                          v-for="(item, index) in commonColumns"
                          :key="index"
                          :label="item.name"
                          :value="item.code"
                        />
                      </el-select>
                      <el-input oninput="value=value.replace(/[^\d]/g, '')" v-model="item.customerTelephone" />
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.emailRequiredFlag === '1'">
                  <el-form-item :label="$t('app.customerEmail')" prop="customerEmail">
                    <el-input v-model="item.customerEmail" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.wechatRequiredFlag === '1'">
                  <el-form-item :label="$t('app.customerWechat')" prop="customerWechat">
                    <el-input v-model="item.customerWechat" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.passportRequiredFlag === '1'">
                  <el-form-item :label="$t('app.passport')" prop="customerPassport">
                    <el-input v-model="item.customerPassport" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.sexRequiredFlag === '1'">
                  <el-form-item :label="$t('app.customerSex')" prop="customerSex">
                    <el-select v-model="item.customerSex" clearable style="width: 100%" placeholder="">
                      <!-- <el-option value="0">{{ $t('app.man') }}</el-option>
                      <el-option value="1">{{ $t('app.woman') }}</el-option> -->
                      <el-option
                        v-for="(item, index) in sexList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.useDateFlag === '1'">
                  <el-form-item :label="$t('app.customerUseDate')" prop="customerUseDate">
                    <el-date-picker
                      style="width: 100%"
                      v-model="item.customerUseDate"
                      type="date"
                      placeholder="">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.codeReuqiredFlag === '1'">
                  <el-form-item :label="$t('app.customerCode')" prop="customerCode">
                    <el-input v-model="item.customerCode" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.addressRequiredFlag === '1'">
                  <el-form-item :label="$t('app.customerAddress')" prop="customerAddress">
                    <el-input v-model="item.customerAddress" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.creditNoRequiredFlag === '1'">
                  <el-form-item :label="$t('app.customerCreditType')" prop="customerCreditType">
                    <el-select v-model="item.customerCreditType" clearable
                               style="width: 100%" placeholder="" @change="item.customerCreditNo = ''">
                      <el-option
                        v-for="item in creditTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.creditNoRequiredFlag === '1' && item.customerCreditType !== ''">
                  <el-form-item :label="$t('app.customerCreditNo')" prop="customerCreditNo">
                    <el-input v-model="item.customerCreditNo" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="travelInfo.receiveDateFlag === '1'">
                  <el-form-item :label="$t('app.customerReceiveDate')" prop="customerReceiveDate">
                    <el-date-picker
                      style="width: 100%"
                      v-model="item.customerReceiveDate"
                      type="date"
                      placeholder="">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <template v-if="travelInfo.customItemList.length > 0">
                  <el-col :span="12" v-for="(obj, i) in travelInfo.customItemList" :key="i">
                    <div v-if="obj.itemRequiredFlag === '1'">
                      <el-form-item :label="obj.itemName" :rules="obj.itemRequiredFlag === '1' ? [{
                                    required: true, message: $t('app.pleaseEnter') + `${obj.itemName}`, trigger: 'blur'
                                    }] : []" :prop="obj.itemRequiredFlag === '1' ? 'orderCustomCustomerItemDtolist.' + i + '.itemContent' : ''">
                        <el-input v-model="item.orderCustomCustomerItemDtolist[i].itemContent" />
                      </el-form-item>
                    </div>
                  </el-col>
                </template>
              </el-row>
            </el-form>
          </div>


          <!-- Contact person -->
          <!-- <div class="traveler_one">{{ $t('app.Contact_person') }}</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <p class="label">{{
                  $t('app.Full_name')
                }}</p>
              <van-field v-model="urname" type="text" placeholder=""
                        :rules="[{ required: true, message: $t('app.Please_name') }]"/>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p class="label">{{
                  $t('app.Email')
                }}</p>
              <a class="emailEntry">{{ $t('app.emailEntryHint') }}</a>
              <van-field v-model="email" placeholder=""
                        :rules="[{ required: true, message: $t('app.Please_email') }]"/>
            </el-col>
          </el-row> -->
          <div style="text-align: left;">
            <van-button @click="onSubmit">{{ $t('app.Next') }}</van-button>
          </div>
          <div class="popup_moeny">
            {{ $t("app.money") }} {{ priceFormat(money) }}
            <span class="tickets"> / {{ num }} {{ getTicket(num) }}</span>
            <span class="payment">{{ $t('app.paymentProtection') }}</span>
          </div>
          <!-- <div class="footer">
            <div class="footer_top">
              <div class="money">{{ $t('app.money') }} {{ money }}<span class="tickets"> /{{ num }}
                  tickets</span><span class="payment">Payment
                  Protection</span></div>
            </div>
            <div class="footer_bottom">
              <img src="../../assets/img/lightning.png" alt="" srcset="">
              Instant Confirmation
            </div>
          </div> -->
        </div>
        <div class="r_sub_box" v-if="reserveNotice">
          <el-card>
            <div slot="header" class="clearfix">{{ $t('app.reservation') }}</div>
            <div class="text_item" v-html="reserveNotice"></div>
          </el-card>
        </div>
      </div>
    </div>

    <!-- SingIn In -->
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="signInVisible"
    >
      <sing-in-dialog :preview="signInVisible" @loginSuccess="loginSuccess"/>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue';
import {NavBar, Form, Field, Divider, Button, Toast} from 'vant';
import apis from "../../utils/apis";
import { commonCountries } from "../contacts/countryCode";;
let {createOrderAndPay, TicketDetail} = apis
import SingInDialog from "@/components/Login/SingInDialog.vue";
import { decrypt, encrypt } from "../../utils/jsencrypt";
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(Divider);
Vue.use(Button);
Vue.use(Toast);


const createInfo = (orderCustomCustomerItemDtolist, orderCustomerSeq) => {
  // let sex = this.travelInfo.sexRequiredFlag == '1' ? '1':'';
  return {
    customerName: '',
    customerEmail: '',
    orderCustomerSeq,
    countryCode: '',
    customerTelephone: '',
    customerWechat: '',
    customerPassport: '',
    customerSex: "",
    customerUseDate: '',
    customerReceiveDate: '',
    customerCode: '',
    customerAddress: '',
    customerCreditType: '',
    customerCreditNo: '',
    orderCustomCustomerItemDtolist,
  };
};

export default {
  components: { SingInDialog },
  data() {
    return {
      urname: '',
      num: 1,
      customerObj: {
        email: localStorage.getItem('email') == 'null' || localStorage.getItem('email') == null ? '' : localStorage.getItem('email'),
      },
      buyQuantity: 1,
      orderCustomerDtoinfolist: [],
      travelInfo: {},
      money:"",
      signInVisible: false,
      creditTypeList: [
        {label: this.$t('app.identityCard'), value: 0},
        {label: this.$t('app.studentIDCard'), value: 1},
        {label: this.$t('app.militaryIDCard'), value: 2},
        {label: this.$t('app.passport'), value: 3},
        {label: this.$t('app.residenceBooklet'), value: 4},
        {label: this.$t('app.hongKongAndMacaoPass'), value: 5},
        {label: this.$t('app.taiwanSyndrome'), value: 6},
      ],
      rules: {
        customerName: [{ required: true, message: this.$t('app.customerNameRequired'), trigger: 'blur' }],
        customerTelephone: [{ required: true, message: this.$t('app.customerTelephoneRequired'), trigger: 'blur' }],
        customerEmail: [{ required: true, message: this.$t('app.Please_email'), trigger: 'blur' }],
        customerWechat: [{ required: true, message: this.$t('app.customerWechatRequired'), trigger: 'blur' }],
        customerPassport: [{ required: true, message: this.$t('app.customerPassportRequired'), trigger: 'blur' }],
        customerSex: [{ required: true, message: this.$t('app.customerSexRequired'), trigger: 'change' }],
        customerUseDate: [{ required: true, message: this.$t('app.customerUseDateRequired'), trigger: 'change' }],
        customerCode: [{ required: true, message: this.$t('app.customerCodeRequired'), trigger: 'blur' }],
        customerAddress: [{ required: true, message: this.$t('app.customerAddressRequired'), trigger: 'blur' }],
        customerCreditType: [{ required: true, message: this.$t('app.customerCreditTypeRequired'), trigger: 'change' }],
        customerCreditNo: [{ required: true, message: this.$t('app.customerCreditNoRequired'), trigger: 'blur' }],
        customerReceiveDate: [{ required: true, message: this.$t('app.customerReceiveDateRequired'), trigger: 'change' }],
      },
      commonColumns: [],
      sexList: [
        {label: this.$t('app.man'), value: '0'},
        {label: this.$t('app.woman'), value: '1'}
      ],
      reserveNotice: '',
    }
  },
  created() {
    this.commonColumns = this.getCommonColumns();

    this.travelInfo = JSON.parse(this.$route.query.contact);
    this.money = this.travelInfo.priceTypeList[0].totalAmount;
    this.num = this.travelInfo.priceTypeList[0].buyQuantity;
    this.travelInfo.customItemList = this.travelInfo.customItemList.map(({itemName, itemSeq, itemRequiredFlag}) =>
                                     (Object.assign({itemName, itemSeq, itemRequiredFlag}, {itemContent: ''})));
    // console.log(this.travelInfo,'----------')
    if(this.travelInfo.customerDataType === '1') {
      this.orderCustomerDtoinfolist.push(createInfo(JSON.parse(JSON.stringify(this.travelInfo.customItemList)), 1));
    } else {
      for (let i = 0; i < this.travelInfo.priceTypeList[0].buyQuantity; i++) {
        this.orderCustomerDtoinfolist.push(createInfo(JSON.parse(JSON.stringify(this.travelInfo.customItemList)), (i + 1)));
      }
    }
    // this.buyQuantity = this.orderCustomerDtoinfolist.length;

    this.ticket()
  },
  methods: {
    getTicket(num){
      if (!isNaN(num)) {
        return Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets')
      } else {
        return this.$t('app.tickets');
      }
    },
    getCommonColumns() {
      const lag = localStorage.getItem('language');
      return lag === null ? commonCountries['en'] : commonCountries[lag];
    },

    ticket() {
      TicketDetail({productId: this.travelInfo.productId}, data => {
        this.reserveNotice = data.data.reserveNotice;
        if(this.reserveNotice) {
          this.reserveNotice = this.reserveNotice.replaceAll('<p>&nbsp;</p>',  '');
          this.reserveNotice = this.reserveNotice.replaceAll('&nbsp;',  '');
          this.reserveNotice = this.reserveNotice.replaceAll('<p><br></p>',  '');
          this.reserveNotice = this.reserveNotice.replaceAll('<p><strong></strong></p>',  '');
        }
      });

    },

    onSubmit() {
      if(!localStorage.getItem('token')) {
        this.signInVisible = true;
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        this.$EventBus.$emit('closeSingIn');
      } else {
        const emailForm = new Promise((resolve, reject) => {
          this.$refs['emailForm'].validate(valid => {
            if(valid) resolve();
          });
        });
        const ruleFormList = this.orderCustomerDtoinfolist.map((el, i) => {
          return new Promise((resolve,reject) => {
            this.$refs['ruleForm' + i][0].validate(valid => {
              if(valid) resolve();
            });
          });
        });
        Promise.all([emailForm, ...ruleFormList]).then(() => {
          this.toPay();
        });
      }
    },
    loginSuccess() {
      this.signInVisible = false;
      this.$EventBus.$emit('closeSingIn');
    },
    onClickLeft() {
      this.$router.back();
    },
    toPay() {
      let totalAmount = 0;
      this.travelInfo.priceTypeList.forEach(el => {
        totalAmount = totalAmount + el.totalAmount;
      });
      let orderCustomerList = JSON.parse(JSON.stringify(this.orderCustomerDtoinfolist));
      orderCustomerList.forEach(el => {
        if(el.countryCode && el.customerTelephone){
          el.customerTelephone = el.countryCode + '-' + el.customerTelephone;
        }
        this.creditTypeList.forEach(v => {
          if(v.label === el.customerCreditType) {
            el.customerCreditType = v.value;
          }
        });
        el.orderCustomCustomerItemDtolist.forEach(l => delete l.itemRequiredFlag);
        delete el.countryCode;
      });

      orderCustomerList.forEach(el =>{
        Object.keys(createInfo(null,null)).forEach(v => {
          if(el[v] == '' || el[v] == undefined || el[v] == null){
            delete el[v];
          }
        });
        if(el.customerUseDate)
        el.customerUseDate = el.customerUseDate.replaceAll('/', '-');
        if(el.customerReceiveDate)
        el.customerReceiveDate = el.customerReceiveDate.replaceAll('/', '-');
      })

      // const str = JSON.stringify({
      //     email: localStorage.getItem('email'),
      //     name: localStorage.getItem('name'),
      //     userId: localStorage.getItem('userId'),
      //     // avatar: localStorage.getItem('avatar'),
      // });
      // const info = encrypt(str);
      // console.log("加密info:",info);


      let params = {
        productUseDate: this.travelInfo.productUseDate, //使用日期
        mailUseLanguageType: this.travelInfo.productId > 11 ? (localStorage.getItem('language') == null ? '2' : localStorage.getItem('language') == 'cn' ? '0'
            : localStorage.getItem('language') == 'jp' ? '1' : localStorage.getItem('language') == 'hk' ? '3' : '2') : '1', //通知邮件语言
        productId: this.travelInfo.productId, //产品ID
        productName: this.travelInfo.productName,
        customerEmail: this.customerObj.email, //凭证发送邮箱（联系人邮箱）
        totalAmount: totalAmount, //总金额
        orderCustomerDtoinfolist: orderCustomerList,
        priceTypeList: this.travelInfo.priceTypeList,
        productImg: this.travelInfo.productPicturePath,//票图片
        domainUrl: window.location.host,
        // info: info,
        // token: localStorage.getItem('token'),
        // avatar: localStorage.getItem('avatar'),
      }
      // console.log('create_order_params',params)
      createOrderAndPay({...params}, data => {
        this.$router.push({
          path: "/orderPaynow",
          query: {
            payUrl: encodeURIComponent(data.data.payUrl),
            ticketDetail: JSON.stringify(this.travelInfo),
            name: this.urname,
            email: this.customerObj.email,
          }
        });
      })
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .van-cell-group--inset {
  margin: 0;
}

/deep/ .van-cell {
  padding: 0;
}

/deep/ .van-field__body {
  height: 47px;
  border: 1px solid #bdbdbd;
  border-radius: .08rem;
  padding-left: .24rem;
}

/deep/ .van-icon {
  color: black;
  font-size: .36rem;
}

/deep/ .van-icon {
  color: black;
  font-size: .36rem;
}

/deep/ .van-icon {
  color: black;
  font-size: .36rem;
}

/deep/ .van-nav-bar__title {
  font-size: .40rem;
  font-weight: bold;
}


/deep/ .van-cell::after {
  border-bottom: 0;
}

/deep/ .van-button {
    background: #000000;
    color: #fff;
    width: 236px;
    height: 48px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 600;
    margin-top: .4rem;
}

// /deep/ .van-divider {
//   margin: 0;
//   height: 2px;
//   background: #ebebeb;
// }

/deep/ .van-field__label {
  width: 130px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

/deep/ .van-nav-bar__content {
  box-shadow: 5px 2px 5px #DDDDDD;
}

.van-hairline--bottom:after {
  border-bottom-width: 0;
}

.box {
  width: 1136px;
  margin: 126px auto 46px;
  .par_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .l_sub_box {
    width: 60%;
  }
  .r_sub_box {
    text-align: left;
    width: 35%;
    margin-top: 30px;
    position: sticky;
    align-self: flex-start;
    top: 80px;
    .clearfix {
      font-size: 24px;
      font-weight: 700;
    }
    ::v-deep .text_item {
      font-size: 16px;
      word-wrap: break-word;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .header {
    text-align: left;
    .row_1 {
      font-size: .28rem;
      font-weight: 600;
    }
    .row_2 {
      background: #eafaf4;
      border-radius: 15px;
      font-size: .2rem;
      margin: 18px 0;
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }
    .row_3 {
      font-size: .2rem;
      color: #9a9a9a;
    }
  }

  .traveler_one {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      margin: 30px 0 14px;
      letter-spacing: -1px;
  }
}

.footer {
  border-top: 1px solid #ebebeb;
  left: 0;
  right: 0;
  height: 2.6rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 12px;
  background: #ffffff;
  position: fixed;
  z-index: 1000;
  box-sizing: border-box;

  .footer_top {
    display: flex;
    justify-content: space-between;
    height: 60px;

    .money {
      font-weight: 540;
      // color: #ef2851;
      font-size: 24px;

      .tickets {
        color: #000000;
        font-weight: 500;
        font-size: .24rem;
      }

      .payment {
        display: block;
        text-align: left;
        font-weight: 500;
        font-size: .24rem;
        color: #4aa476;
      }
    }
  }


}

.label {
  // display: block;
  // margin: 10px 0 10px 0;
  text-align: left;
  // width: 100%;
  font-size: 0.2rem;
  color: #000000;
}

.emailEntry {
  font-size: 0.13rem;
  color:#ee0a24;
  display: block;
  text-align: left;
  clear: both;
  line-height: 20px;
  margin-bottom: 0.05rem;
}

.footer_bottom {
  height: 1.2rem;
  line-height:1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .32rem;

  img {
    height: 20px;
    margin-right: .2rem;
  }
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 32px;
}
::v-deep .el-form--label-top .el-form-item__label {
  float: left !important;
  font-size: 16px;
  color: #000000;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
::v-deep .el-input__inner {
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  // padding: 13px 25px 13px 16px;
  font-size: 14px;
  box-shadow: none;
}
/deep/ .el-dialog--center{
  border-radius: 8px;
}
.popup_moeny {
    text-align: left;
    font-size: .25rem;
    color: #000000;
    font-weight: bold;
    overflow: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: .2rem;

    .tickets {
      color: #000000;
      font-size: .2rem;
      font-weight: 500;
    }

    .payment {
      display: block;
      text-align: left;
      font-size: 0.18rem;
      color: #4aa476;
    }
  }
.country_tel {
  display: flex;
  width: 100%;
}
</style>
