<template>
  <div class="districtDiv" v-if="dataList.length > 0">
    <el-carousel height="348px" arrow="never" style="cursor: grab;border-radius: 0.16rem">
      <el-carousel-item v-for="item in dataList" v-bind:key="item.url">
        <img :src="item.columnImageUrl" class="car-img" @click="toDetail(tab, item)"/>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    dataList: {
      type: Array,
    }
  },
  data() {
    return {}
  },
  methods: {
    toDetail(tab, item) {
      if(tab < 5) {
        this.$router.push({
          path: "/essayDetail",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else if (tab == 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      }
    },
  }
}
</script>

<style lang="less">
.districtDiv {
  text-align: start;
  width: 1136px;
  margin: 0 auto;

  .el-carousel__indicators--horizontal{
    .el-carousel__indicator--horizontal{
      background-color: white;
      opacity: 1;
      border: 1px #000 solid;
      border-radius: 6px;
      display: inline-block;
      padding: 1px 2px;
      margin-right: 14px;
    }

    .el-carousel__indicator--horizontal.is-active{
      width: 18px;
      border-radius: 6px;
    }

    .el-carousel__button{
      width: 2px;
      height: 3px;
      background: #ffffff;
      border-radius: 6px;
      opacity:1;
    }
  }
}
.car-img {
  width: 100%;
  height: 348px;
  object-fit: cover;
}
</style>
