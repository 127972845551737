<template>
  <div>
    <!-- <Header @goBack="goBack"></Header> -->
    <LoginLogo></LoginLogo>
    <div class="login">
      <h1 class="login_title">{{ $t("app.user_info") }}</h1>
      <div class="login_input">
        <van-form ref="vForm" @submit="onSubmit">
          <p class="label">{{ $t("app.user_pic") }}</p>
          <el-upload
              class="avatar-uploader"
              action="#"
              accept="image/png, image/jpeg, image/jpg"
              :http-request="afterRead"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <img v-if="avatar && avatar != 'null' && avatar != ''" :src="avatar" class="avatar">
            <img v-else src="../../assets/user_ic.png" class="avatar">
          </el-upload>
<!--          <van-uploader class="avatar" reupload v-model="fileList" :deletable="false" :after-read="afterRead" name="avatar" :preview-full-image="false" multiple=false max-count="1" accept="image/png, image/jpeg, image/jpg"/>-->
          <p class="label">{{ $t("app.nike_name") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
                v-model="nikeName"
                type="text"
                name="name"
                placeholder=""
                :rules="[{ required: true, message: $t('app.Please_new_nike_pwd') }]"
            />
          </van-cell-group>
          <div style="margin: 35px 0 45px 0">
            <van-button round block type="info" native-type="submit"
            >{{ $t('app.calendarConfirmText') }}
            </van-button>
          </div>
        </van-form>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import apis from "../../utils/apis";
import Vue from "vue";
import { NavBar, Icon, Form, Field, Button, Toast,Uploader } from "vant";
import Header from "../../components/Login/Header.vue";
import Footer from "../../components/Login/Footer.vue";
import LoginLogo from "../../components/Login/Loginlogo.vue";
import user_ic_def from "../../assets/user_ic.png";

Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Uploader);

let { updProFilePhoto,updProfileUserInfo } = apis;
export default {
  props: ['preview'],
  components: {
    Header,
    Footer,
    LoginLogo,
  },
  data() {
    return {
      value1:
          localStorage.getItem("language") == null
              ? "en"
              : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      nikeName: "",
      avatar:'',
      profilePhotoPath:"",
    };
  },
  watch: {
    preview: {
      handler(val) {
        if(val) {
          this.$nextTick(() => {
            this.$refs.vForm.resetValidation();
          })
        }
      },
      immediate: true
    }
  },
  created() {
    this.nikeName = localStorage.getItem("name");
    this.avatar = localStorage.getItem("avatar");
    if (!this.avatar || this.avatar == "undefined") {
      this.avatar = user_ic_def;
    }
  },
  methods: {
    onSubmit() {
      let params = {
          userId: localStorage.getItem('userId'),
          userName:this.nikeName,
          profilePhotoPath: this.profilePhotoPath,
      };
      updProfileUserInfo({ ...params }, (data) => {
        if (data.code == 200) {
          Toast.success(this.$t("app.Success"));
          this.$emit('close', 200)
          localStorage.setItem("name", this.nikeName);
          if (this.profilePhotoPath) {
            localStorage.setItem("avatar", this.profilePhotoPath);
          }
          this.$emit('loginSuccess');
        }
        if (data.code == 500) {
          Toast.fail(this.$t("app.Failure"));
          this.$emit('close', 500)
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    afterRead(file) {
      let that = this;
      updProFilePhoto( file.file , (data) => {
        if (data.code == 200) {
          that.avatar = data.data;
          this.profilePhotoPath = data.data;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.avatar = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg');
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error(this.$t("app.image_require"));
        return isJPG && isLt2M;
      }
      if (!isLt2M) {
        this.$message.error(this.$t("app.image_max_2"));
        return isJPG && isLt2M;
      }
      return isJPG && isLt2M;
    },
    reset() {
      this.nikeName = localStorage.getItem("name");
      this.avatar = localStorage.getItem("avatar");
      if (!this.avatar || this.avatar == "undefined") {
        this.avatar = user_ic_def;
      }
    }
  },
};
</script>

<style scoped lang="less">
/deep/.van-cell-group--inset {
  margin: 0;
}
/deep/.van-cell {
  padding: 0;
}

/deep/.van-field__body {
  height: 47px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 0.24rem;
}

.label {
  text-align: left;
}

.van-button {
  background-color: #000000;
  border: none;
  width: 322px;
  margin: 0 auto;
  height: 54px;
}
.login {
  //   padding: 0 0.5rem;

  .login_title {
    text-align: left;
    margin: 30px 0;
    color: #000000;
  }
  .login_describe {
    text-align: left;
    display: block;
    font-weight: bold;
    font-size: 12px;
    margin: 0 0 0.6rem 0;
  }
}

.van-button--normal {
  font-size: 17px;
  font-weight: 600;
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border: 1px solid #e0e0e0;
  object-fit: cover;
}
</style>
