var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Loginlogo'),_c('div',{staticClass:"login"},[_c('h1',{staticClass:"login_title"},[_vm._v(_vm._s(_vm.$t("app.Welcome_back")))]),_c('div',{staticClass:"login_input"},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$t('app.line_login')))]),_c('div',{staticClass:"line_hover",on:{"click":function($event){return _vm.loginLine()}}}),_c('van-icon',{attrs:{"name":"envelop-o"}}),_c('div',{staticClass:"email",attrs:{"id":"email_login"},on:{"click":function($event){_vm.activeName === '1' ? _vm.activeName = null : _vm.activeName = '1'}}},[_vm._v(_vm._s(_vm.$t('app.email_login')))]),_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-collapse-item',{attrs:{"name":"1"}},[_c('van-form',{ref:"vForm",on:{"submit":_vm.onSubmit}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("app.Email_address")))]),_c('van-cell-group',{staticStyle:{"margin-bottom":"2%"},attrs:{"inset":""}},[_c('van-field',{attrs:{"rules":[
                  {
                    required: true,
                    message: _vm.$t('app.Email_required'),
                    trigger: 'onBlur',
                  },
                  {
                    validator: (value) => {
                      return /.+@.+\..+/.test(value);
                    },
                    message: _vm.$t('app.Email_required_must'),
                    trigger: 'onBlur',
                  },
                ],"name":"value","placeholder":"","id":"ipt_email"},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}})],1),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("app.Password")))]),_c('van-cell-group',{staticStyle:{"margin-bottom":"2%"},attrs:{"inset":""}},[_c('van-field',{attrs:{"type":"password","rules":[{ required: true, message: _vm.$t('app.enter_pwd') }],"name":"value","placeholder":"","focus":"","id":"ipt_pwd"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('span',{staticClass:"forgot_password",on:{"click":function($event){_vm.forgetVisible = true}}},[_vm._v(_vm._s(_vm.$t("app.Forgot_password")))]),_c('div',{staticStyle:{"margin-top":"30px"}},[_c('van-button',{attrs:{"id":"submit_login","round":"","block":"","type":"info","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t("app.Sign_in"))+" ")])],1)],1)],1)],1)],1),_c('divider',{attrs:{"type":0}}),_c('div',{staticClass:"connect"},[_c('p',{staticClass:"medin_ban",on:{"click":function($event){_vm.registerVisible = true}}},[_vm._v(_vm._s(_vm.$t("app.medin_bank")))]),_c('p',{staticClass:"cookie_state"},[_vm._v(_vm._s(_vm.$t("app.cookie_state")))])])],1),_c('el-dialog',{attrs:{"center":"","lock-scroll":"","append-to-body":"","width":"500px","visible":_vm.forgetVisible},on:{"update:visible":function($event){_vm.forgetVisible=$event},"close":_vm.resetFrom}},[_c('forget-psw-dialog',{attrs:{"preview":_vm.forgetVisible},on:{"close":_vm.forgetClose}})],1),_c('el-dialog',{attrs:{"center":"","lock-scroll":"","append-to-body":"","width":"500px","visible":_vm.registerVisible},on:{"update:visible":function($event){_vm.registerVisible=$event},"close":_vm.resetFrom}},[_c('register-dialog',{attrs:{"preview":_vm.registerVisible},on:{"close":function($event){_vm.registerVisible = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }