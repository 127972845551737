<template>
  <div>
    <!-- <Header @goBack="goBack"></Header> -->
    <Loginlogo></Loginlogo>
    <div class="login">
      <h1 class="login_title">{{ $t("app.reset_pwd") }}</h1>
      <div class="login_input">
        <van-form ref="vForm" @submit="onSubmit">
          <p class="label">{{ $t("app.new_pwd") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
              v-model="newpassword"
              type="password"
              name="New password"
              placeholder=""
              :rules="[{ required: true, message: $t('app.Please_pwd') }]"
            />
          </van-cell-group>
          <p class="label">{{ $t("app.new_pwd_confirmation") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
              v-model="pwd"
              type="password"
              name="pwd"
              placeholder=""
              :rules="[{ required: true, message: $t('app.Please_newpwd') }]"
            />
          </van-cell-group>
          <div style="margin: 35px 0 45px 0">
            <van-button round block type="info" native-type="submit"
              >{{ $t('app.calendarConfirmText') }}
            </van-button>
          </div>
        </van-form>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import apis from "../../utils/apis";
import Vue from "vue";
import { NavBar, Icon, Form, Field, Button, Toast } from "vant";
import Header from "../../components/Login/Header.vue";
import Footer from "../../components/Login/Footer.vue";
import Loginlogo from "../../components/Login/Loginlogo.vue";
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
let { modifyPwd } = apis;
export default {
  props: ['preview'],
  components: {
    Header,
    Footer,
    Loginlogo,
  },
  data() {
    return {
      value1:
        localStorage.getItem("language") == null
          ? "en"
          : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      mail: "1537694643@qq.com",
      pwd: "",
      newpassword: "",
    };
  },
  watch: {
    preview: {
      handler(val) {
        if(val) {
          this.$nextTick(() => {
            this.$refs.vForm.resetValidation();
            this.newpassword = '';
            this.pwd = '';
          })
        }
      }, 
      immediate: true
    }
  },
  methods: {
    onSubmit(values) {
      if (this.pwd != this.newpassword) {
        Toast.fail(this.$t('app.restPsw'));
        return;
      }
      let params = {
        mail: localStorage.getItem("restMail"),
        pwd: this.pwd,
      };
      modifyPwd({ ...params }, (data) => {
        if (data.code == 200) {
          Toast.success(data.msg);
          this.$emit('close', 200)
        }
        if (data.code == 500) {
          Toast.fail(data.data.msg);
          this.$emit('close', 500)
          // this.$router.push("/register");
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
/deep/.van-cell-group--inset {
  margin: 0;
}
/deep/.van-cell {
  padding: 0;
}

/deep/.van-field__body {
  height: 47px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 0.24rem;
}

.label {
  text-align: left;
}

.van-button {
  background-color: #000000;
  border: none;
  width: 322px;
  margin: 0 auto;
  height: 54px;
}
.login {
//   padding: 0 0.5rem;

  .login_title {
    text-align: left;
    margin: 30px 0;
    color: #000000;
  }
  .login_describe {
    text-align: left;
    display: block;
    font-weight: bold;
    font-size: 12px;
    margin: 0 0 0.6rem 0;
  }
}

.van-button--normal {
  font-size: 17px;
  font-weight: 600;
}
</style>
