<template>
  <div class="homePage">
    <!-- <nav-bar :ifBlack="true"></nav-bar> -->
    <div class="back" style="position: relative">
      <i class="van-icon van-icon-arrow-left" style="font-size: 0.35rem" @click="goback()"></i>
      <span class="headerText" @click="goback()">{{ $t('app.back') }}</span>
      <collect style="position: absolute;right: 0px" :text-flg="true" :favorite="favorite"></collect>
    </div>
    <div class="baseInfo">
      <img :src="ticketDetail?.productPicturePath ? ticketDetail.productPicturePath : NoPicture" alt="" class="baseImg">
      <div class="box">
        <div class="box_zoo">
          <div class="box_title">
            {{ ticketDetail.productName }}
          </div>
          <div class="box_rate">
            <div class="box_num">5.0</div>
            <div class="box_xing">
              <div>
                <van-rate
                    v-model="value"
                    :size="16"
                    color="#ffd21e"
                    void-icon="star"
                    void-color="#eee"
                    readonly
                />
              </div>
              <div class="box_good">{{ $t('app.verygood') }}</div>
            </div>
          </div>
          <div class="detailRichText">
            <span>{{ ticketDetail.productTextDescription | filtersText }}</span>
          </div>
          <div v-show="ticketDetail.productTextDescription" class="readMore" @click="ifShowDecs = true">
            {{ $t("app.readMore") }}
          </div>

        </div>

        <div class="priceDiv">
          <div>
            <div class="price">
              <span class="discount_price">
                <span style="font-size: 14px">{{ $t("app.discount_price") }}</span>
                {{ $t("app.money") }}{{ priceFormat(minMoney) }}
              </span>
              <span class="original_price">{{ $t("app.money") }}{{ priceFormat(minMoney * 1.3) }}</span>
            </div>

            <div class="t0">
              1{{ $t("app.tickets") }}
            </div>
          </div>


          <el-button :disabled="!ticketDetail.priceTypeList?.length" @click="ifShowList = true"
                     :style="{background: ticketDetail.priceTypeList?.length ? '#f2b203' : '#C0C4CC'}">
            {{ $t("app.Check_availability") }}
          </el-button>
          <!-- <span>{{ ticketDetail.priceTypeList }}</span> -->


        </div>
        <!-- <div class="box_hours">
          <div class="box_day">{{ $t("app.Address") }}</div>
          <div class="box_round"></div>
        </div> -->

      </div>

    </div>

    <el-row style="padding-top: 0px;width: 1136px;margin: 0 auto">
      <el-divider class="merchant-line" style="width: 98%;"></el-divider>
      <div style="display: flex; margin: 0 auto; margin-bottom: 15px;">
        <div class="plan-list" style="width: 900px;">
          <div style="display: flex">
            <div v-if="planUserList.length > 0" class="user-info-box" v-for="user in planUserList" :key="user">
              <img v-if="user.avatar" class="plan-user-avatar" :src="user.avatar">
              <img v-if="!user.avatar && user.userAvatarRandom" class="plan-user-avatar" :src="user.userAvatarRandom">
              <img v-if="!user.avatar && !user.userAvatarRandom" class="plan-user-avatar" src="../../assets/user_ic.png">
              <div class="plan-user-name">{{ user.userName }}</div>
            </div>
            <div v-if="planShowMoreFlg" @click="planMore()" style="font-size: 14px;color: #448CBB;font-weight: bold;cursor: pointer;width: 20px;">
              {{ $t("app.more_plan") }}
            </div>
            <div v-if="planUserList.length <= 0" style="font-size: 14px">
              {{ $t("app.come_plan") }}
            </div>
          </div>
        </div>
        <div style="width: calc(100% - 900px);padding-left: 8px;text-align: right">
          <div style="font-size: 14px">{{planTotal}}{{ $t("app.user_plan_go") }}</div>
          <el-button id="plango_ticket" @click="addPlan()" v-if="planFlg == '0'" round class="btn-black">&nbsp;&nbsp;{{ $t("app.plan_go") }}&nbsp;&nbsp;</el-button>
          <el-button @click="cancelPlan()" v-if="planFlg == '1'" round class="btn-black">&nbsp;&nbsp;{{ $t("app.cancel") }}&nbsp;&nbsp;</el-button>
        </div>
      </div>
      <el-divider class="merchant-line" style="width: 98%;"></el-divider>
    </el-row>

    <div class="box_notice">

      <div class="con_nav">
          <!-- <span @click="changeTab(0)"
            :class="active === 0 ? 'active' : ''">{{ $t('app.productDescription') }}</span> -->
          <span @click="changeTab(1)"
            :class="active === 1 ? 'active' : ''">{{ $t('app.detailsIntro') }}</span>
          <span @click="changeTab(2)"
            :class="active === 2 ? 'active' : ''">{{ $t('app.reserveNotice') }}</span>
        </div>

      <div class="con_tab">
        <!-- <div id="instro" class="conBlock1" v-if="active === 0">
          <div class="box_notice_title">{{ ticketDetail.productName }}</div>
          <div class="box_notice_years" v-html="ticketDetail.productTextDescription"></div>
        </div> -->
        <!-- <div class="con_divider">详情介绍</div> -->
        <div id="catalog" class="conBlock2" v-if="active === 1">
          <div v-if="ticketDetail.useFlow">
            <div class="tab_sub_title">{{ $t('app.productUseFlow') }}</div>
            <div class="box_notice_years" v-html="ticketDetail.useFlow"></div>
          </div>

          <div v-if="ticketDetail.productZhLanguageFlag === '1' || ticketDetail.productEnLanguageFlag === '1' ||
                     ticketDetail.productJpLanguageFlag === '1' || ticketDetail.productKoLanguageFlag === '1'">
            <div class="tab_sub_title">{{ $t('app.productLanguage') }}</div>
            <div class="box_notice_years">
              <el-tag v-if="ticketDetail.productZhLanguageFlag === '1'">{{ $t('app.Chinese') }}</el-tag>
              <el-tag v-if="ticketDetail.productEnLanguageFlag === '1'">{{ $t('app.English') }}</el-tag>
              <el-tag v-if="ticketDetail.productJpLanguageFlag === '1'">{{ $t('app.Japanese') }}</el-tag>
              <el-tag v-if="ticketDetail.productKoLanguageFlag === '1'">{{ $t('app.Korean') }}</el-tag>
            </div>
          </div>

          <div v-if="ticketDetail.productAudienceAsk">
            <div class="tab_sub_title">{{ $t('app.productAudienceAsk') }}</div>
            <div class="box_notice_years">大人</div>
          </div>

          <div v-if="ticketDetail.scheduleDuration">
            <div class="tab_sub_title">{{ $t('app.travelDuration') }}</div>
            <div class="box_notice_years">{{ ticketDetail.scheduleDuration }}</div>
          </div>

          <div v-if="ticketDetail.groupNumber">
            <div class="tab_sub_title">{{ $t('app.groupSize') }}</div>
            <div class="box_notice_years">{{ ticketDetail.groupNumber }}</div>
          </div>

          <div v-if="ticketDetail.viewAddress">
            <div class="tab_sub_title">{{ $t('app.proAddress') }}</div>
            <div class="box_notice_years">{{ ticketDetail.viewAddress }}</div>
          </div>

          <div v-if="ticketDetail.productBusinessTime">
            <div class="tab_sub_title">{{ $t('app.businessHours') }}</div>
            <div class="box_notice_years">{{ ticketDetail.productBusinessTime }}</div>
          </div>

          <div v-if="ticketDetail.costInclude">
            <div class="tab_sub_title">{{ $t('app.productCostInclude') }}</div>
            <div class="box_notice_years">{{ ticketDetail.costInclude }}</div>
          </div>

          <div v-if="ticketDetail.costExclude">
            <div class="tab_sub_title">{{ $t('app.productCostExclusive') }}</div>
            <div class="box_notice_years">{{ ticketDetail.costExclude }}</div>
          </div>
        </div>
        <!-- <div class="con_divider">预定说明</div> -->
        <div id="welfare" class="conBlock3" v-if="active === 2">
          <div v-if="ticketDetail.reserveLimitDay && ticketDetail.reserveLimitTime">
            <div class="tab_sub_title">{{ $t('app.advanceDays') }}</div>
            <div class="box_notice_years">{{ $t('app.advanceDaysText', {day: ticketDetail.reserveLimitDay, time: ticketDetail.reserveLimitTime}) }}</div>
          </div>

          <div v-if="ticketDetail.productConfirmType">
            <div class="tab_sub_title">{{ $t('app.confirmWay') }}</div>
            <div class="box_notice_years">{{ ticketDetail.productConfirmType === '0' ?
                                          $t('app.automaticConfirm') : $t('app.secondaryConfirm') }}</div>
          </div>

          <div v-if="ticketDetail.orderValidType">
            <div class="tab_sub_title">{{ $t('app.effectiveDate') }}</div>
            <div class="box_notice_years">{{ orderValid }}</div>
          </div>

          <div class="tab_sub_title">{{ $t('app.refundInstructions') }}</div>
          <div class="box_notice_years">{{ $t('app.non_refundable') }}</div>

          <div v-if="ticketDetail.reserveNotice">
            <div class="tab_sub_title">{{ $t('app.predetermineNotice') }}</div>
            <div class="box_notice_years" v-html="ticketDetail.reserveNotice"></div>
          </div>
        </div>
      </div>

    </div>


    <scenic-part :scenicList="scenicList"></scenic-part>

    <el-dialog
        :visible.sync="show"
        center
        width="800px"
    >
      <div>
        <div class="calTitle"> {{ ticketDetail.productName }}
        </div>
        <!--      <van-notice-bar color="#ef8534" background="#fffae8" left-icon="warning">-->
        <!--        {{ $t("app.Products") }}-->
        <!--      </van-notice-bar>-->
        <!-- <van-divider :style="{ color: '#1989fa', borderColor: '#ebebeb', padding: '0 16px' }" /> -->
        <div class="popup_box">
          <div>{{ $t("app.Ticket_type") }}</div>
          <div v-if="popup_list.priceType">
            <mark
            ><span class="popup_box_btn">{{ priceTypeName }}</span></mark
            >
          </div>
          <div v-else></div>
          <div>{{ $t("app.Date_selection") }}</div>
          <div class="select_date">
            <!-- <van-row type="flex">
                <van-col span="9"> -->
            <van-cell-group>
              <van-cell :title="date" @click="showCalendar" is-link>
                <template #icon>
                  <img
                      src="../../assets/img/date.png"
                      alt=""
                      srcset=""
                      height="22"
                  />
                </template>
              </van-cell>
            </van-cell-group>
            <!-- </van-col>
                <van-col span="9"></van-col>
            </van-row> -->
          </div>

          <div class="popup_box_text">
            <div>
              <div style="color: #000;margin-bottom: 0.1rem">{{ $t("app.Purchase_quantity") }}</div>
              <div>{{ $t('app.minimum_order') }}</div>

            </div>


            <div class="btn_number">
              <span @click="subtract" style="cursor: pointer"><van-icon name="minus"/></span>
              <span class="number">{{ num }}</span>
              <span @click="add(popup_list)" style="cursor: pointer"><van-icon name="plus"/></span>
            </div>
          </div>
        </div>
        <van-divider
            :style="{ color: '#1989fa', borderColor: '#ebebeb', padding: '0 16px' }"
        />
        <div class="popup_footer">
          <div class="popup_moeny">
            {{ $t("app.money") }} {{ priceFormat(num * popup_list.distributionPrice) }}
            <span class="tickets"> / {{ num }}{{ getTicket(num) }}</span>
            <span class="payment">{{ $t('app.paymentProtection') }}</span>
          </div>
          <!--          <div class="next_btn">-->
          <!--            <span class="btn" @click="goPayments">{{ $t("app.bookNow") }}</span>-->
          <!--          </div>-->

          <el-button @click="goPayments">
            {{ $t("app.bookNow") }}
          </el-button>

          <!-- <div class="footer_instant">
            <img src="../../assets/img/lightning.png" alt="" srcset=""/>
            Instant Confirmation
          </div> -->
        </div>
      </div>

    </el-dialog>

    <van-calendar
        v-model="showDate"
        :default-date="curShowDate"
        @confirm="confirmFn"
        :formatter="formatter"
        :confirm-text="$t('app.calendarConfirmText')"
        :max-date="maxDate"
        :min-date="minDate"
        position="right"/>

    <el-dialog
        center
        append-to-body
        width="800px"
        :visible.sync="ifShowDecs">
      <div>

      </div>
      <div
          class="descPop"
          v-html="ticketDetail.productTextDescription"
      ></div>
    </el-dialog>

    <el-dialog
        center
        append-to-body
        width="800px"
        :visible.sync="ifShowList">

      <div style="overflow: auto">
        <div class="box_list">
          <div class="list_card_tours">{{ $t("app.Adventure") }}</div>

          <div
              class="list_card"
              v-for="(item, index) in ticketDetail.priceTypeList"
              :key="index"
              :style="{background: item.price === 0 ? '#c8c9cc' : ''}"
              @click="item.price === 0 ? '' : showPopup(item)"
          >

            <div>
              <div class="list_card_title" style="font-size: 22px; font-weight: 600">
                {{ item.priceTypeName }}
              </div>
              <div class="list_card_title" style="text-decoration: line-through" v-if="item.crossedPrice != 0">
                {{ $t("app.money") }} {{ priceFormat(item.crossedPrice) }}
              </div>
              <div class="list_card_title" v-if="item.price == 0">{{ $t('app.noInventory') }}</div>
              <div class="list_card_title" v-else>
                {{ $t("app.money") }} {{ priceFormat(item.price) }}({{ $t("app.priceVaries") }})
              </div>
            </div>

            <!-- <el-button @click="showPopup(item)" :disabled="item.price === 0"> {{ $t("app.book") }}</el-button> -->

            <!--            <div class="list_card_title1">-->
            <!--              <div>-->
            <!--                <van-button-->
            <!--                    type="warning"-->
            <!--                    :disabled="item.price === 0"-->
            <!--                    @click="showPopup(item)"-->
            <!--                >{{ $t("app.book") }}-->
            <!--                </van-button-->
            <!--                >-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>

      </div>


    </el-dialog>

    <!-- SingIn In -->
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="signInVisible"
    >
      <sing-in-dialog :preview="signInVisible" @loginSuccess="loginSuccess"/>
    </el-dialog>

  </div>
</template>

<script>
import apis from "../../utils/apis";
import Vue from "vue";
import {
  Rate,
  Button,
  Image as VanImage,
  Popup,
  Calendar,
  Icon,
  Divider,
  Col,
  Row,
  Toast,
} from "vant";

import NavBar from "../../components/basicComponents/navBar";
import HomeBanner from "../../components/homeBanner";
import OrderBk from "../../components/order/orderBk.vue";
import ScenicPart from "@/components/scenicPart";
import SingInDialog from "@/components/Login/SingInDialog.vue";
import NoPicture from "../../assets/img/no_picture.png";
import Collect from "../../components/collect"

Vue.use(Rate);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Popup);
Vue.use(Calendar);
Vue.use(Icon);
Vue.use(Divider);
Vue.use(Col);
Vue.use(Row);
Vue.use(Toast);
let {
  TicketDetail, 
  TicketPirceCalendar, 
  indexRecommend, 
  checkToken, 
  addTicketPlan,
  delTicketPlan,
  getTicketPlan,
  addClick
  } = apis;
const varDate = new Date();

export default {
  components: {
    ScenicPart,
    HomeBanner,
    NavBar,
    Collect,
    OrderBk,
    SingInDialog,
  },

  data() {
    return {
      curShowDate: varDate,
      NoPicture: NoPicture,
      active: 1,
      ticketDetail: {
        priceTypeList: [],
      },
      value: 5,
      nextEnd: "",
      show: false,
      showDate: false,
      date: "",
      num: 1,
      total: 0,
      minMoney: 0,
      ticketData: [],
      startTime: "",
      endtime: "",
      minDate: "",
      maxDate: "",
      productId: "",
      articleId: "",
      row: {},
      popup_list: "",
      curNums: [],
      concatList: [],

      priceTypeCode: "",
      priceTypeName: "",
      searchList: "",

      ifShowDecs: false,
      ifShowList: false,
      scenicList: [],

      signInVisible: false,
      favorite:{
        width:28,
        height:28,
      },
      planTotal: 0,
      planUserList: [],
      planShowMoreFlg: false,
      planFlg: '0',
      planQueryParam: {
        pageNum: 1,
        pageSize: 20,
        productId: this.$route.query.id,
      },

    };
  },
  computed: {
    orderValid() {
      switch (this.ticketDetail.orderValidType) {
        case '0':
          return this.$t('app.orderValidText0');
        case '1':
          return this.$t('app.orderValidText1', {day: this.ticketDetail.delayedDay});
        case '2':
          return this.$t('app.orderValidText2', {date: this.ticketDetail.specifiedDate});
        case '3':
          return this.$t('app.orderValidText3', {day: this.ticketDetail.delayedDay});
        case '4':
          return this.$t('app.orderValidText4', {date: this.ticketDetail.specifiedDate});
        case '5':
          return this.$t('app.orderValidText5', {startDate: this.ticketDetail.specifiedStartDate, endDate: this.ticketDetail.specifiedEndDate});
        case '6':
          return this.$t('app.orderValidText6', {startDay: this.ticketDetail.specifiedStartDay, endDay: this.ticketDetail.specifiedEndDay});
      }
    },
  },
  created() {
    this.getTabList();
    this.currentTime();
    this.endtime = this.getDay(+30);
    this.productId = this.$route.query.id;
    this.articleId = this.$route.query.id;
    this.type = this.$route.query.type;
    this.ticket();
    this.getPlan();
    this.click();
  },
  mounted() {
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
            .replace(reg, "")
            .replace(
                /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
                function (all, t) {
                  return arrEntities[t];
                }
            );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
  methods: {
    click() {
      let params = {
        foreignId: this.$route.query.id,
        clickType: '2',
        domainUrl: window.location.host,
        clickUserId: localStorage.getItem('userId'),
      };
      addClick(params);
    },
    planMore() {
      this.planQueryParam.pageNum++;
      this.getPlan();
    },
    getPlan() {
      getTicketPlan(this.planQueryParam, (response) => {
        if (response.data) {
          this.planTotal = response.data.total;
          this.planShowMoreFlg = response.data.hasNextPage;
          if (response.data.pageNum === 1) {
            this.planUserList = response.data.list;
          } else {
            this.planUserList.push(...response.data.list);
          }
        }
      })
    },
    addPlan() {
      if (!localStorage.getItem('userId')) {
        this.signInVisible = true;
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        return;
      };
      const param = {
        productId: this.$route.query.id,
        userId: localStorage.getItem('userId'),
        userName: localStorage.getItem('name'),
        domainUrl: window.location.host,
      }
      addTicketPlan({ ...param }, (response) => {
        if (response.code == 200) {
          this.planFlg = "1";
          this.$message({message: this.$t('app.add_plan_success'), customClass: 'messageIndex',type:'success'});
          this.planQueryParam.pageNum = 1;
          this.getPlan();
        }
      })
    },
    cancelPlan() {
      if (!localStorage.getItem('userId')) {
        this.signInVisible = true;
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        return;
      };
      const param = {
        productId: this.$route.query.id,
        userId: localStorage.getItem('userId'),
      }
      delTicketPlan({ ...param }, (response) => {
        if (response.code == 200) {
          this.planFlg = "0";
          this.$message({message: this.$t('app.cancel_plan_success'), customClass: 'messageIndex',type:'success'});
          this.planQueryParam.pageNum = 1;
          this.getPlan();
        }
      })

    },
    showCalendar() {
      if(new Date(this.date) != varDate) {
        this.curShowDate = new Date(this.date);
      } else {
        this.curShowDate = varDate;
      }
      this.showDate = true;
    },
    getTicket(num){
      if (!isNaN(num)) {
        return Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets')
      } else {
        return this.$t('app.tickets');
      }
    },
    changeTab(v) {
      this.active = v;
      window.scrollTo({
        top: 515,
        behavior: "smooth",
      })
    },
    goback(){
      this.$router.back()
    },
    currentTime() {
      // 当前时间
      //年
      let year = new Date().getFullYear();
      //月份是从0月开始获取的，所以要+1;
      let month = new Date().getMonth() + 1;
      //日
      let day = new Date().getDate();
      //时
      // let hour = new Date().getHours();
      // //分
      // let minute = new Date().getMinutes();
      // //秒
      // let second = new Date().getSeconds();
      let time =
          year + "-" + this.doHandleMonth(month) + "-" + this.doHandleMonth(day);
      this.minDate = new Date(year, month - 1, day);
      this.maxDate = new Date(year, month - 1, day + 30);
      this.startTime = time;
    },
    //30天之后的之间
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds);

      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    formatter(day) {
      // 当前月份的日
      const type = day.type;
      day.type = "disabled";
      var date = day.date.getDate();
      // let that=this
      for (let i = 0; i < this.curNums.length; i++) {
        // 当前点击的日相同
        if (
            this.curNums[i].key.indexOf(date) != -1 &&
            this.curNums[i].code == this.popup_list.priceTypeCode
        ) {
          //日期
          // 判断预约次数是否为0
          if (this.curNums[i].value) {
            //日期金钱
            // 日期添加备注
            day.bottomInfo = this.priceFormat(this.curNums[i].value);
            day.type = type;
            break;
          } else {
            // 日期添加备注
            // day.type = 'disabled'
          }
        }
      }
      return day;
    },
    async ticket() {
      let params = {
        productId: this.productId,
        userid: localStorage.getItem('userId'),
        type:this.type,
      };
      let startDate = '';
      let endDate = '';
      await TicketDetail({...params}, (data) => {
        this.ticketDetail = data.data;
        this.planFlg = data.data.planFlg;
        this.favorite = {
          favorite_id:this.articleId,
          type:this.type,
          userid: localStorage.getItem('userId'),
          favoriteFlg:data.data.favoriteFlg,
          width:28,
          height:28,
        }

        if(this.ticketDetail.reserveNotice) {
          this.ticketDetail.reserveNotice = data.data.reserveNotice.replaceAll('<p>&nbsp;</p>',  '');
          this.ticketDetail.reserveNotice = this.ticketDetail.reserveNotice.replaceAll('&nbsp;',  '');
          this.ticketDetail.reserveNotice = this.ticketDetail.reserveNotice.replaceAll('<p><br></p>',  '');
          this.ticketDetail.reserveNotice = this.ticketDetail.reserveNotice.replaceAll('<p><strong></strong></p>',  '');
        }
        startDate = data.data.productSaleStartDate;
        endDate = data.data.productSaleEndDate;
        // this.minDate = new Date(Number(startDate[0]), Number(startDate[1]) - 1, Number(startDate[2]));
        // this.maxDate = new Date(Number(endDate[0]), Number(endDate[1]) - 1, Number(endDate[2]));
      });
      // let fDate =  Number(startDate[0]) * 12 + (Number(startDate[1]) + 1);
      // let sDate =  Number(endDate[0]) * 12 + (Number(endDate[1]) + 1);
      // console.log("日期之差",Math.abs(fDate - sDate));

      // let nowDate = new Date();
      // let saleStartDate = new Date(startDate);
      // if(nowDate > saleStartDate) {
      //   this.minDate = nowDate;
      //   this.startTime = this.formatDate(nowDate);
      // } else {
      //   this.minDate = saleStartDate;
      //   this.startTime = this.formatDate(saleStartDate);
      // }
      // this.maxDate = new Date(endDate);
      // this.endtime = endDate;

      let ticket = {
        productSaleStartDate: this.startTime, //日期格式
        productSaleEndDate: this.endtime, //日期格式
        productId: this.productId, //EasyGo产品ID
      };
      await TicketPirceCalendar({...ticket}, (data) => {
        this.ticketData = data.data;
        let b = [];
        for (let a in data.data) {
          b = {
            key: data.data[a].priceDate,
            value: data.data[a].distributionPrice,
            code: data.data[a].priceTypeCode,
          };
          this.curNums.push(b);
        }
      });
      if(this.ticketDetail.priceTypeList?.length) {
        this.ticketDetail.priceTypeList.forEach((el) => {
          let tmpPrice = 0;
          let crossedPrice = 0;
          // let crossedPrice1 = this.ticketData.reduce((prev, cur) => {
          //   return prev > Number(cur.retailPrice) ? prev : Number(cur.retailPrice);
          // }, 0)
          // console.log(crossedPrice1);
          this.ticketData.forEach((v) => {
            if (el.priceTypeCode === v.priceTypeCode) {
              if (tmpPrice === 0) {
                tmpPrice = Number(v.distributionPrice);
              } else if (tmpPrice > Number(v.distributionPrice)) {
                tmpPrice = Number(v.distributionPrice);
              }

              if (crossedPrice === 0) {
                crossedPrice = Number(v.retailPrice);
              } else if (crossedPrice < Number(v.retailPrice)) {
                crossedPrice = Number(v.retailPrice);
              }
            }
          });
          this.$set(el, "price", tmpPrice);
          this.$set(el, "crossedPrice", crossedPrice > tmpPrice ? crossedPrice : tmpPrice);
          if(this.minMoney === 0) {
            this.minMoney = tmpPrice
          } else if(this.minMoney > tmpPrice) {
            this.minMoney = tmpPrice
          }
        });
      }
    },
    //显示日历弹窗
    showPopup(index) {
      this.priceTypeName = index.priceTypeName;
      if(!localStorage.getItem('token')) {
        this.signInVisible = true;
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        return;
      }
      checkToken({}, data => {
        if(data.code != 200) {
          this.signInVisible = true;
          this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        } else {
          if (this.ticketData?.length) {
            this.priceTypeCode = index.priceTypeCode;
            this.priceTypeName = index.priceTypeName;
            // this.productId = index.productId;
            let that = this;
            that.show = true;
            for (let i = 0; i < that.ticketData.length; i++) {
              if (that.ticketData[i].priceTypeCode == index.priceTypeCode) {
                that.date = that.ticketData[i].priceDate;
                that.popup_list = that.ticketData[i];
                this.$forceUpdate();
                return;
              }
            }
          } else {
            Toast(this.$t("app.DateList"));
          }
        }
      });
    },
    formatDate(date) {
      const padZero = (val) => val.toString().padStart(2, '0');

      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    },
    confirmFn(data) {
      this.$forceUpdate();
      this.date = this.formatDate(data);
      this.showDate = false;
      for (let i = 0; i < this.ticketData.length; i++) {
        if (this.ticketData[i].priceTypeCode == this.popup_list.priceTypeCode) {
          this.popup_list = this.ticketData[i];
          return;
        }
      }
    },
    subtract() {
      this.num--;
      const minNum = Number(this.ticketDetail.minReserveNummber);
      if(minNum === 0 && this.num <= 1) {
        this.num = 1;
      }
      if(minNum !== 0 && this.num <= minNum) {
        this.num = minNum;
      }
    },
    getTabList() {
      let params = {
        type: 2,
      };
      indexRecommend({...params}).then(res => {
        this.scenicList = res.data.data;
      });
    },

    loginSuccess() {
      this.signInVisible = false;
      this.$EventBus.$emit('closeSingIn')
    },

    goPayments() {

      if(!localStorage.getItem('token')) {
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        // this.$EventBus.$emit('closeSingIn');
        this.signInVisible = true
      } else {
        let params = {
          productName: this.ticketDetail.productName,
          productPicturePath: this.ticketDetail.productPicturePath,
          priceTypeName: this.priceTypeName,
          productId: this.productId,
          productUseDate: this.date,
          customerDataType: this.ticketDetail.customerDataType,
          customItemList: this.ticketDetail.customItemList,
          nameRequiredFlag: this.ticketDetail.nameRequiredFlag  || '1',
          telephoneRequiredFlag: this.ticketDetail.telephoneRequiredFlag  || '1',
          emailRequiredFlag: this.ticketDetail.emailRequiredFlag  || '1',
          wechatRequiredFlag: this.ticketDetail.wechatRequiredFlag  || '1',
          passportRequiredFlag: this.ticketDetail.passportRequiredFlag  || '1',
          sexRequiredFlag: this.ticketDetail.sexRequiredFlag  || '1',
          useDateFlag: this.ticketDetail.useDateFlag  || '1',
          codeReuqiredFlag: this.ticketDetail.codeReuqiredFlag  || '1',
          addressRequiredFlag: this.ticketDetail.addressRequiredFlag || '1',
          creditNoRequiredFlag: this.ticketDetail.creditNoRequiredFlag  || '1',
          receiveDateFlag: this.ticketDetail.receiveDateFlag  || '1',
          reserveNotice: this.ticketDetail.reserveNotice,
          priceTypeList: [
            {
              totalAmount: this.num * this.popup_list.distributionPrice,
              priceTypeCode: this.priceTypeCode,
              buyQuantity: this.num,
            },
          ],
        };

        this.$router.push({
          path: "/contacts",
          query: {
            contact: JSON.stringify(params),
          },
        });
      }
    },
    add(list) {
      this.num++;
      const maxNum = Number(this.ticketDetail.maxReserveNummber);
      if(maxNum !== 0 && this.num >= maxNum) {
        this.num = maxNum;
      }
      //点击就增加一个人的信息
      this.concatList.push(list);
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/orderDetails.less");
.merchant-line {
  margin-top: 8px !important;
}
.plan-list {
  overflow-x: hidden;
}

.plan-list:hover {
  overflow-x: auto;
}

.plan-list::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.plan-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.plan-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.plan-list::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.user-info-box {
  margin-right: 16px;
}
.plan-user-avatar {
  width: 50px;
  height: 50px !important;
  border-radius: 100%;
  border: 1px solid #e0e0e0;
  margin: 0 auto;
}
.plan-user-name {
  width: 60px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  font-size: 12px;
  position: relative;
}
.btn-black {
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.btn-black.el-button:active,
.btn-black.el-button:hover,
.btn-black.el-button:focus{
  color: #ffffff;
  border-color: #ffffff;
  outline: 0;
  background-color: #000000;
}
.back{
  display: flex;
  justify-content: left;
  width: 1136px;
  margin: 120px auto 0;
  text-align: left;
  font-size: 0.28rem;
  font-weight: 600;
  display: flex;
  cursor:pointer;
  align-items: center;
}
.headerText {
    margin-left: 15px;
    font-weight: 600;
    font-size: .3rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    width: 100%;
    text-align: left;
  }
.title_desc {
  font-weight: 540;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  white-space: normal;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}

.title_header {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.32rem;
}
.con_nav {
  text-align: left;
  font-size: .2rem;
  padding: 0.24rem 0 0.3rem;
  position: sticky;
  top: 80px;
  background: #ffffff;
  span:nth-child(n+2) {
    margin-left: .2rem;
  }
  span {
    padding-bottom: .1rem;
    cursor: pointer;
  }
  .active {
    border-bottom: 3px solid green;
  }
}
.con_tab {
  text-align: left;
  .con_divider {
    border-left: 5px solid green;
    margin: .3rem 0;
    padding-left: .2rem;
  }
}
/deep/ .van-calendar {
  width: 50vh;
}

.discount_price {
  color: #f1b231;
  font-size: 20px;
}

.original_price {
  color: #989898;
  font-size: 14px;
  text-decoration: line-through;
  margin-left: 12px;
}
</style>
