<template>
    <div>
       <header class="header_search">
            <a href="javascript:;" class="left_icon" @click="goBack">
                <van-icon name="down" size="18" />
            </a>
            <div class="search_input">
                <input type="text">
            </div>
            <a href="javascript:;" class="right_icon" @click="goSearchResult('a','0')">
                <van-icon name="search" />
            </a>
       </header>
       <div class="box">
            <div class="box_list" @click="goSearchResult('b','4')">
                <div class="box_list_lf">
                    <img src="../../assets/img/gonglue1.png" alt="" srcset="">
                </div>
                <div class="box_list_rf">
                    {{
                        $t('app.introduction')
                    }}           
                </div>
            </div>
            <div class="box_list" @click="goSearchResult('c','1')">
                <div class="box_list_lf">
                    <img src="../../assets/img/area1.png" alt="" srcset="">   
                </div>
                <div class="box_list_rf">
                    {{
                        $t('app.Area')
                    }}
                </div>
            </div>
            <div class="box_list" @click="goSearchResult('d','2')">
                <div class="box_list_lf">
                    <img src="../../assets/img/jingdian1.png" alt="" srcset="">
                </div>
                <div class="box_list_rf">
                    {{
                        $t('app.Scenic_spot')
                    }}
                </div>
            </div>
            <div class="box_list" @click="goSearchResult('e','5')">
                <div class="box_list_lf">
                    <img src="../../assets/img/shop1.png" alt="" srcset="">
                </div>
                <div class="box_list_rf">
                    {{
                        $t('app.Shop')
                    }}                
                </div>
            </div>
            <div class="box_list" @click="goSearchResult('f','6')">
                <div class="box_list_lf">
                    <img src="../../assets/img/piao1.png" alt="" srcset="">
                </div>
                <div class="box_list_rf">
                    {{
                        $t('app.Ticket')
                    }}   
                </div>
            </div>
            <div class="box_list" @click="goSearchResult('a','0')">
                <div class="box_list_lf">
                    <img src="../../assets/img/all.png" alt="" srcset="">
                </div>
                <div class="box_list_rf">
                    {{
                        $t('app.All')
                    }}   
                </div>
            </div>
       </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
            option1: [
                { text: this.$t('app.en'), value: 'en' },
                { text: this.$t('app.jp'), value: 'jp' },
                { text: this.$t('app.cn'), value: 'cn' },
                { text: this.$t('app.hk'), value: 'hk' },
            ],
        }
    },
    created(){
       
    },
    methods:{
        goBack(){
            this.$router.back()
        },
        goSearchResult(e,index){
            this.$router.push({
                path:'/result',
                query:{
                    name:e,
                    index:index
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
/deep/ .van-icon {
    color: black;
   font-size: 22px;
}
/deep/.van-icon-down{
    transform: rotate(90deg);
}
.header_search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: .9rem;
    margin: 0 12px;
    border-bottom: 2px solid #000000;
    .left_icon{
        width: 1rem;
    }
    .search_input{
        flex: 1;
        input{
            height: 0.7rem;
            width: 100%;
            border: none;
        }
    }
    .right_icon{
        width: 0.6rem;
    }
}
.box{
    display: flex;
    flex-direction: column;
    margin-top: 0.26rem;
    .box_list{
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.6rem;
        width: 100%;
        padding:  .3rem;
        border-bottom: 1px solid #dcdddd;
        .box_list_lf{
            width: 1rem;
            img{
                width: 0.6rem;
            }
        }
        .box_list_rf{
            margin-left: .28rem;
            font-weight: bold;
            // font-size: .4rem;
            color: #231815;
        }
    }
}
</style>