<template>
  <div class="boeder">
    <div style="width: 1136px;margin: 80px auto 0px;">
      <div class="pesonnal-box">
        <div class="personal">
          <div class="title" style="background:#fff;margin:10px 0 5px;width: 893px">
            <div class="text">{{$t('app.noticeList')}}</div>
            <div class="more" @click="goMessage" v-if="messageList.length > 0">{{$t('app.More')}}</div>
          </div>
          <div style="padding: 15px 0;background:#fff;width: 893px" v-if="messageList.length > 0">
            <div style="width: 893px" class="mes-item" v-for="(item,index) in messageList" :key="index">
              <van-icon name="comment" size="22px" style="margin-top: 3px;"/>
              <div class="mess-right">
                <div class="mess-info">{{item.title}}</div>
                <div class="time-box">
                  <p>{{item.sendTime}}</p>
                  <!-- <p style="margin-left:25px">{{item.time}}</p> -->
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <van-empty style="width: 893px" :description="$t('app.No_data')" />
          </div>
          <div class="title" style="background:#fff;margin:10px 0 5px;width: 893px">
            <div class="text">{{$t('app.orderList')}}</div>
            <div class="more" v-if="list.length > 0" @click="goOrder">{{$t('app.More')}}</div>
          </div>
          <div v-if="list.length > 0">
                <van-cell
                  v-for="(item, index) in list"
                  :key="index"
                  style="width: 893px;"
                >
                  <div class="order_nav">
                    <div class="order_img">
                      <img :src="item?.img ? item.img : NoPicture" alt="" srcset="" />
                    </div>
                    <div class="order_cbk">
                      <div class="order_title">{{ item.proName }}</div>
                      <div class="order_status">
                        <span v-if="item.orderStatus == 1"><van-icon name="certificate" size="16" /></span>
                        <span v-if="item.orderStatus == 1">{{$t("app.Success")}}</span>
                        <span v-if="item.orderStatus == 2"><van-icon name="close" size="16" /></span>
                        <span v-if="item.orderStatus == 2">{{$t("app.Failure")}}</span>
                        <span v-if="item.orderStatus == 3"><van-icon name="replay" /> </span>
                        <span v-if="item.orderStatus == 3">{{$t("app.Processing")}}</span>
                        <span>{{ item.orderId }}</span>
                      </div>
                      <div class="order_date">
                        <span><van-icon name="underway-o" size="16" /></span>
                        <span>{{ item.createTime }}</span>
                        <!-- <span>|</span>
                        <span>{{ item.buyQuantity }}{{ $t("app.tickets") }}</span> -->
                      </div>
                      <div class="order_date">
                        <span>{{ item.buyQuantity }}{{ getTicket(item.buyQuantity) }}</span>
                      </div>
                      <div class="bo_text">
                        <div class="order_detail" style="cursor: pointer" @click="OrderDetails(item.orderId)">{{ $t('app.OrderDetails') }}</div>
                        <div v-if="Number(item.amount) == 0" class="order_price">{{ $t('app.noInventory') }}</div>
                        <div v-else class="order_price">
                          {{ $t("app.money") }}{{ priceFormat(item.amount) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </van-cell>
                <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../assets/img/down.png" /></div>
            </div>
            <div v-else>
              <van-empty :description="$t('app.No_data')" />
            </div>
          <div class="title" style="background:#fff;margin:10px 0 5px;width: 893px">
            <div class="text">{{$t('app.collectionList')}}</div>
            <div class="more" @click="collectionList('a', '0')" v-if="searchList.length > 0">{{$t('app.More')}}</div>
          </div>
          <display-list class="display-list" v-if="searchList.length > 0" :collectFlag="collectFlag" :dataList="searchList"  @getTabList="getTabList"></display-list>
          <div v-else>
            <van-empty :description="$t('app.No_data')" />
          </div>
        </div>
        <div class="info">
          <img v-if="avatar && avatar != 'null' && avatar != ''" :src="avatar" class="avatar">
          <img v-else src="../../assets/user_ic.png" class="avatar">
          <div class="nameclass">{{ nikeName }}</div>
          <div class="emailclass">{{ email ? email : '' }}</div>
          <div class="icon-box" @click="resetHeadVisible = true">
            {{ $t('app.editProfile') }}
          </div>
        </div>
      </div>
      <el-dialog
          center
          lock-scroll
          append-to-body
          width="500px"
          :before-close="handleClose"
          :visible.sync="resetHeadVisible"
      >
        <reset-head ref="personalInformation" @loginSuccess="loginSuccessCallback" @close="resetHeadVisible = false"/>
      </el-dialog>
      <el-dialog
        center
        lock-scroll
        append-to-body
        width="500px"
        :visible.sync="signInVisible"
      >
          <sing-in-dialog :preview="signInVisible" @loginSuccess="loginSuccess"/>
      </el-dialog>
      <!-- order detail dialog -->
      <el-dialog
        center
        lock-scroll
        append-to-body
        width="1050px"
        :visible.sync="orderDetailVisible"
      >
          <order-detail ref="orderDetail" :orderId="orderId" @closeDialog="orderDetailVisible = false" />
      </el-dialog>
    </div>
  </div>
  
</template>

<script>
import apis from "../../utils/apis";
import Vue from "vue";
import { NavBar, Icon, Form, Field, Button, Toast,Uploader } from "vant";
import Header from "../../components/Login/Header.vue";
import Footer from "../../components/Login/Footer.vue";
import user_ic_def from "../../assets/user_ic.png";
import displayList from "../search/components/displayList";
import {isNotEmpty} from "@/utils/priceFormat";
import ResetHead from "@/components/Login/ResetHead.vue";
import SingInDialog from "../../components/Login/SingInDialog.vue";
import OrderDetail from "../order/components/OrderDetailDialog.vue";
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Uploader);
let {OrderList,SearchAll} = apis
export default {
  props: ['preview'],
  components: {
    Header,
    Footer,
    displayList,
    ResetHead,
    SingInDialog,
    OrderDetail
  },
  data() {
    return {
      shareArr:[
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ],
      key: "",
      orderId: "",
      collectFlag:'1',
      areaId: '',
      avatar:'',
      email: '',
      nikeName: "",
      queryParams: {
        pageNum: 1,
        pageSize: 5,
        readStatus: 3,
        languageType: null,
        userId: null,
      },
      resetHeadVisible:false,
      orderDetailVisible: false,
      showMoreFlg: false,
      signInVisible: false,
      list: [],
      messageList:[],
      searchList: [],
          };
  },
  watch: {
    // preview: {
    //   handler(val) {
    //     if(val) {
    //       this.$nextTick(() => {
    //         this.$refs.vForm.resetValidation();
    //       })
    //     }
    //   },
    //   immediate: true
    // }
  },
  created() {
    this.activeIndex = isNotEmpty(this.$route.query.index)?Number(this.$route.query.index):"";
    this.key = this.$route.query.searchKey;
    // this.collectFlag = this.$route.query.collectFlag;
    this.nikeName = localStorage.getItem("name");
    this.email = localStorage.getItem("email");
    this.avatar = localStorage.getItem("avatar");
    if (!this.avatar || this.avatar == "undefined") {
      this.avatar = user_ic_def;
    }
    this.getList()
    this.getorderListShort()
    this.getTabList();
  },
  mounted() {
    this.randomNumber();
  },
  methods: {
    handleClose() {
      this.$refs.personalInformation.reset();
      this.resetHeadVisible = false;
    },
    getTicket(num){
      if (!isNaN(num)) {
        return Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets')
      } else {
        return this.$t('app.tickets');
      }
    },
    OrderDetails(orderId) {
      if(orderId === '' || orderId === null ) {
        this.$message({message: this.$t('app.orderExcption'), customClass: 'messageIndex'});
        return
      }
      if(!localStorage.getItem('token')) {
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        this.$EventBus.$emit('closeSingIn');
        this.signInVisible = true;
      } else {
        this.orderDetailVisible = true;
        this.orderId = orderId;
        this.$nextTick(() => {
          this.$refs.orderDetail.getOrderDetails();
        });
      }
    },
    getList() {
      let types = {
      "en": 2,
      "cn": 0,
      "jp": 1,
      "hk": 3
      }
      this.queryParams.languageType = localStorage.getItem("language") == null ? 2 : types[localStorage.getItem("language")];
      this.queryParams.userId = localStorage.getItem("userId");
      // this.queryParams.userId = '1643875911356440576';
      apis.getMessagetList(this.queryParams, (data) => {
        this.messageList = data.data.list;
        // this.total = data.data.total;

        // if(data.data.list.length === 20) this.showMoreFlg = true;
        // else this.showMoreFlg = false;
      });
    },
    getorderListShort() {
      let params = {
        "mail": localStorage.getItem('email'), //注册用的邮箱，接收系统发送的验证码。
        "searchType": 0, //搜索类型：0-全部订单；2-失败订单；1-成功订单；3-处理中订单
        "pageNum": 1,
        "pageSize": 3,
      }
      OrderList({...params}, data => {
        // this.total = data.data.total;
        // this.pageNum = Math.ceil(this.total / this.pageSize);
        this.list = data.data.list;
      })
    },
    randomNumber() {
      let shareArr = [
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ];
      var str = ""; //重新调用赋值给空
      for (var i = 0; i < shareArr.length; i++) {
        var rond = Math.round(Math.random());
        str += shareArr[rond];
      }
      this.viewa = str;
    },
    getTabList(val) {
      let _this = this;
      // if(isNotEmpty(val)){
      //   this.pageSize = Number(this.pageSize) * Number(this.currentPage);
      //   this.currentPage = 1
      // }
      let params = {
        searchType: 0,
        key: this.key || "",
        areaId: this.areaId,
        pageSize: 3,
        pageNum: 1,
        userId: localStorage.getItem('userId'),
        type:this.activeIndex,
        collectFlag:"1"
      };

      let shareArr = ["1000+","2000+","3000+","4000+","5000+","6000+","7000+","8000+","9000+"];

      SearchAll({ ...params }).then(res => {
        this.total = res.data.data.total;

        _this.searchList = res.data.data.list;
        _this.searchList.forEach(item=>{
          item.favorite = {
            favorite_id:item.id,
            type:item.type,
            userid: localStorage.getItem('userId'),
            favoriteFlg:item.favoriteFlg,
            width: 21,
            height: 21,
          }
        })

        // if(res.data.data.list.length === 20) this.showMoreFlg = true;
        // else this.showMoreFlg = false;

        this.searchList.forEach((it) => {
          it.viewa = shareArr[Math.floor(Math.random() * shareArr.length)];
        });
      });
    },
    goMessage(){
      this.$router.push("/noticeList");
    },
    goOrder(){
      this.$router.push("/order");
    },
    collectionList(e, index) {
      this.$router.push({
        path: "/result",
        query: {
          name: e,
          index: index,
          collectFlag:"1"
        },
      });
    },
     loginSuccessCallback() {
      this.signInVisible = false;
      this.uInfoFlg = true;
      this.email = localStorage.getItem("email");
      this.avatar = localStorage.getItem("avatar");
      this.nikeName = localStorage.getItem("name");
      this.userName = this.name ? this.name:this.email;
      window.location.reload()
    },
    loginSuccess() {
      this.signInVisible = false;
      this.$EventBus.$emit('closeSingIn')
    },
  },
};
</script>

<style scoped lang="less">
// .login_input {
//     padding: .4rem .64rem;
// }
.boeder {
  background:#f7f5f5;
}
.back {
  color: #acacac;
  position: absolute;
  top: 35px;
  left: 15px;
}
.pesonnal-box {
  width: 1134px;
  display: flex;
}
.info {
    width: 215px;
    height: 280px;
    background: #fff;
    // position: absolute;
    // top: 91px;
    // right: 780px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 10px;
    .nameclass {
      font-size: 20px;
      color: #000000;
      font-weight: 600;
    }
    .emailclass {
      font-size: 18px;
      color: #acacac;
    }
    .icon-box {
      min-width: 95px;
      max-width: 118px;
      height: 35px;
      line-height: 35px;
      border-radius: 18px;
      font-size: 16px;
      color: #000000;
      font-weight: 600;
      border: 1px solid #000000;
      cursor: pointer;
      padding: 0 10px;
    }
}
.avatar {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 1px solid #e0e0e0;
}
.personal {
  width: 900px;
  // background: orange;
}
.display-list {
  width: 60%;
  margin:inherit;
}
/deep/.display-list .list_nav {
   width: 893px;
}
.title {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background: #f7f5f5;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  .text {
    color: #000000;
    font-weight: 600;
  }
  .more {
    margin-right: 40px;
    color: #999999;
    cursor: pointer;
  }
}
.mes-item {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
}
.mess-right {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mess-info {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.time-box {
  font-size: 18px;
  display: flex;
  color: #999999;
}

.order_nav {
  display: flex;
  justify-content: space-between;

  .order_img {
    img {
      border-radius: 0.12rem;
      object-fit: cover;
      width: 178px;
      height: 140px;
    }
  }

  .order_cbk {
    flex: 1;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0.36rem;
    min-width: 0;

    .order_title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.25rem;
      height: 0.26rem;
    }

    .order_status {
      span {
        color: #595757;
        font-size: 0.15rem;
        padding-right: 0.1rem;
      }
    }

    .order_date {
      span {
        font-size: 0.15rem;
        padding-right: 0.1rem;
      }
    }

    .bo_text {
      display: flex;
      justify-content: space-between;
      .order_detail {
        font-size: .18rem;
        color: #006699;
        cursor: default;
      }
      .order_price {
        font-size: .2rem;
      }
    }

  }
}
</style>
