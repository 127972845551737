<template>
  <div>
    <div class="header">
      <i class="van-icon van-icon-arrow-left" @click="goback()"></i>
      <span v-if="title" class="headerText">{{ $t('app.thingsToDoIn') }} {{ `"${title}"` }}</span>
      <span v-else class="headerText">{{ $t('app.thingsToDo') }}</span>
    </div>
    <common-part :dataList="introductList" title="introduction" />
    <common-part :dataList="areaList" title="area" />
    <common-part :dataList="scenicList" title="scenicSpot" />
    <common-part :dataList="shopList" title="shop" />
    <common-part :dataList="ticketList" title="ticket" />
  </div>
</template>
<script>
import apis from "../../utils/apis";
import CommonPart from  "./components/SearchAllCommon.vue";

export default {
  components: { CommonPart },
  data() {
    return {
      title: '',
      searchList: [],
      introductList: [],
      areaList: [],
      scenicList: [],
      shopList: [],
      ticketList: [],
    }
  },
  created() {
    this.title = this.$route.query.title || '';
    this.getTableList('1')
    this.getTableList('2')
    this.getTableList('4')
    this.getTableList('5')
    this.getTableList('6')
  },
  methods: {
    goback(){
       this.$router.back()
    },
    getTableList(type) {
      let params = {
        searchType: type,
        key: this.title || "",
      };
      apis.SearchAll({ ...params }).then(res => {
          switch(type) {
            case '1':
              this.areaList = res.data.data.list;
              break;
            case '2':
              this.scenicList = res.data.data.list;
              break;
            case '4':
              this.introductList = res.data.data.list;
              break;
            case '5':
              this.shopList = res.data.data.list;
              break;
            case '6':
              this.ticketList = res.data.data.list;
              break;
          }
      });
    },
    toModule() {
      this.$router.push({
        path: "/result",
        query: {
          searchKey: this.title,
          name: 'a',
          index: '0',
        },
      });
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .header {
  display: flex;
  justify-content: left;
  width: 1136px;
  margin: 130px auto 50px;
  .headerText {
    margin-left: .1rem;
    font-weight: 600;
    font-size: .26rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    width: 100%;
    text-align: left;
  }
}
</style>