<template>
  <div>
    <!-- <Header @goBack="goBack"></Header> -->
    <Loginlogo></Loginlogo>
    <div class="login">
      <h1 class="login_title">
        {{ $t("app.login_code") }}
      </h1>
      <div class="login_input">
        <van-form ref="vForm" @submit="onSubmit">
          <p class="label">{{ $t("app.code") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
              :rules="[
                { required: true, message: $t('app.verification_code') },
              ]"
              name="code"
              v-model="inputvalue"
              placeholder=""
            />
          </van-cell-group>
          <!-- <van-field v-model="username" name="用户名" placeholder="Verification code" /> -->

          <div style="margin: 36px 0">
            <van-button round block type="info" native-type="submit"
              >OK
            </van-button>
          </div>
        </van-form>
      </div>
      <Footer></Footer>
    </div>
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="resetPswVisible"
      @close="resetFrom"
    >
      <reset-psw :preview="resetPswVisible" @close="close" />
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import apis from "../../utils/apis";
import { NavBar, Icon, Form, Field, Button, Toast } from "vant";
import Header from "../../components/Login/Header.vue";
import Footer from "../../components/Login/Footer.vue";
import Loginlogo from "../../components/Login/Loginlogo.vue";

import ResetPsw from "../Login/ResetPsw.vue"

Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
let { verifyCode } = apis;
export default {
  props: ['preview'],
  components: {
    Header,
    Footer,
    Loginlogo,
    ResetPsw,
  },
  data() {
    return {
      resetPswVisible: false,
      value1:
        localStorage.getItem("language") == null
          ? "en"
          : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      username: "",
      inputvalue: "",
      telRules: [
        {
          required: true,
          message: "code is required",
          trigger: "onBlur",
        },
      ],
    };
  },
  watch: {
    preview: {
      handler(val) {
        if(val) {
          this.$nextTick(() => {
            this.$refs.vForm.resetValidation();
            this.inputvalue = '';
          })
        }
      }, 
      immediate: true
    }
  },
  methods: {
    close(code) {
      this.$emit('close', code);
      this.resetPswVisible = false
    },
    resetFrom() { 
      this.$refs.vForm.resetValidation();
      this.inputvalue = '';
    },
    onSubmit() {
      // console.log('submit', values);
      let params = {
        mail: localStorage.getItem("restMail"),
        code: this.inputvalue,
      };
      verifyCode({ ...params }, (data) => {
        if (data.code == 200) {
        //   if (data.data.code == -1) {
        //     Toast.fail(data.data.msg);
        //     return;
            // this.$router.push('/repwd')
        //   }
          Toast.success(data.msg);
        //   this.$router.push("/repwd");
          this.resetPswVisible = true;
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
/deep/.van-nav-bar__content {
  height: 0.92rem;
}
/deep/.van-cell-group--inset {
  margin: 0;
}
/deep/.van-cell {
  padding: 0;
}
// /deep/.van-button--normal {
//   height: 50px;
//   font-size: 18px;
// }
/deep/.van-field__body {
  height: 47px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 0.24rem;
}
.login {
//   padding: 0 0.5rem;

  .login_title {
    text-align: left;
    margin: 30px 0;
    color: #000000;
    width: 380px;
  }
}
.label {
  text-align: left;
}

.van-button {
  background-color: #000000;
  border: none;
  width: 322px;
  margin: 0 auto;
  height: 54px;
}

.van-button--normal {
  font-size: 17px;
  font-weight: 600;
}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 32px;
}
/deep/ .el-dialog--center {
  border-radius: 8px;
}
</style>
