<template>
  <div class="contact">
    <div class="header">
      <div class="row_1">Secure Chechout</div>
      <div class="row_2">Chechout securely - it takes only a few minutes</div>
      <div class="row_3">Need to fill in the information of 2 people</div>
    </div>
    <el-form>
      <div class="title">Traveler1</div>
      <el-row>
        <el-col :span="8">
          <el-form-item label="Surname">
            <el-input></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Given name">
            <el-input></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Email">
            <el-input></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="title">Traveler2</div>
      <el-row>
        <el-col :span="8">
          <el-form-item label="Surname">
            <el-input></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Given name">
            <el-input></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Email">
            <el-input></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="title">Contact person</div>
      <el-row>
        <el-col :span="8">
          <el-form-item label="Name">
            <el-input></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Email">
            <el-input></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="text-align: left;">
        <el-form-item>
          <el-button round @click="dialogVisible = true">Next</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="1050px"
      :visible.sync="dialogVisible"
    >
        <order-pay />
    </el-dialog>
  </div>
</template>
<script>
import OrderPay from "../../components/OrderPay.vue"

export default {
  components: { OrderPay },
  data() {
    return {
      dialogVisible: false,
    }
  },
}
</script>
<style scoped lang="less">
.contact {
  width: 1136px;
  margin: 126px auto 46px;
  .header {
    text-align: left;
    .row_1 {
      font-size: .28rem;
      font-weight: 600;
    }
    .row_2 {
      background: #eafaf4;
      border-radius: 15px;
      font-size: .2rem;
      margin: 18px 0;
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }
    .row_3 {
      font-size: .2rem;
      color: #9a9a9a;
    }
  }
  /deep/ .el-form {
    .title {
      font-size: .28rem;
      font-weight: 600;
      text-align: left;
      margin: 30px 0 14px;
    }
    .el-form-item__label {
      font-size: .2rem;
      color: #000000;
    }
    .el-form-item__content {
      margin-right: 16px;
    }
    .el-input__inner {
      height: 47px;
    }
    .el-button {
      background: #000000;
      color: #fff;
      width: 236px;
      height: 48px;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 600;
      margin-top: .4rem;
    }
  }
}
/deep/ .el-dialog--center .el-dialog__body {
  padding: 0 32px 32px;
}
/deep/ .el-dialog--center{
  border-radius: 8px;
}
</style>