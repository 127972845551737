<template>
  <div style="width: 1136px; margin: 0 auto">

    <div class="header">
        <div @click="goback"><van-icon name="arrow-left" /></div>
        <div class="dictTitle">{{ $t('app.all_area') }}</div>
    </div>
    <div class="dictItem">
        <div class="videoInfo" v-for="(item, index) in areaList" :key="index">
            <div class="imgDiv" @click="toDetail(item)">
              <img :src="item.img" class="img" alt="" />
              <div class="title">
                <div class="subtitle">{{ item.areaName }}</div>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import apis from "../../utils/apis";

export default {
  data() {
    return {
      areaList: [],
    }
  },
  created() {
    this.getAreaList();
  },
  methods: {
    getAreaList() {
      apis.getAreaList({}, data => {
        this.areaList = data.data;
      })
    },
    goback() {
      this.$router.go(-1);
    },
    toDetail(item) {
      this.$router.push({
        path: "essayDetail",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
  }
}
</script>
<style lang="less" scoped>
.header {
  margin-top: 1.3rem;
  display: flex;
  .dictTitle {
    text-align: left;
    font-size: 0.28rem;
    font-weight: 600;
    margin-bottom: .2rem;
  }
  .van-icon {
    cursor: pointer;
  }
}
.dictItem {
    width: 100%;
    text-align: left;
    .videoInfo {
        display: inline-block;
        width: 272px;
        height: 2rem;
        margin-right: 16px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        .imgDiv:hover {
          transform: translateY(-.04rem);
          transition-duration: 0.5s;
        }
        .imgDiv {
          position: relative;
          height: 100%;
          cursor: grab;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: .16rem;
          }
          .title {
            position: absolute;
            bottom: 0;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 34.41%, rgba(0, 0, 0, 0) 100%);
            width: 94%;
            padding: 10% 3%;
            color: white;
            text-align: center;
            border-bottom-right-radius: .16rem;
            border-bottom-left-radius: .16rem;
            .subtitle{
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: .23rem;
            }
          }
        }
    }
}
.videoName {
    margin-top: 0.04rem;
    font-size: 0.18rem;
    text-align: start;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    height: .4rem;

}
</style>