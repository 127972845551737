<template>
    <div>
        <div class="logo">
            <img src="../../assets/img/logo_icon.png" alt="" srcset="">
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{

        }
    }
}
</script>
<style scoped lang="less">
.logo {
    width: 100%;
    // padding: .48rem .5rem;
    // height: 2.2rem;
    height: 47px;
    box-sizing: border-box;
    img {
        float: left;
        // width: 3.4rem;
        width: 145px;
        // height: 70px;
    }
}
</style>