<template>
  <div class="collect">
    <a @click="shareClick()">
      <svg t="1689216746929" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2444" :style="{width: (width - 1) + 'px',height: (height - 1) + 'px'}"><path d="M512 73.142857v73.142857C310.016 146.285714 146.285714 310.016 146.285714 512s163.730286 365.714286 365.714286 365.714286 365.714286-163.730286 365.714286-365.714286h73.142857c0 242.358857-196.498286 438.857143-438.857143 438.857143S73.142857 754.358857 73.142857 512 269.641143 73.142857 512 73.142857z m354.450286 122.258286l-339.309715 339.309714L475.428571 482.998857 812.141714 146.285714h-201.691428V73.142857h329.142857v329.142857h-73.142857V195.401143z" fill="#333333" p-id="2445"></path></svg>
      <span v-if="textFlg" style="font-weight: normal;margin-left: 4px;font-size: 18px;font-weight: normal">
        {{$t("app.favorite.share")}}
      </span>
    </a>
    <a @click="collectClick()" id="collect">
      <svg v-if="favoriteFlg == '1'"  t="1688528558335" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="998" :style="{width: width + 'px',height:height + 'px'}" style="margin-left:12px;">
        <path d="M494.994286 794.697143l-214.930286 113.005714a36.571429 36.571429 0 0 1-53.028571-38.546286l40.996571-239.323428a36.571429 36.571429 0 0 0-10.496-32.365714l-173.860571-169.472a36.571429 36.571429 0 0 1 20.260571-62.390858l240.274286-34.925714a36.571429 36.571429 0 0 0 27.538285-20.004571l107.446858-217.709715a36.571429 36.571429 0 0 1 65.609142 0l107.446858 217.709715a36.571429 36.571429 0 0 0 27.538285 20.004571l240.274286 34.925714a36.571429 36.571429 0 0 1 20.260571 62.390858l-173.860571 169.472a36.571429 36.571429 0 0 0-10.532571 32.365714l41.069714 239.323428a36.571429 36.571429 0 0 1-53.065143 38.546286l-214.930286-113.005714a36.571429 36.571429 0 0 0-34.011428 0zM512 700.306286l205.970286 108.288-39.350857-229.339429 166.656-162.450286-230.290286-33.462857L512 174.628571l-102.985143 208.676572-230.290286 33.462857 166.619429 162.450286-39.314286 229.339428L512 700.342857z" fill="#fa9d0b" p-id="999"></path>
      </svg>
      <svg v-else t="1688528558335" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="998" :style="{width: width + 'px',height:height + 'px'}" style="margin-left:12px;">
        <path d="M494.994286 794.697143l-214.930286 113.005714a36.571429 36.571429 0 0 1-53.028571-38.546286l40.996571-239.323428a36.571429 36.571429 0 0 0-10.496-32.365714l-173.860571-169.472a36.571429 36.571429 0 0 1 20.260571-62.390858l240.274286-34.925714a36.571429 36.571429 0 0 0 27.538285-20.004571l107.446858-217.709715a36.571429 36.571429 0 0 1 65.609142 0l107.446858 217.709715a36.571429 36.571429 0 0 0 27.538285 20.004571l240.274286 34.925714a36.571429 36.571429 0 0 1 20.260571 62.390858l-173.860571 169.472a36.571429 36.571429 0 0 0-10.532571 32.365714l41.069714 239.323428a36.571429 36.571429 0 0 1-53.065143 38.546286l-214.930286-113.005714a36.571429 36.571429 0 0 0-34.011428 0zM512 700.306286l205.970286 108.288-39.350857-229.339429 166.656-162.450286-230.290286-33.462857L512 174.628571l-102.985143 208.676572-230.290286 33.462857 166.619429 162.450286-39.314286 229.339428L512 700.342857z" fill="#333333" p-id="999"></path>
      </svg>
      <span v-if="textFlg && favoriteFlg == '1'" style="font-weight: normal;margin-left: 4px;color: #fa9d0b;font-size: 18px;font-weight: normal">
        {{$t("app.favorite.collected")}}
      </span>
      <span v-if="textFlg && favoriteFlg != '1'" style="font-weight: normal;margin-left: 4px;font-size: 18px;font-weight: normal">
        {{$t("app.favorite.collect")}}
      </span>
    </a>

    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="signInVisible"
    >
      <sing-in-dialog :preview="signInVisible" @loginSuccess="loginSuccessCallback"/>
    </el-dialog>
  </div>
</template>

<script>
import apis from "@/utils/apis";
import {isNotEmpty} from "@/utils/priceFormat";
import SingInDialog from "@/components/Login/SingInDialog.vue";

let { updCollectFlg } = apis;
export default {
  components: { SingInDialog },


  name: "collect",
  props: ["favorite", "textFlg"],
  data() {
    return {
      width:'',
      height:'',
      favoriteFlg:"",
      favorite_id:"",
      type:"",
      userid:"",
      signInVisible: false,
    }
  },
  created() {
    if(this.favorite!=null){
      this.favorite_id = this.favorite.favorite_id;
      this.type = this.favorite.type;
      this.userid = this.favorite.userid;
      this.favoriteFlg = this.favorite.favoriteFlg;
      this.width = this.favorite.width;
      this.height = this.favorite.height;
    }
  },
  methods: {
    loginSuccessCallback() {
      this.signInVisible = false;
    },
    shareClick() {
      const textArea = document.createElement('textArea')
      if(this.type=='1' || this.type=='2' || this.type=='4'){
        textArea.value = window.location.origin + '/essayDetail?type=' + this.type + '&id=' + this.favorite_id;
      } else if(this.type=='5'){
        textArea.value = window.location.origin + '/detailsPage?type=' + this.type + '&id=' + this.favorite_id;
      } else {
        textArea.value = window.location.origin + '/orderXq?type=' + this.type + '&id=' + this.favorite_id;
      }
      textArea.style.width = 0
      textArea.style.position = 'fixed'
      textArea.setAttribute('readonly', 'readonly')
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      this.$message({message: this.$t("app.copy_div"), customClass: 'messageIndex',type:'success'});
    },
    collectClick () {
      var flg = "";
      if (this.favoriteFlg  === '1') {
        flg = "0";
      } else {
        flg = "1";
      }
      let params = {
        favoriteFlg:flg,
        favorite_id:this.favorite_id,
        type:this.type,
        userid:this.userid,
        userName:localStorage.getItem('name'),
        domainUrl: window.location.host,
      }
      if(!isNotEmpty(this.userid)){
        this.signInVisible = true;
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
      } else {
        updCollectFlg({ ...params }, (data) => {
          this.favoriteFlg = flg;
          if(flg == '0'){
            this.$emit('getTabList',1);
          }
        });
      }
    }
  },
  watch: {
    favorite:{
      handler(data) {
        if (data != null) {
          this.favoriteFlg = data.favoriteFlg;
          this.favorite_id = data.favorite_id;
          this.type = data.type;
          this.userid = data.userid;

        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.collect {
  font-size: 0.3rem;
  font-weight: 700;
  text-align: left;
  display: flex;
  height: 42px;
  align-items: center;
}

.collect a{
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
