<template>
  <div>
    <van-divider :style="{ color: '#231815', borderColor: '#e0e0e0', fontSize: '17px' }">
      {{ type == 0 ? $t('app.Not_a_member') : $t('app.Already_a_member') }}
    </van-divider>
  </div>
</template>

<script>
export default {
  props: ["type"],

  data() {
    return {}
  }

}
</script>

<style>

</style>