export default {
    app: {
        hello: 'こんにちは、世界！',
        cn: '中文（简体)',
        hk: '中文（繁体)',
        en: 'English',
        jp: '日本語',

        language: '言語',
        signIn: 'ログイン',
        personal: '個人用ページ',
        editProfile: '情報の変更',
        orderList: '予約リスト',
        changePwd: 'パスワードを変更する',
        personalInformation:'個人情報',
        logOut: 'ログアウト',
        collectionList: 'コレクションリスト',
        register: '登録',

        search: '検索',

        introduction: '人気記事',
        area: 'エリア',
        scenicSpot: '観光スポット',
        shop: '店舗',
        ticket: 'チケット',

        whereTo: '興味のある地名や言葉を入力して検索',

        enterBasicInfo: '基本情報記入',
        userName: 'ユーザー名',
        submit: '送信',

        all_area: '全部地域',
        other_area: 'その他地域',
        recommendedScenicSpots: 'おすすめスポットです',

        Welcome_back: 'おかえりなさい',
        Email_address: 'メールアドレス',
        Password: 'パスワード',
        Forgot_password: 'パスワードを忘れます?',
        restPsw: '2回のパスワードが一致しません',
        Sign_in: "ログイン",
        line_login: 'LINEでログインする',
        email_login: 'メールアドレスでログインする',
        Not_a_member: "メンバーではありません?",
        medin_bank: "最高のMedia Bank Incを利用するには参加してください.",
        cookie_state: "利用規約に同意し、プライバシーおよびクッキー声明を読んだことを確認する.",
        forget_pwd: "パスワードを忘れます?",
        enter_your_pwd: "問題ありません。以下にメールアドレスを入力してください-私たちはリセットするためのコードを送信します.",
        login_code: "確認コードを入力してください",
        code: "確認コード",
        reset_pwd: "パスワードをリセットする",
        new_pwd: "新しいパスワード",
        new_pwd_confirmation: "新しいパスワードの確認",
        nike_name: "ニックネーム",
        Create_Account: "アカウントを作成する",
        create_pwd: "パスワードを作成する",
        displayed_title: "Media Bank の事前予約のみ表示されます。",
        All: "すべて",
        Success: "成功",
        Failure: "失敗",
        Processing: "処理中",
        Forest_zoo: "森の動物園",
        Business_Hours: "営業時間",
        Address: "住所",
        Notice: "注意事項",
        Peripheral_Selection: "周辺選択",
        Explore_now: "今すぐ探索する",
        space_for_lese: "広告スペースを貸し出し中",
        we_got: "2023年に日本を旅するのに必要なすべてが揃っています",
        Guideline: "ガイドライン",
        Area: "エリア",
        Scenic_spot: "観光地",
        Shop: "ショップ",
        Ticket: "チケット",
        topResult: "すべてのお気に入りて “{key}” に一致する上位の結果",
        topResult1: "すべてのカテゴリーの人気記事一覧",
        collectResult: "すべてのお気に入り “{key}” に一致する上位の結果",
        collectResult1: "すべてのカテゴリーの人気記事一覧",
        All_Category: "{value}における上位の結果",
        book: "予約",
        Zoo_ticket: "動物園のチケット",
        Products: "要件を満たす製品. ",
        Ticket_type: "チケットの種類",
        Children: "子供",
        Date_selection: "日付選択",
        Purchase_quantity: "購入数量",
        Next: "次へ",
        pay_successful: "支払いが成功しました。チケットの発行をお待ちください",
        ticket_issued_successful: "チケットの発券が成功しました。詳細はご提供いただいたメールをご確認ください",
        Complete: "完了",
        My_Order: "私の注文",
        need_people: "2人分の情報を入力する必要があります",
        Traveler: "旅行者 ",
        Traveler_wo: "旅行者2",
        Contact_person: "联系人",
        Surname: "名前 ：",
        Given_name: "下の名前 ：",
        Full_name: "名前 ：",
        Phone: "電話番号+86 ：",
        Email: "メールアドレス ：",
        Please_surname: "姓を入力してください",
        Please_name: "名を入力してください",
        Please_number: "携帯電話番号を入力してください",
        Please_email: "メールアドレスを入力してください",
        Email_required: "メールアドレスが必要です",
        Email_required_must: "有効なメールアドレスを入力してください",
        enter_pwd: "ユーザーパスワードを1つ入力してください",
        UserName_required: 'ユーザー名が必要です',
        Address_required: '住所が必要です',
        verification_code: "確認コードを入力してください",
        Please_pwd: "新しいパスワードを入力してください",
        Please_newpwd: "新しいパスワードをもう一度入力してください",
        There_is_present: "現在このタイプの注文はありません",
        To_explore: "探索する",
        money: "￥",
        bookNow: "予定",
        Traveler_Details: "旅行者の詳細",
        No_data:"データがありません",
        Required:"記入が必要",
        human:"にんげんじょうほう",
        Order_Status:"受注ステータス",
        Payment_Status:"支払ステータス",
        Type:"タイプ",
        Date:"日付",
        Quantity:"日付数",
        reviews:"views",
        TrendingTravel:"旅行トレンド",
        Places_Go:"行くところ",
        Adventure:"探検の旅",
        about:'について',
        Rating:"評価",
        DateList:"価格カレンダーの取得に失敗しました",
        calendarConfirmText: '確定です',
        noInventory: '在庫はまだありません',
        change_language_null_tips: '選択した言語にはデータがありません。前のページに戻りますか?',
        data_null_tips: 'しばらくデータがありません',

        noticeList: 'メッセージリスト',
        noticeDetail: 'メッセージの詳細',
        noticeTitle: 'メッセージタイトル: ',
        sendTime: '公開日: ',
        noticeContent: 'メッセージの内容: ',
        read: '既読',
        unRead: '未読',
        delete: '削除します',
        cancel: 'キャンセル',
        allNotice: '合計{total}つのメッセージ',
        allUnRead: '未読メッセージ合計{total}件',
        allRead: '既読メッセージ合計{total}件',


        productDescription: '製品説明',
        detailsIntro: '詳細紹介',
        reserveNotice: '予約説明',
        productUseFlow: '【利用フロー】',
        productLanguage: '【サービス言語】',
        productAudienceAsk: '【製品の対象者】',
        travelDuration: '【旅行時間】',
        groupSize: '【最小催行人数】',
        proAddress: '【住所】',
        businessHours: '【営業時間】',
        productCostInclude: '【料金に含まれるもの】',
        productCostExclusive: '【料金に含まれないもの】',
        advanceDays: '【予約の締め切り】',
        confirmWay: '【予約の確認方法】',
        effectiveDate: '【有効期間】',
        refundInstructions: '【返金説明】',
        predetermineNotice: '【予約に関する注意事項】',
        automaticConfirm: '自動確認',
        secondaryConfirm: '二次確認',
        English: '英語',
        Chinese: '中文',
        Japanese: '日本語',
        Korean: '韓国語',
        advanceDaysText: '{day}日前の{time}までに予約',
        orderValidText0: '選択した遊び日付は現在有効です',
        orderValidText1: '予約日が{day}日延長されます',
        orderValidText2: '予約日は{date}まで有効です',
        orderValidText3: '選択した日付は{day}日延長されます',
        orderValidText4: '選択した日付は{date}まで有効です',
        orderValidText5: '{startDate}から{endDate}まで有効です',
        orderValidText6: '指定された使用日の{startDay}前から{endDay}後まで有効です',



        About_MEDIA_BANK: 'Media Bankについて',
        MEDIA_BANK_Sites: 'Media Bankサイト',
        Privacy: 'プライバシーポリシー',
        Contactus: '問い合わせ',
        termsOfUse: 'ユーザー利用規約',

        howToContact: '問題がある場合は、以下の方法でお問い合わせください',
        tel: '電話',
        mail: 'メール',
        translate: '日本語(JP)に翻訳',
        translateFrom: '日本語からの翻訳',
        originalContent: '原文表示',
        translateContent: '翻訳',

        exploreNow: 'もっと見る',
        exploreAll: 'すべて参照',

        thingsToDoIn: '何かする',
        thingsToDo: '何かする',

        OrderDetails: 'オーダー詳細',
        orderExcption: 'オーダーデータ例外',

        total: 'すべての',
        Contact_information: '連絡先情報',
        name: 'の名前をあげる:',
        eMail: 'Eメール:',
        close: '閉じる',
        More_Detail: 'もっと',
        More: 'もっと見る',
        System_Order_ID: 'システムオーダーID:',
        Payment_Order_ID: '支払オーダ番号:',
        Ticket_Order_ID: 'チケット注文ID:',
        System_Order_Status: 'システムオーダーステータス:',
        Ticket_PDF_Url: 'チケット枚 PDF:',

        homeTitle1: '人気エリア',
        homeSubtitle1: 'エリア別の見どころ紹介',
        homeTitle2: '人気店舗',
        homeSubtitle2: '日本各地の魅力を店舗で楽しむ.',
        homeTitle3: '人気観光スポット',
        homeSubtitle3: '訪れるべき名所＆人気スポット',
        homeTitle4: '人気チケット',
        homeSubtitle4: '日本を堪能できる特別な体験をラインナップ.',
        homeTitle5: '人気記事',
        homeSubtitle5: '人気の観光スポットやグルメ情報、モデルコースなどを紹介.',

        priceVaries: '価格はグループ規模によって異なる',

        joininMEDIN_BANK: 'Media Bank Incアカウントを使用したログイン.',
        Already_a_member: 'すでにメンバー?',

        secureCheckout: 'セキュリティチェックアウト',
        secureCheckoutText: 'セキュリティチェックアウト - ほんの数分で',
        PayNow: '即時支払',
        trade_tips:'お支払いは2分以内に完了してください。途中で支払いが中断された場合、注文はキャンセルされます.',
        paymentProtection: '支払い保護',
        non_refundable: '返金不可です',
        minimum_order: '1枚から買う',


        liaisonDetail: '連絡詳細',
        customerName: '名前',
        customerTelephone: '携帯電話',
        customerEmail: 'メール',
        customerWechat: 'WeChat ID',
        customerSex: '性別',
        man: '男性',
        woman: '女性',
        customerUseDate: '利用日',
        customerCode: '郵便番号',
        customerAddress: '住所',
        customerCreditType: '身分証明書の種類',
        customerCreditNo: '身分証明書番号',
        customerReceiveDate: '受け取り日',

        customerNameRequired: '名前を入力してください',
        customerTelephoneRequired: '携帯電話番号を入力してください',
        customerWechatRequired: 'WeChat IDを入力してください',
        customerPassportRequired: 'パスポート番号を入力してください',
        customerSexRequired: '性別を選択してください',
        customerUseDateRequired: '利用日を選択してください',
        customerCodeRequired: '郵便番号を入力してください',
        customerAddressRequired: '住所を入力してください',
        customerCreditTypeRequired: '身分証明書の種類を選択してください',
        customerCreditNoRequired: '身分証明書番号を入力してください',
        customerReceiveDateRequired: '受け取り日を選択してください',
        pleaseEnter: '入力してください',
        reservation: '予約に関する注意事項',

        identityCard: '身分証明書',
        studentIDCard: '学生証',
        militaryIDCard: '軍人証',
        passport: 'パスポート',
        residenceBooklet: '戸籍謄本',
        hongKongAndMacaoPass: '香港・マカオ通行証',
        taiwanSyndrome: '台湾居民来往大陸通行証',



        aboutPlace: 'この場所について',
        aboutJTA:'Japan Tourism Agencyについて',
        aboutJED:'\"Japan. Endless Discovery.\"logo について',

        tickets:'張',
        tickets_s:'張',

        videoList:'人気動画',
        videoList_sub:'みんなの好きな',
        allVideos: 'すべてのビデオ',

        readMore: '続きを読む',
        Check_availability: '価格カレンダー',
        copyright:'Copyright © 2023 Media Bank Inc. All rights reserved.',

        plz_login: '先にログインしてください',
        showAllAreaContent: '地域に関連するすべてのコンテンツの表示',

        emailEntryHint: 'このメールボックスは電子チケットの証明書を受け取るために使用されますので、丁寧に記入してください。記入ミスをすると電子チケットを取得できません。',
        auth_loading:'認証中です、お待ちください...',
        auth_failed:'認証に失敗しました、後でもう一度お試しください。',
        back:'戻る',
        pay_failed:'支払いに失敗しました。入力した支払い情報を確認してください。',
        verygood:'すばらしい',
        createdOrderFailed: 'オーダー作成に失敗しました',

        website:'ウェブサイト',
        position:'位置',
        go_order:'注文する',
        noOperation: '営業終了',
        businessWeeks:{ week1:'月曜日',week2:'火曜日',week3:'水曜日',week4:'水曜日',week5:'金曜日',week6:'土曜日',week7:'日曜日'},
        store_operation:'営業時間',
        store_introduction:'店舗紹介',
        store_Information:'店舗情報',
        store_periphery:'店舗周辺',
        store_comment:'店舗評価',
        store_comment_addBtn:'評価',
        store_comment_placeholder: '評価内容を入力願います',
        store_comment_title: 'ユーザーの評価',
        store_comment_null: '評価内容を空欄にしてはいけません',
        store_comment_success: '成功を評価',
        store_comment_del: '削除成功',
        confirm: '確認',
        store_comment_delTips: '当該レビューの削除を確認しますか',
        copy_div:"クリップボードにコピーされました",
        copy_entry_div:"該当店舗には関連情報はございません",

        fresh_news_title:"新しいもの",
        fresh_news_subtitle:"店舗熱検索コンサルティング",
        fresh_news_detail_title:"ホット検索コンサルティング",

        new_nike_name:'新しいニックネーム',
        Please_new_nike_pwd: "ニックネームを入力してください",
        user_pic:"顔写真",
        user_info:'個人情報',

        image_require: '画像ファイルをアップロードしてください',
        image_max_2: '画像サイズは 2MB を超えることはできません。',

        plan_go: '行く予定',
        user_plan_go: ' 人で行く予定です!',
        plan_added: 'プラン追加',
        come_plan: 'まだ行く予定の人はいないので、ぜひ参加してください！',
        more_plan: 'もっと ...',
        add_plan_success: 'プラン追加',
        cancel_plan_success: 'キャンセル',

        pop_introduction_recommend: '人気攻略のおすすめ',
        pop_area_recommend: '人気地域のおすすめ',
        pop_scenicSpot_recommend: '人気スポットのおすすめ',
        pop_shop_recommend: '人気店舗のおすすめ',
        guess_you_like: 'あなたが好きだと思う',
        everyone_plans_go: 'みんなで行こうと計画している',
        introduction_list: '人気記事一覧',
        introduction_collect_list: '人気記事のお気に入り一覧',
        area_list: 'エリア一覧',
        area_collect_list: 'エリアのお気に入り一覧',
        scenicSpot_list: '観光スポット一覧',
        scenicSpot_collect_list: '観光スポットのお気に入り一覧',
        shop_list: '店舗一覧',
        shop_collect_list: '店舗のお気に入り一覧',
        ticket_list: 'チケット一覧',
        ticket_collect_list: 'チケットのお気に入り一覧',

        merchant_enter: '出店者向け管理ページ',
        discount_price: '割引価格',

        SCTAI_title:'特定商取引法に基づく表記',
        SCTAI_col1_title:'運営主体',
        SCTAI_col1_val:'株式会社 Media Bank',
        SCTAI_col2_title:'販売事業者所在地',
        SCTAI_col2_val:'〒105-0001 東京都港区⻁ノ⾨ 1-10-5 KDX ⻁ノ⾨ 1 丁⽬ビル 11 階',
        SCTAI_col3_title:'代表者または運営統括責任者',
        SCTAI_col3_val:'リ アイン',
        SCTAI_col4_title:'代表者または運営統括責任者（フリガナ）',
        SCTAI_col4_val:'リ アイン',
        SCTAI_col5_title:'連絡先/ホームページ',
        SCTAI_col5_val:'https://www.media-bank.co/',
        SCTAI_col6_title:'連絡先/e-mail(お客様窓⼝連絡先)',
        SCTAI_col6_val:'info@media-bank.info',
        SCTAI_col7_title:'連絡先/TEL (お客様窓⼝連絡先)',
        SCTAI_col7_val:'03-4400-0707',
        SCTAI_col8_title:'連絡先/ウェブサイト',
        SCTAI_col8_val:'https://gotoja.media-bank.info/',
        SCTAI_col9_title:'サイト表⽰名',
        SCTAI_col9_val:'⽇本深度遊',
        SCTAI_col10_title:'取扱商品 最⼩単価/円',
        SCTAI_col10_val:'1,000',
        SCTAI_col11_title:'取扱商品 最⼤単価/円',
        SCTAI_col11_val:'50,000 ',
        SCTAI_col12_title:'商品（サービスを含む）の引き渡し時期（⽇数）・発送⽅法',
        SCTAI_col12_val1:'【引き渡し時期】決済完了後即時',
        SCTAI_col12_val2:'ご購⼊後、ご登録のメールアドレスあてに、E メールにて送付いたします。',
        SCTAI_col12_val3:'⼀部の商品に関しては⼆次確認が必要でございます。詳細に関しては各商品の詳細ページの情報をご参照ください。',
        SCTAI_col13_title:'代⾦の⽀払時期および⽅法',
        SCTAI_col13_val1:' ⽀払時期:ご注⽂確定時',
        SCTAI_col13_val2:' 決済⼿段: ウェブサイトに表⽰されているアイコンにの⼿段はすべて利⽤可能。',
        SCTAI_col14_title:'',
        SCTAI_col14_val:'',
        SCTAI_col15_title:'商品（サービスを含む）代⾦以外に必要な費⽤/送料、消費税等',
        SCTAI_col15_val:'インターネットのご利⽤に関する通信費、決済⼿数料はお客様の負担となります。',
        SCTAI_col16_title:'返品の取扱条件/返品期限、返品時の送料負担または解約や退会条件/解約・退会の規約',
        SCTAI_col16_val:'商品の特性上、返品が不可能であるため、購⼊確定後のキャンセル・返⾦についてはお受けできません。',
        SCTAI_col17_title:'不良品の取扱条件',
        SCTAI_col17_val:'提供する引換券に⽋陥がある場合、改めて、弊社から⽋陥のない引換券を提供させて頂きます。 なお、返⾦には応じられません',
        time_out: 'リクエストタイムアウト',

        favorite: {
            share: 'シェア',
            collect: 'お気に入り',
            collected: '集めました'
        },
        merchant_closed: '営業終了'
    }
}
