export default {
    app: {
        hello: '你好，世界！',
        cn: '中文（简体)',
        hk: '中文（繁体)',
        en: 'English',
        jp: '日本語',
        language: '语言',
        signIn: '注册',
        personal: '个人主页',
        editProfile: '修改资料',
        orderList: '订单列表',
        changePwd: '修改密码',
        personalInformation:'个人资料',
        logOut: '退出',
        collectionList: '收藏列表',
        register: '注册',

        search: '搜索',

        introduction: '攻略',
        area: '地区',
        scenicSpot: '景点',
        shop: '店铺',
        ticket: '票券',

        whereTo: '去哪儿',

        enterBasicInfo: '基本信息填写',
        userName: '用户名',
        submit: '提交',

        all_area: '全部地区',
        other_area: '其他地区',
        recommendedScenicSpots: '推荐景点',

        Welcome_back: '欢迎回来',
        Email_address: '电子邮件地址',
        Password: '密码',
        Forgot_password: '忘记密码?',
        restPsw: '两次密码不一致',
        Sign_in: "登录",
        line_login: '使用Line登录',
        email_login: '使用Email登录',
        Not_a_member: "还不是会员?",
        medin_bank: "加入以解锁Media Bank Inc的最佳服务.",
        cookie_state: "通过继续，您同意我们的使用条款，并确认您已阅读我们的隐私和Cookie声明",
        forget_pwd: "忘记密码?",
        enter_your_pwd: "没问题。只需在下面输入您的电子邮件地址 - 我们将向您发送重置密码的代码。",
        login_code: "请输入验证码",
        code: "验证码",
        reset_pwd: "重置密码",
        new_pwd: "新密码",
        new_pwd_confirmation: "确认新密码",
        nike_name: "昵称",
        Create_Account: "创建账户",
        create_pwd: "创建密码",
        displayed_title: "仅显示Media Bank的预订.",
        All: "全部",
        Success: "成功",
        Failure: "失败",
        Processing: "处理中",
        Forest_zoo: "森林动物园",
        Business_Hours: "营业时间",
        Address: "地址",
        Notice: "注意事项",
        Peripheral_Selection: "周边选择",
        Explore_now: "立即探索",
        space_for_lese: "广告位出租",
        we_got: "我们提供您旅游日本所需的一切",
        Guideline: "攻略",
        Area: "区域",
        Scenic_spot: "景点",
        Shop: "商店",
        Ticket: "门票",
        topResult: "在所有类别中匹配 “{key}” 的前几个结果",
        topResult1: "在所有类别中匹配的前几个结果",
        collectResult: "在所有收藏中匹配 “{key}” 的前几个结果",
        collectResult1: "在所有收藏中匹配的前几个结果",
        All_Category: "在{value}中的前几个结果",
        book: "预订",
        Zoo_ticket: "动物园门票",
        Products: "符合要求的产品",
        Ticket_type: "门票类型",
        Children: "儿童",
        Date_selection: "日期选择",
        Purchase_quantity: "购买数量",
        Next: "下一步",
        pay_successful: "付款成功，请等待门票发放",
        ticket_issued_successful: '出票成功，请到您填写的邮箱中查收',
        Complete: "完成",
        My_Order: "我的订单",
        need_people: "需要填写2个人的信息",
        Traveler: "旅行者 ",
        Traveler_wo: "旅行者2",
        Contact_person: "联系人",
        Surname: "姓 ：",
        Given_name: "名 ：",
        Full_name: "姓名 ：",
        Phone: "电话+86 ：",
        Email: "电子邮件 ：",
        Please_surname: "请填写您的姓氏",
        Please_name: "请填写名字",
        Please_number: "请输入您的手机号码",
        Please_email: "请输入邮箱",
        Email_required: "电子邮件是必填项",
        Email_required_must: "电子邮件必须有效",
        UserName_required: '用户名是必填项',
        Address_required: '地址是必填项',
        enter_pwd: "输入一个用户密码",
        verification_code: "请输入验证码",
        Please_pwd: "请填写新密码",
        Please_newpwd: "请再次填写新密码",
        There_is_present: "目前没有此类型的订单",
        To_explore: "探索",
        money: "￥",
        bookNow: "预定",
        Traveler_Details: "旅行者详细信息",
        No_data:"暂无数据",
        Required:"需要填写",
        human:"人信息",
        Order_Status:"订单状态",
        Payment_Status:"付款状态",
        Type:"类型",
        Date:"日期",
        Quantity:"数量",
        reviews:"浏览量",
        recommend:"推荐",
        TrendingTravel:"旅行趋势",
        Places_Go:"要去的地方",
        Adventure:"探险之旅",
        about:'关于',
        Rating:"评分",
        DateList:"获取价格日历失败",
        calendarConfirmText: '确定',
        noInventory: '暂无库存',
        change_language_null_tips: '所选语言下没有数据，是否返回上一页面',
        data_null_tips: '暂时没有数据',

        noticeList: '消息列表',
        noticeDetail: '消息详情',
        noticeTitle: '消息标题：',
        sendTime: '发布时间：',
        noticeContent: '消息内容：',
        read: '已读',
        unRead: '未读',
        delete: '删除',
        cancel: '取消',
        allNotice: '共{total}条消息',
        allUnRead: '共{total}条未读消息',
        allRead: '共{total}条已读消息',


        productDescription: '产品描述',
        detailsIntro: '详情介绍',
        reserveNotice: '预定说明',
        productUseFlow: '【使用流程】',
        productLanguage: '【服务语言】',
        productAudienceAsk: '【产品人群要求】',
        travelDuration: '【行程时长】',
        groupSize: '【成团人数】',
        proAddress: '【地址】',
        businessHours: '【营业时间说明】',
        productCostInclude: '【费用包含】',
        productCostExclusive: '【费用不包含】',
        advanceDays: '【提前天数】',
        confirmWay: '【确认方式】',
        effectiveDate: '【有效日期】',
        refundInstructions: '【退款说明】',
        predetermineNotice: '【预定须知】',
        automaticConfirm: '自动确认',
        secondaryConfirm: '二次确认',
        English: '英文',
        Chinese: '中文',
        Japanese: '日文',
        Korean: '韩文',
        advanceDaysText: '{day}天前的{time}之前预定',
        orderValidText0: '选择的游玩日期当前有效',
        orderValidText1: '预定日期延后{day}天有效',
        orderValidText2: '预订日期截止到{date}有效',
        orderValidText3: '选定日期延后{day}天有效',
        orderValidText4: '选定日期截止到{date}有效',
        orderValidText5: '{startDate}到{endDate}内有效',
        orderValidText6: '指定使用日期的{startDay}前到指定日期的{endDay}后为止有效',


        About_MEDIA_BANK: '关于Media Bank',
        MEDIA_BANK_Sites: 'Media Bank 网站',
        aboutJTA:'关于 Japan Tourism Agency',
        aboutJED:'关于 \"Japan. Endless Discovery.\"logo',

        Privacy: '隐私条款',
        Contactus: '联系我们',
        termsOfUse: '使用条款',

        howToContact: '如果您遇到任何问题，请使用以下方法与我们联系：',
        tel: '电话',
        mail: '邮箱',
        translate: '中国语(简体)翻译',
        translateFrom: '中国语(简体)的翻译',
        originalContent: '显示原文',
        translateContent: '译文',


        exploreNow: '立即浏览',
        exploreAll: '浏览全部',

        thingsToDoIn: '做点什么在',
        thingsToDo: '做点什么',

        OrderDetails: '订单详情',
        orderExcption: '订单数据异常',

        total: '合计',
        Contact_information: '联系人信息',
        name: '姓名:',
        eMail: '邮箱:',
        close: '关闭',
        More_Detail: '更多',
        More: '更多',
        System_Order_ID: '系统订单号:',
        Payment_Order_ID: '付款订单号:',
        Ticket_Order_ID: '票务订单号:',
        System_Order_Status: '系统订单状态:',
        Ticket_PDF_Url: '票据PDF:',

        homeTitle1: '游览日本的方式',
        homeSubtitle1: '旅行者必去之地排行榜',
        homeTitle2: '店铺',
        homeSubtitle2: '预订这些体验，近距离了解日本.',
        homeTitle3: '推荐目的地',
        homeSubtitle3: '旅行者必去之地排行榜',
        homeTitle4: '特色门票',
        homeSubtitle4: '预订这些体验，近距离了解日本.',
        homeTitle5: '旅行故事',
        homeSubtitle5: '人气观光景点、美食信息、示范路线等的介绍.',

        priceVaries: '价格因集团规模而异',

        joininMEDIN_BANK: '使用您的Media Bank Inc帐户登录.',
        Already_a_member: '已经是会员?',

        secureCheckout: '安全结账',
        secureCheckoutText: '安全结账 - 只需要几分钟',
        PayNow: '立即支付',
        trade_tips:'请在两分钟之内完成支付，如果中途中断支付订单会被取消。',
        paymentProtection: '支付保护',
        non_refundable: '不可退款',
        minimum_order: '一张起购',

        liaisonDetail: '联络详情',
        customerName: '姓名',
        customerTelephone: '手机',
        customerEmail: '邮箱',
        customerWechat: '微信号',
        customerSex: '性别',
        man: '男',
        woman: '女',
        customerUseDate: '使用日期',
        customerCode: '邮编',
        customerAddress: '住址',
        customerCreditType: '证件类型',
        customerCreditNo: '证件号',
        customerReceiveDate: '领取日期',

        customerNameRequired: '请输入姓名',
        customerTelephoneRequired: '请输入手机号',
        customerWechatRequired: '请输入微信号',
        customerPassportRequired: '请输入护照',
        customerSexRequired: '请选择性别',
        customerUseDateRequired: '请选择使用日期',
        customerCodeRequired: '请输入邮编',
        customerAddressRequired: '请输入住址',
        customerCreditTypeRequired: '请选择证件类型',
        customerCreditNoRequired: '请输入证件号',
        customerReceiveDateRequired: '请选择领取日期',
        pleaseEnter: '请输入',
        reservation: '预定须知',

        identityCard: '身份证',
        studentIDCard: '学生证',
        militaryIDCard: '军官证',
        passport: '护照',
        residenceBooklet: '户口本',
        hongKongAndMacaoPass: '港澳通行证',
        taiwanSyndrome: '台胞证',

        aboutPlace: '关于',

        tickets:'张',
        tickets_s:'张',

        videoList:'火爆视频',
        videoList_sub:'分享大家喜欢的',
        allVideos: '全部视频',

        readMore: '更多',
        Check_availability: '价格日历',
        copyright:'Copyright © 2023 Media Bank Inc. All rights reserved.',
        plz_login: '请先登录系统',

        showAllAreaContent: '查看地区相关全部内容',

        emailEntryHint: '该邮箱将用于接收电子票凭证，请认真填写。如填写错误将无法获取电子票。',
        auth_loading:'授权中，请稍后...',
        auth_failed:'授权失败请稍后重试',
        back:'返回',
        pay_failed:'支付失败，请检查您输入的支付信息是否正确。',
        verygood:'非常棒',
        createdOrderFailed: '创建订单失败',

        website:'网址',
        position:'位置',
        go_order:'去点单',
        noOperation: '不营业',
        businessWeeks:{ week1:'星期一',week2:'星期二',week3:'星期三',week4:'星期四',week5:'星期五',week6:'星期六',week7:'星期日'},
        store_operation:'营业时间',
        store_introduction:'店铺介绍',
        store_Information:'店铺博客',
        store_periphery:'店铺周边',
        store_comment:'店铺评价',
        store_comment_addBtn:'评价',
        store_comment_placeholder: '请输入评价内容',
        store_comment_title: '用户评价',
        store_comment_null: '评价内容不能为空',
        store_comment_success: '评价成功',
        store_comment_del: '删除成功',
        confirm: '确认',
        store_comment_delTips: '是否确认删除该评价',
        copy_div:"已经复制到剪切板",
        copy_entry_div:"该店铺没有相关信息",

        fresh_news_title:"新鲜事",
        fresh_news_subtitle:"店铺热搜咨询",
        fresh_news_detail_title:"热搜咨询",

        new_nike_name:'新昵称',
        Please_new_nike_pwd: "请填写昵称",
        user_pic:"头像",
        user_info:'个人资料',

        image_require: '请上传图片文件',
        image_max_2: '图片大小不能超过 2MB!',

        plan_go: '计划去',
        user_plan_go: ' 人计划去哦!',
        plan_added: '已添加计划',
        come_plan: '暂无人计划去，快来加入吧！',
        more_plan: '更多 ...',
        add_plan_success: '已添加计划',
        cancel_plan_success: '已取消',

        pop_introduction_recommend: '热门攻略推荐',
        pop_area_recommend: '热门地区推荐',
        pop_scenicSpot_recommend: '热门景点推荐',
        pop_shop_recommend: '热门店铺推荐',
        guess_you_like: '猜你喜欢',
        everyone_plans_go: '大家计划去',
        introduction_list: '攻略列表',
        introduction_collect_list: '攻略收藏列表',
        area_list: '地区列表',
        area_collect_list: '地区收藏列表',
        scenicSpot_list: '景点列表',
        scenicSpot_collect_list: '景点收藏列表',
        shop_list: '店铺列表',
        shop_collect_list: '店铺收藏列表',
        ticket_list: '票券列表',
        ticket_collect_list: '票券收藏列表',

        merchant_enter: '商家入驻',
        discount_price: '折扣价',


        SCTAI_title:'特定商交易法信息',
        SCTAI_col1_title:'运营主体',
        SCTAI_col1_val:'株式会社 Media Bank',
        SCTAI_col2_title:'公司所在地',
        SCTAI_col2_val:'邮政编号 105-0001 东京都港区虎之门 1-10-5 KDX 虎之门 1 丁目大厦 11 楼',
        SCTAI_col3_title:'公司代表或运营总监',
        SCTAI_col3_val:'李 娅寅',
        SCTAI_col4_title:'代表者または運営統括責任者（フリガナ）',
        SCTAI_col4_val:'リ アイン',
        SCTAI_col5_title:'联系方式/公司主页',
        SCTAI_col5_val:'https://www.media-bank.co/',
        SCTAI_col6_title:'联系方式/e-mail(客服邮箱)',
        SCTAI_col6_val:'info@media-bank.info',
        SCTAI_col7_title:'联系方式/TEL (客服电话)',
        SCTAI_col7_val:'+81-3-4400-0707',
        SCTAI_col8_title:'联系方式/产品网站',
        SCTAI_col8_val:'https://gotoja.media-bank.info/',
        SCTAI_col9_title:'网站名称',
        SCTAI_col9_val:'日本深度游',
        SCTAI_col10_title:'商品最低单价',
        SCTAI_col10_val:'1000 日元',
        SCTAI_col11_title:'商品最大单价',
        SCTAI_col11_val:'50000 日元',
        SCTAI_col12_title:'商品（含服务）交付时间（天数）、发货方式',
        SCTAI_col12_val1:'【交货时间】完成支付后立即交付',
        SCTAI_col12_val2:'消费者购买网站商品后，相关电子券将通过电子邮件发送至您注册的电子邮件地址。',
        SCTAI_col12_val3:'部分商品需要二次确认，具体详情请参考每个商品的使用说明。',
        SCTAI_col13_title:'货款支付时间和方式',
        SCTAI_col13_val1:' 支付时间:确认下单后',
        SCTAI_col13_val2:' 支付方式: 网站上展示的所有图标所代表的支付方式都可以使用。',
        SCTAI_col14_title:'',
        SCTAI_col14_val:'',
        SCTAI_col15_title:'商品（包括服务）价款以外的必要费用/运费、消费税等',
        SCTAI_col15_val:'与使用互联网相关的通信费、支付手续费由顾客承担。',
        SCTAI_col16_title:'退货的处理条件/退货期限、退货时的运费承担或者解约和退会条件/解约·退会的规约',
        SCTAI_col16_val:'由于商品特性，本品不能退货，因此确定购买后无法进行退货或退款。',
        SCTAI_col17_title:'不良品的处理条件',
        SCTAI_col17_val:'如果本网站提供的兑换券有缺陷，请联系我司客服提供无缺陷的兑换券，请注意我司不接受退款。',
        time_out: '请求超时',

        favorite: {
            share: '分享',
            collect: '收藏',
            collected: '已收藏'
        },
        merchant_closed: '不营业'
    }
}
