import Vue from 'vue';
import App from './App';
import {router} from './router';
import {Toast, DropdownMenu, DropdownItem} from "vant";
import Vuei18n from "vue-i18n";
import cn from './lang/cn-ZH';
import en from './lang/en-US';
import hk from './lang/zh-HK';
import jp from './lang/ja-JP';
import 'video.js/dist/video-js.css';
import { priceFormat } from './utils/priceFormat';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.less';
import waterfall from 'vue-waterfall2'
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"


Vue.prototype.priceFormat = priceFormat;
Vue.config.productionTip = false


// 引入swiper的样式
import 'swiper/css/swiper.css';

Vue.prototype.$EventBus = new Vue();
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Vuei18n);
Vue.use(ElementUI, { size: 'small', showArrow: 'always' })
Vue.use(waterfall);
import locales from './lang/index.js';
const i18n = new Vuei18n({
  locale:window.localStorage.getItem('language') == null? 'en': window.localStorage.getItem('language'),
  messages:{
    'cn':{...cn},
    'en':{...en},
    'hk':{...hk},
    'jp':{...jp},

    },
    silentTranslationWarn: false,
});
Vue.prototype.$Local = locales;
let lang = window.localStorage.getItem('language') == null? 'en': window.localStorage.getItem('language');
if(window.localStorage.getItem('language') == null) window.localStorage.setItem('language', 'en');
locales(lang);

new Vue({
    router,
    el: '#app',
    i18n,
    render: h => h(App)
});

router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
})
