<template>
  <div style="width: 1136px; margin: 0 auto">

    <div class="header">
        <div @click="goback"><van-icon name="arrow-left" /></div>
        <div class="dictTitle">{{ $t('app.allVideos') }}</div>
        <div class="search">
          <el-input
              prefix-icon="el-icon-search"
              v-model="key"
              @clear="clearContent"
              clearable>
          </el-input>
        <el-button round @click="onSearch(key)">{{ $t('app.search') }}</el-button>

      </div>
    </div>
    <div class="dictItem">

      <div class="container-water-fall">
        <div v-if="videoList.length>0">
          <waterfall :col='col' :data="videoList">
            <template>
              <div class="cell-item" v-for="(item,index) in videoList" :key="index">
                <VideoPlay  class="video-player vjs-custom-skin" :videoCover="getVideoCover(item.url)" :videoUrl="item.url" :autoplay="false" :loop="false"
                            :muted="true" :id="'video-' + item.id" :idIndex="index" :width="272" :type="'video'" :videoId="item.id"/>
                <div class="videoName" :title="item.title">{{ item.title }}</div>
              </div>
            </template>
          </waterfall>
        </div>

        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import apis from "../../utils/apis";
import VideoPlay from "../../components/video/ViedoPlayer.vue";
import {isNotEmpty} from "@/utils/priceFormat";

export default {
  components: { VideoPlay },
  data() {
    return {
      videoList: [],
      col:4,
      page:'1',
      key: "",
    }
  },
  created() {
    this.getVideoList();
  },
  computed: {
  },
  methods: {
    onSearch(e) {
      this.key = e;
      this.getVideoList();
    },
    clearContent(){
      this.key = '';
      // this.getVideoList();
    },
    getVideoCover(videourl){
      let result = videourl.substring(0,videourl.lastIndexOf('.'));
      return result+"_cover.jpeg";
    },
    goback() {
      this.$router.go(-1);
    },
    getVideoList() {
      let param = {};
      if(isNotEmpty(this.key)){
        param = {
          title:this.key
        }
      }
      apis.indexVideo(param, data => {
        this.videoList = data.rows;
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  margin-top: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .dictTitle {
    text-align: left;
    font-size: 0.28rem;
    font-weight: 600;
    margin-bottom: .2rem;
  }
  .van-icon {
    cursor: pointer;
  }
}
.dictItem {
    width: 100%;
    text-align: left;
    .videoInfo {
        display: inline-block;
        width: 272px;
        margin-right: 16px;
        height: 270px;
        vertical-align: top;
        &:nth-child(4n) {
          margin-right: 0;
        }
    }
}
.videoName {
    margin-top: 0.04rem;
    font-size: 0.18rem;
    text-align: start;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    line-height: .25rem;
}


.container-water-fall {
  padding: 10px 3%;
  width: 100%;
  box-sizing: border-box;
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin:10% 0;
}

/deep/ .vue-waterfall-column .cell-item:nth-child .c_video{
  height: 296px;
}

/deep/ .vue-waterfall-column .cell-item .m-video{
  height: 296px;
  border-radius: 0.16rem;
}

///deep/ .vue-waterfall-column:nth-child(2) .cell-item:nth-child(3n) .c_video{
//  height: 300px;
//}
///deep/ .vue-waterfall-column:nth-child(2) .cell-item:nth-child(3n) .m-video{
//  height: 300px;
//  border-radius: 0.16rem;
//}
//
///deep/ .vue-waterfall-column:nth-child(3) .cell-item:nth-child(even) .c_video{
//  height: 300px;
//}
///deep/ .vue-waterfall-column:nth-child(3) .cell-item:nth-child(even) .m-video{
//  height: 300px;
//  border-radius: 0.16rem;
//}


/deep/ .vue-waterfall-column:nth-child(4) .cell-item:nth-child(odd) .c_video{
  height: 300px;
}

/deep/ .vue-waterfall-column:nth-child(4) .cell-item:nth-child(odd) .m-video{
  height: 300px;
  border-radius: 0.16rem;
}


/deep/ .vue-waterfall-column:nth-child(5) .cell-item:nth-child(4n) .c_video{
  height: 300px;
}

/deep/ .vue-waterfall-column:nth-child(5) .cell-item:nth-child(4n) .m-video{
  height: 300px;
  border-radius: 0.16rem;
}

/deep/ .search {
  width: 74%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 3%;
  .el-button {
    background: #004f32;
    color: #fff;
    padding: 16px 85px;
    border-radius: 35px;
    font-size: 18px;
  }
  .van-cell__value {
    background: #fff;
    padding-bottom: 0;
    font-size: .2rem;
  }
  .van-field__body {
    height: 100%;
  }
}

/deep/.el-input {
  border: .02rem solid #cccc;
  border-radius: .6rem;
  box-shadow: .02rem .02rem .08rem #e9e9e9;
  background-color: #fff;
  height: 45px;
  padding-right: 24px;
  padding-left: 15px;
  width: 43%;
  margin-right: 30px;
  font-size:0.2rem;
}

/deep/ .el-input--small .el-input__inner{
  height: 45px;
  line-height: 45px;
  border: 0;
}

/deep/ .el-icon svg {
  height: 40px;
  width: 40px;
  font-size: 30px;
}

/deep/ .el-input--small .el-input__icon {
  line-height: 45px;
  font-size: 19px;
  margin-left: 0.1rem;
}

/deep/ .el-input__suffix{
  margin-right: 0.1rem;
}
</style>
