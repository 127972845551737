<template>
  <div>
    <Header @goBack="goBack"></Header>
    <Loginlogo></Loginlogo>
    <div class="login">
      <h1 class="login_title">{{ $t("app.Welcome_back") }}</h1>
      <div class="login_input">
        <van-form @submit="onSubmit">
          <p class="label">{{ $t("app.Email_address") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
              :rules="[
                {
                  required: true,
                  message: $t('app.Email_required'),
                  trigger: 'onBlur',
                },
                {
                  validator: (value) => {
                    return /.+@.+\..+/.test(value);
                  },
                  message: $t('app.Email_required_must'),
                  trigger: 'onBlur',
                },
              ]"
              name="value"
              v-model="inputvalue"
              placeholder=""
            />
          </van-cell-group>
          <p class="label">{{ $t("app.Password") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
              type="password"
              :rules="[{ required: true, message: $t('app.enter_pwd') }]"
              name="value"
              v-model="password"
              placeholder=""
              focus
            />
          </van-cell-group>
          <span class="forgot_password" @click="forget">{{
            $t("app.Forgot_password")
          }}</span>
          <div style="margin-top: 0.5rem">
            <van-button round block type="info" native-type="submit"
              >{{ $t("app.Sign_in") }}
            </van-button>
          </div>
        </van-form>
      </div>
      <divider :type="0"></divider>
      <div class="connect">
        <p class="medin_ban" @click="toJoin">{{ $t("app.medin_bank") }}</p>
        <p class="cookie_state">{{ $t("app.cookie_state") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import apis from "../../utils/apis";
import Vue from "vue";
import { NavBar, Icon, Form, Field, Button, Divider, Toast } from "vant";
import Header from "../../components/Login/Header.vue";
import divider from "../../components/Login/divider.vue";
import Loginlogo from "../../components/Login/Loginlogo.vue";
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Toast);
let { login } = apis;
export default {
  components: {
    Header,
    divider,
    Loginlogo,
  },
  data() {
    return {
      value1:
        localStorage.getItem("language") == null
          ? "en"
          : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      inputvalue: "",
      password: "",
      telRules: [
        {
          required: true,
          message: "E-mail is required",
          trigger: "onBlur",
        },
        {
          // 自定义校验规则
          validator: (value) => {
            return /.+@.+\..+/.test(value);
          },
          message: "E-mail must be valid",
          trigger: "onBlur",
        },
      ],
    };
  },
  methods: {
    toJoin() {
      this.$router.push("/register");
    },
    onSubmit(values) {
      // console.log("submit", values);
      let params = {
        pwd: this.password || localStorage.getItem("email"),
        mail: this.inputvalue,
      };
      login({ ...params }, (data) => {
        // console.log(data, "datadatadatadatadata");
        if (data.code == 200) {
          Toast.success(data.msg);

          this.$router.replace({
            path: "/homePage",
          });
        }
      });
    },
    forget() {
      this.$router.push("/forget");
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped lang="less">
.login {
  padding: 0 0.5rem;
  .login_title {
    text-align: left;
    margin: 0 0 0.4rem 0;
  }
}
/deep/ .van-cell-group--inset {
  margin: 0;
}
/deep/ .van-cell {
  padding: 0;
}
/deep/.van-field__body {
  height: 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.16rem;
  padding-left: 0.24rem;
}
.label {
  display: block;
  margin: 20px 0 15px 0;
  text-align: left;
  font-size: 18px;
  font-weight: 540;
}
.forgot_password {
  display: block;
  margin: 20px 0 15px 0;
  text-align: left;
  font-size: 18px;
  font-weight: 540;
  width: 70%;
}
.van-button {
  background-color: #333;
  border: none;
}
.connect {
  .medin_ban {
    margin: 0;
    font-size: 0.32rem;
    font-weight: bold;
  }
  .cookie_state {
    font-size: 0.28rem;
    font-weight: bold;
  }
}
</style>
