<template>
  <div>
    <Header @goBack="goBack"></Header>
    <Loginlogo></Loginlogo>
    <div class="login">
      <h1 class="login_title">{{ $t("app.forget_pwd") }}</h1>
      <p class="login_describe">{{ $t("app.enter_your_pwd") }}</p>
      <div class="login_input">
        <van-form @submit="onSubmit">
          <p class="label">{{ $t("app.Email_address") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
              :rules="[
                {
                  required: true,
                  message: $t('app.Email_required'),
                  trigger: 'onBlur',
                },
                {
                  validator: (value) => {
                    return /.+@.+\..+/.test(value);
                  },
                  message: $t('app.Email_required_must'),
                  trigger: 'onBlur',
                },
              ]"
              name="value"
              v-model="inputvalue"
              placeholder=""
            />
          </van-cell-group>
          <!-- <p class="label">Email address</p>
          <van-field v-model="emails" type="email" name="email" placeholder="Email" @keyup="onPwd"/>
          <p class="required" v-if="showPwd">Please input a emails</p>-->
          <div style="margin: 0.64rem 0">
            <van-button round block type="info" native-type="submit"
              >Send Code
            </van-button>
          </div>
        </van-form>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import apis from "../../utils/apis";
import { NavBar, Icon, Form, Field, Button, Toast } from "vant";
import Header from "../../components/Login/Header.vue";
import Footer from "../../components/Login/Footer.vue";
import Loginlogo from "../../components/Login/Loginlogo.vue";

Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
let { verifyMail } = apis;
export default {
  components: {
    Header,
    Footer,
    Loginlogo,
  },
  data() {
    return {
      value1:
        localStorage.getItem("language") == null
          ? "en"
          : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      username: "",
      emails: "",
      showPwd: false,
      telRules: [
        {
          required: true,
          message: "E-mail is required",
          trigger: "onBlur",
        },
        {
          // 自定义校验规则
          validator: (value) => {
            return /.+@.+\..+/.test(value);
          },
          message: "E-mail must be valid",
          trigger: "onBlur",
        },
      ],
      inputvalue: "",
    };
  },

  created() {
    this.inputvalue =
      localStorage.getItem("email") == null
        ? ""
        : localStorage.getItem("email");
  },
  methods: {
    onSubmit() {
      let params = {
        mail: this.inputvalue,
      };
      verifyMail(params, (data) => {
        if (data.code == 200) {
          Toast.loading({
            message: data.msg,
            forbidClick: true,
            loadingType: "spinner",
          });
          this.$router.push("/code");
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped lang="less">
// .login_input {
//     padding: .4rem .64rem;
// }
.login {
  padding: 0 0.5rem;
  .login_title {
    text-align: left;
    font-size: 26px;
    margin: 0 0 0.7rem 0;
  }

  .login_describe {
    text-align: left;
    display: block;
    font-weight: 500;
    font-size: 0.28rem;
    margin: 0 0 0.6rem 0;
  }
}

/deep/ .van-cell-group--inset {
  margin: 0;
}

/deep/ .van-cell {
  padding: 0;
}

/deep/ .van-field__body {
  height: 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.1rem;
  padding-left: 0.24rem;
}

.label {
  text-align: left;
}
.van-button {
  background-color: #000000;
  border: none;
  height: 50px;
  font-size: 18px;
}
.connect p {
  margin: 0;
}
.required {
  text-align: left;
  color: red;
  font-weight: 200;
}
</style>
