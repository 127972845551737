<template>
    <div class="paySuccess">
        <div class="pay_box">
            <!-- <div class="pay_logo">
                <img src="../../assets/img/logo_black.png" alt="">
            </div> -->
            <div class="pay_title">
                {{getPayTitle()}}
            </div>
            <div class="pay_center">
                <img src="../../assets/img/pay.png" alt="">
            </div>
            <div class="pay_list">
                <div class="pay_list_flex" v-if="outTradeNo">
                    <div class="pay_lf">{{ $t('app.System_Order_ID') }}</div>
                    <div class="pay_rf">{{ outTradeNo }}</div>
                </div>
                <div class="pay_list_flex" v-if="qfpayId">
                    <div class="pay_lf">{{ $t('app.Payment_Order_ID') }}</div>
                    <div class="pay_rf">{{ qfpayId }}</div>
                </div>
                <div class="pay_list_flex" v-if="easygoId">
                    <div class="pay_lf">{{ $t('app.Ticket_Order_ID') }}</div>
                    <div class="pay_rf">{{ easygoId }}</div>
                </div>
                <div class="pay_list_flex" v-if="orderStatus">
                    <div class="pay_lf">{{ $t('app.Order_Status') }}</div>
                    <div class="pay_rf">{{ orderStatus }}</div>
                </div>
                <div class="pay_list_flex" style="border-bottom:0;">
                    <div class="pay_lf">{{ $t('app.Payment_Status') }}</div>
                    <div class="pay_rf">{{ this.result=='0000'?$t('app.Success'):$t('app.Failure') }}</div>
                </div>
            </div>
            <div class="pay_btn">
                <van-button type="default" @click="complete">{{
                        $t('app.Complete')
                    }} </van-button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { Button } from 'vant';
import apis from '../../utils/apis';
import { decrypt, encrypt } from "../../utils/jsencrypt";
let { OrderDetail,sendQfpayCallback } = apis;

Vue.use(Button);
export default {
    data(){
        return{
            qfpayId: '',
            easygoId: '',
            result:'',
            outTradeNo:'',
            orderStatus: '',
            value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
            option1: [
                { text: this.$t('app.en'), value: 'en' },
                { text: this.$t('app.jp'), value: 'jp' },
                { text: this.$t('app.cn'), value: 'cn' },
                { text: this.$t('app.hk'), value: 'hk' },
            ],
        }
    },

  created() {
    // console.log('路由信息>>>>', this.$route);
    // console.log('localStorage>>>language：',localStorage.getItem('language'));
    // console.log('localStorage>>>email：',localStorage.getItem('email'));
    // console.log('localStorage>>>avatar：',localStorage.getItem('avatar'));
    // console.log('localStorage>>>name：',localStorage.getItem('name'));
    // console.log('localStorage>>>userId：',localStorage.getItem('userId'));
    // console.log('localStorage>>>token：',localStorage.getItem('token'));
    this.outTradeNo = this.$route.query.outTradeNo;
    this.result = this.$route.query.result;
    if(this.result!=null && this.result != undefined && this.result !=""){
        if(this.outTradeNo!=null && this.outTradeNo != undefined && this.outTradeNo !=""){
            let sendQfpayCallbackRequest = {
                orderNum: this.outTradeNo,
                status: this.result,
                errorMsg: this.result=="0000" ? "交易成功":"交易失败",
                sign:"1C281E908AB30FBEA7C3FD0157257B50"
            }
            sendQfpayCallback(sendQfpayCallbackRequest,data => {
                // console.log("payresult sync called");
            })
        }
    }

    if(this.result=='0000'){
        this.orderStatus =  this.$t("app.Success");
    }else{
        this.orderStatus =  this.$t("app.Failure");
    }

    // if(this.$route.query.language == undefined || this.$route.query.language == null || this.$route.query.language == '') {
    //     this.$i18n.locale = navigator.language == 'ja' ? 'ja'
    //                         : (navigator.language.split('-')[1] == 'TW' || navigator.language.split('-')[1] == 'HK') ? 'hk'
    //                         : navigator.language.split('-')[1] == 'CN' ? 'cn' : 'en';
    // } else {
    //     let language = this.$route.query.language == 0 ? 'cn' : this.$route.query.language == 1 ? 'jp' : this.$route.query.language == 2 ? 'en' : 'hk';
    //     localStorage.setItem("language", language);
    //     this.$i18n.locale = language;
    // }
    // window.scrollTo(0, 0);
    const navLag = navigator.language == 'ja' ? 'jp'
                : (navigator.language.split('-')[1] == 'TW' || navigator.language.split('-')[1] == 'HK') ? 'hk'
                : navigator.language.split('-')[1] == 'CN' ? 'cn' : 'en';

    let language = localStorage.getItem('language');

    this.$i18n.locale = language == null ? navLag : language;


    if(this.outTradeNo!= undefined && this.outTradeNo != null && this.outTradeNo != '') {


        if(this.result == '0000') {
            this.getOrderDetrails(this.outTradeNo);

            let timer = setInterval(() => {
                if(this.orderStatus != this.$t("app.Success")) {
                    OrderDetail({mail: localStorage.getItem('email'), orderId: this.outTradeNo}, data => {
                        this.qfpayId = data.data.productInfo.qfpayId;
                        this.easygoId = data.data.productInfo.easygoId;
                        this.orderStatus = data.data.orderInfo.orderStatus == 1
                            ? this.$t("app.Success")
                            : data.data.orderInfo.orderStatus == 2
                            ? this.$t("app.Failure")
                            : data.data.orderInfo.orderStatus == 3
                            ? this.$t("app.Processing")
                            : this.$t("app.Processing");
                        if(data.data.orderInfo.orderStatus == 1) {
                            clearInterval(timer)
                        }
                    });
                }
            }, 5000)

            this.$once('hook:beforeDestroy', () => {
                clearInterval(timer);
            });
        }
      } else {
        this.$message.error({message: this.$t('app.createdOrderFailed'), customClass: 'messageIndex'});
      }
    },

  methods:{
    getPayTitle(){
        if(this.result != '0000') {
            return this.$t('app.pay_failed');
        } else if (this.orderStatus == this.$t("app.Success")) {
            return this.$t('app.ticket_issued_successful');
        } else {
            return this.$t('app.pay_successful');
        }
        // return this.result=='0000'?this.$t('app.pay_successful'):this.$t('app.pay_failed');
    },
    getOrderDetrails(orderId) {
        OrderDetail({mail: localStorage.getItem('email'), orderId: orderId}, data => {
            this.qfpayId = data.data.productInfo.qfpayId;
            this.easygoId = data.data.productInfo.easygoId;
            this.orderStatus = data.data.orderInfo.orderStatus == 1
                ? this.$t("app.Success")
                : data.data.orderInfo.orderStatus == 2
                ? this.$t("app.Failure")
                : data.data.orderInfo.orderStatus == 3
                ? this.$t("app.Processing")
                : this.$t("app.Processing");
        });
    },
    complete(){
      this.$router.replace('/homePage');
    }
  }
}
</script>

<style scoped lang="less">
/deep/.van-button--default{
    padding: 0 .4rem;
    color: #000000;
    font-size: .2rem;
    border:.02rem solid #c9c9c9;
    border-radius: .12rem;
}
.paySuccess {
    position: relative;
    width: 57%;
    margin: 0 auto;
    // background: url('../../assets/img/paySuccess.png') no-repeat;
    // background-size: contain;
    .pay_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 1.1rem 0 0;
        .pay_logo {
            height: 50px;
            width: 140px;

            img {
                width: 100%;
            }
        }
        .pay_title{
            font-size: .3rem;
            color: #000000;
            font-weight: bold;
            margin-bottom: .3rem;
        }
        .pay_center{
            width: 2rem;
            img{
                width: 50%;
            }
        }
        .pay_list{
            display: flex;
            flex-direction: column;
            width: 80%;
            .pay_list_flex{
                display: flex;
                width: 100%;
                padding: .2rem 0;
                justify-content: space-between;
                border-bottom: .04rem solid #fafafa;
                .pay_lf{
                    font-size: 14px;
                    color: #9fa0a0;
                }
                .pay_rf{
                    font-weight: 540;
                    font-size: 14px;
                    color: #000000;
                }
            }
        }
    }
}
</style>
