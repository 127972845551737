<template>
  <div class="orderDetails">
    <div class="ticketDiv">
      <div class="ticket1">
        <img :src="productInfo.img || ticketImg" />
        <div class="ticket_header">
          <div class="title1">
            <!-- {{ productInfo.productName }}  -->
            Zoo ticket
          </div>
          <div class="title2">
            <!-- {{ productInfo.priceTypeName }} / {{ productInfo.count}}  -->
            Children / 2 {{ $t("app.tickets") }}
          </div>
          <div class="title2">non-refundable</div>
          <div class="ticketDate">February 20, 2023</div>
          <div style="display: flex; justify-content: space-between; width: 616px;">
            <div>{{ $t("app.total") }}</div>
            <div style="font-weight: 600">JPY￥{{ productInfo.price }}</div>
        </div>
        </div>
      </div>
    </div>

    <div class="bodyDiv">
      <div class="title">{{ $t("app.Contact_information") }}</div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.name") }}</div>
        <div class="infoValue">{{ name }} ZHANG</div>
      </div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.eMail") }}</div>
        <div class="infoValue">{{ eMail }} 123445@qq.com</div>
      </div>
    </div>

    <div class="footer_btn">
      <el-button round>{{ $t('app.PayNow') }}</el-button>
    </div>
    <!-- <contact-us></contact-us> -->
  </div>
</template>

<script>
import apis from "../utils/apis";
// import ContactUs from "../../components/contactUs";
import { NavBar } from "vant";
import Vue from "vue";

Vue.use(NavBar);

let { login } = apis;
let { OrderDetail } = apis;

export default {
//   components: { ContactUs },
  props: ["orderId"],
  data() {
    return {
      title: this.$t("app.OrderDetails"),

      ticketImg:
        "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/27/79/12/10/caption.jpg?w=600&h=-1&s=1",

      name: "",
      eMail: "",
      System_Order_ID: "",
      Payment_Order_ID: "",
      Ticket_Order_ID: "",
      System_Order_Status: "",
      Ticket_PDF_Url: "",

      payUrl: "",
      ticketDetail: {},
      productInfo: {},
      orderInfo: {},
    };
  },

  created() {
    this.getOrderDetails();
  },

  methods: {
    getOrderDetails() {
      let params = {
        mail: localStorage.getItem("email"),
        orderId: this.orderId,
      };
      OrderDetail({ ...params }, (data) => {
        this.orderInfo = data.data.orderInfo;
        this.productInfo = data.data.productInfo;

        this.Ticket_Order_ID = this.productInfo.easygoId;
        this.name = this.productInfo.contectUserInfo ? this.productInfo.contectUserInfo.userName : "";
        this.eMail = this.productInfo.contectUserInfo ? this.productInfo.contectUserInfo.email : "";

        this.System_Order_ID = this.productInfo.orderId;
        this.Payment_Order_ID = this.productInfo.qfpayId;
        this.Ticket_Order_ID = this.productInfo.easygoId;
        this.System_Order_Status =
          this.orderInfo.orderStatus == 1
            ? this.$t("app.Success")
            : this.orderInfo.orderStatus == 2
            ? this.$t("app.Failure")
            : this.orderInfo.orderStatus == 3
            ? this.$t("app.Processing")
            : this.$t("app.Processing");
        this.Ticket_PDF_Url = this.productInfo.pdfurl;
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar__content {
  height: 1rem;
}

/deep/ .van-icon {
  color: black;
  font-size: 0.36rem;
}

/deep/ .van-nav-bar__title {
  font-weight: 700;
}

.orderDetails {
  background: #f8f8f8;
  font-size: 0.26rem;

  .ticketDiv {
    border-bottom: 1px solid #000000;
    background: white;
    padding: 30px;

    .ticket1 {
      display: flex;
      text-align: left;
      width: 100%;
      align-items: center;

      .ticket_header {
        display: flex;
        flex-direction: column;
        height: 222px;
      }

      img {
        border-radius: 0.08rem;
        height: 222px;
        width: 290px;
        margin-right: 0.2rem;
      }

      .title1 {
        color: black;
        font-weight: 500;
        font-size: 0.3rem;
        flex: 1;
      }

      .title2 {
        color: #9a9a9a;
        font-size: 0.26rem;
        flex: 1;
      }

      .ticketDate {
        font-weight: 600;
        text-align: left;
        flex: 1;
      }
    }

  }

  .allIdsDiv {
    padding: 30px;
    background: #ffffff;
    align-items: start;
    font-size: 0.2rem;
    text-align: left;
    color: #000000;

    .title {
      font-size: 0.28rem;
    }

    .infoDiv {
      margin-top: 0.2rem;
      .infoValue {
        margin-top: 0.1rem;
      }
    }
  }

  .payBtn {
    margin-top: 2rem;
    background: #f2b203;
    color: black;
    width: 6rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 0.2rem;
    margin-left: 0.75rem;
    margin-bottom: 0.56rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.46rem;
  }

  .bodyDiv {
    padding: 30px;
    background: #ffffff;
    align-items: start;
    font-size: 0.2rem;
    text-align: left;
    color: #000000;
    border-bottom: 1px solid #000000;

    .title {
      font-size: 0.28rem;
    }

    .infoDiv {
      display: flex;
      padding: 0.1rem 0;

      .infoType {
        width: 15%;
      }
    }
  }

  /deep/ .footer_btn {
    background: #fff;
    display: flex;
    justify-content: center;
    padding-top: .4rem;
    .el-button {
      background: #f2b203;
      color: #000000;
      width: 321px;
      height: 54px;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>
