<template>
  <div id="app">
    <nav-bar :ifBlack="false" :is-lang-show="showLang"></nav-bar>
    <!-- <router-view /> -->
    <div id="v-content" v-bind:style="{minHeight: Height+'px'}"><router-view /></div>
    <Footer :is-lang-show="showLang" v-if="showFooter"/>

    <!-- basic dialog -->
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="basicVisible"
    >
      <basic-dialog :preview="basicVisible" @close="basicVisible = false" />
    </el-dialog>
  </div>
</template>
<script>
import Footer from "../src/components/footer.vue";
import NavBar from "./components/basicComponents/navBar.vue";
import { hosWebtUrl, hostUrl } from "./utils/apis";
import BasicDialog from "./components/Login/BasicDialog.vue"
export default {
  components: { Footer, NavBar, BasicDialog },
  data() {
    return {
      showLang: true,
      Height: 0,
      basicVisible: false,
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      // this.showLang = "homePage".lastIndexOf(to.name) > -1;
      this.showLang = to.path != '/contacts' && to.path != '/orderPaynow' && this.$route.path != '/payResult';

      if(this.$route.path !== '/payPage' && this.$route.path !== '/payResult') {
        this.showFooter = true;
      } else {
        this.showFooter = false;
      }


      // if(!(this.$route.path.indexOf('login') > -1)) {
        localStorage.setItem("lastPath", hostUrl.slice(0, -1) + this.$route.fullPath);
      // }
    },
  },
  oreRouteEnter(to, from, next) {
    // console.log(from);
  },
  mounted(){
    //动态设置内容高度 让footer始终居底
    this.Height = document.documentElement.clientHeight - 270;
    //监听浏览器窗口变化　
    window.onresize = ()=> {this.Height = document.documentElement.clientHeight - 270};
  },
  beforeCreate() {

    // var userAgentInfo = navigator.userAgent;
    // var Agents = new Array(
    //   "Android",
    //   "iPhone",
    //   "SymbianOS",
    //   "Windows Phone",
    //   "iPad",
    //   "iPod"
    // );
    // var flag = true;
    // for (var v = 0; v < Agents.length; v++) {
    //   if (userAgentInfo.indexOf(Agents[v]) > 0) {
    //     flag = false;
    //     break;
    //   }
    // }
    // //判断移动端还是PC端。
    // if (flag) {
    //   // console.log("this is pc");
    // } else {
    //   // console.log("this is not pc");
    //   //TODO 打开移动端项目
    //   window.location.href = hosWebtUrl
    // }
  },
  created() {

    if(this.$route.path !== '/payPage' && this.$route.path !== '/payResult') {
        this.showFooter = true;
    } else {
      this.showFooter = false;
    }

    //判断移动端还是PC端。
    if (this.isPc()) {
      // console.log("this is pc");
    } else {
      // console.log("this is not pc");
      window.location.href = hosWebtUrl
    }
    // if(localStorage.getItem("isFirstLogin") != null && localStorage.getItem("isFirstLogin") != undefined && localStorage.getItem("isFirstLogin") === 'a') {
    //   this.basicVisible = true;
    // }

    this.showLang = this.$route.path != '/contacts' && this.$route.path != '/orderPaynow' && this.$route.path != '/payResult';
  },
  methods: {
    isPc() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
  },
};
</script>

<style>
body {
  margin: 0;
  overflow-x: hidden;
}
/* ::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
} */
html {
  font-size: 0.32rem;
}

#app {
  font-size: medium;
  font-family: "Trip Sans VF", "Trip Sans", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  width: 100%;
  font-size: 0.32rem;
  letter-spacing: 0rem;
}
/* ::-webkit-scrollbar {
  display: none;
} */
</style>
