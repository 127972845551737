<template>
  <div class="navBarBody" style="background: #fff; width: 100%">
    <div class="navBarBodyAll">
      <!-- <div class="propuBotton blackColor" @click="showPopup"></div> -->
      <img :src="logo" class="nav-logo" @click="toHomePage()" />
      <!-- <div class="selectInputIcon blackColor" @click="toSearch"></div> -->
      <el-button id="login" v-if="!uInfoFlg" round @click="signInVisible = true" style="font-size: 16px;font-weight: 700;">{{ $t('app.Sign_in') }}</el-button>
      <div v-else class="uInfo">
        <span >{{ text }}</span>
        <el-dropdown>
          <van-image
            id="userImg"
            width=".36rem"
            height=".36rem"
            :src="avatar"
            fit="cover"
            :round=true
            :error-icon="user_ic_def">
          </van-image>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="isLangShow">
              <!-- 二级菜单 -->
              <el-dropdown placement="right-start" :show-timeout="0" ref="subDropdown">
                <!-- 手动控制hover显示，解决鼠标移入三级菜单时二级菜单消失问题 -->
                <span id="language" @mouseenter="() => {$refs.subDropdown.show()}">{{ $t("app.language") }}</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item id="en" @click.native="changeLang('en')">{{ $t("app.en") }}</el-dropdown-item>
                  <el-dropdown-item id="jp" @click.native="changeLang('jp')">{{ $t("app.jp") }}</el-dropdown-item>
                  <el-dropdown-item id="cn" @click.native="changeLang('cn')">{{ $t("app.cn") }}</el-dropdown-item>
                  <el-dropdown-item id="hk" @click.native="changeLang('hk')">{{ $t("app.hk") }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>
            <el-dropdown-item @click.native="toPersonal()">{{ $t("app.personal") }}</el-dropdown-item>
            <!-- <el-dropdown-item @click.native="toDetail(0)">{{ $t("app.orderList") }}</el-dropdown-item>
            <el-dropdown-item @click.native="toNoticeList">{{ $t('app.noticeList') }}</el-dropdown-item>
            <el-dropdown-item @click.native="collectionList('a', '0')">{{ $t("app.collectionList") }}</el-dropdown-item> -->
            <!-- <el-dropdown-item @click.native="goSearchResult('b', '4')">{{ $t("app.introduction") }}</el-dropdown-item>
            <el-dropdown-item @click.native="goSearchResult('c', '1')">{{ $t("app.area") }}</el-dropdown-item>
            <el-dropdown-item @click.native="goSearchResult('d', '2')">{{ $t("app.scenicSpot") }}</el-dropdown-item>
            <el-dropdown-item @click.native="goSearchResult('e', '5')">{{ $t("app.shop") }}</el-dropdown-item>
            <el-dropdown-item @click.native="goSearchResult('f', '6')">{{ $t("app.ticket") }}</el-dropdown-item>
            <el-dropdown-item @click.native="goSearchResult('a', '0')">{{ $t("app.All") }}</el-dropdown-item> -->
            <el-dropdown-item @click.native="resetPswVisible = true">{{ $t("app.changePwd") }}</el-dropdown-item>
            <!-- <el-dropdown-item @click.native="resetHeadVisible = true">{{ $t("app.personalInformation") }}</el-dropdown-item> -->
            <el-dropdown-item id="logout" @click.native="logout">{{ $t("app.logOut") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>





    <van-popup
      v-model="show"
      closeable
      close-icon="cross"
      position="left"
      :style="{ height: '100%', width: '82vw' }"
    >
      <div class="propupWin">
        <div class="propupWinBody">
          <button class="signinButton" @click="toRegister" v-if="unfold">
            {{ $t("app.signIn") }}
          </button>
          {{ email }}
          <div class="body-top">
            <ul>
              <van-collapse v-model="activeNames" :border="false">
                <van-collapse-item :title="languageTitle" name="1">
                  <div class="langList">
                    <div
                      v-for="(item, index) in option1"
                      :key="index"
                      @click="changeLang(item.value)"
                    >
                      {{ item.text }}
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>

              <li @click="toDetail(0)">{{ $t("app.orderList") }}</li>
              <li @click="toDetail(1)" v-if="!unfold">
                {{ $t("app.changePwd") }}
              </li>
              <li @click="logout" v-if="!unfold">{{ $t("app.logOut") }}</li>
              <!-- ljn -->
              <li @click="goSearchResult('b', '4')">
                {{ $t("app.introduction") }}
              </li>
              <li @click="goSearchResult('c', '1')">{{ $t("app.Area") }}</li>
              <li @click="goSearchResult('d', '2')">
                {{ $t("app.Scenic_spot") }}
              </li>
              <li @click="goSearchResult('e', '5')">{{ $t("app.Shop") }}</li>
              <li @click="goSearchResult('f', '6')">{{ $t("app.Ticket") }}</li>
              <li @click="goSearchResult('a', '0')">{{ $t("app.All") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </van-popup>
    <van-dialog
      v-model="show1"
      :title="$t('app.changePwd')"
      show-cancel-button
      @confirm="goCode"
    >
    </van-dialog>
    <van-dialog
      v-model="show2"
      :title="$t('app.logOut')"
      show-cancel-button
      @cancel="show2Cancel"
      @confirm="goLogout"
    >
    </van-dialog>

    <!-- Sign In -->
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="signInVisible"
    >
      <sing-in-dialog :preview="signInVisible" @loginSuccess="loginSuccessCallback"/>
    </el-dialog>

    <!-- Reset Password -->
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="resetPswVisible"
    >
      <reset-psw @close="resetPswVisible = false"/>
    </el-dialog>

    <el-dialog
        center
        lock-scroll
        append-to-body
        width="500px"
        :before-close="handleClose"
        :visible.sync="resetHeadVisible"
    >
      <reset-head ref="personalInformation" @loginSuccess="loginSuccessCallback" @close="resetHeadVisible = false"/>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup, Collapse, CollapseItem, List, Dialog } from "vant";

import logo_black from "../../assets/logo_black.png";
import SingInDialog from "@/components/Login/SingInDialog.vue";
import ResetPsw from "../Login/ResetPsw.vue"
import user_ic_def from "../../assets/user_ic.png";
import ResetHead from "@/components/Login/ResetHead.vue";
import apis from "../../utils/apis";

Vue.use(Popup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(List);
Vue.use(Dialog);
let { getUserInfo, getDataCount } = apis;

export default {
  components: {ResetHead, SingInDialog, ResetPsw },
  name: "navBar",
  props: ["ifBlack", "isLangShow"],
  data() {
    return {
      show: false,
      show1: false,
      show2: false,
      isBlack: this.ifBlack,
      // logo: this.ifBlack ? logo_black : logo_white,
      logo: logo_black,

      languageTitle: this.$t("app.language"),

      activeNames: ["0"],
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      unfold: true,
      email: "",
      avatar:"",
      name:"",
      signInVisible: false,
      resetPswVisible: false,
      resetHeadVisible:false,
      uInfoFlg: false,
      userName: '',
      user_ic_def:user_ic_def,
    };
  },
  computed: {
    text: {
      get() {
        return this.userName;
      },
      set(value) {
        this.userName = value;
      },
    },
  },
  created() {
    if (localStorage.getItem('userId')) {
      let params = {
        userId: localStorage.getItem('userId'),
      };
      getUserInfo({ ...params }, (response) => {
        localStorage.setItem("avatar", response.data.avatar);
        localStorage.setItem("name", response.data.userName);
        this.token = localStorage.getItem("token");
        this.email = localStorage.getItem("email");
        this.avatar = localStorage.getItem("avatar");
        this.name = localStorage.getItem("name");
        if (this.token) {
          this.unfold = false;
          this.uInfoFlg = true;
        }
        this.userName = this.name ? this.name:this.email;
      });
    } else {
      this.token = localStorage.getItem("token");
      this.email = localStorage.getItem("email");
      this.avatar = localStorage.getItem("avatar");
      this.name = localStorage.getItem("name");
      if (this.token) {
        this.unfold = false;
        this.uInfoFlg = true;
      }
      this.userName = this.name ? this.name:this.email;
    }
  },

  mounted() {

    this.$EventBus.$on('closeSingIn', () => {
      this.uInfoFlg = true;
      this.email = localStorage.getItem("email");
      this.name = localStorage.getItem("name");
      this.avatar = localStorage.getItem("avatar");
      this.userName = this.name ? this.name : this.email;
    })

    this.$forceUpdate();
    let that = this;
    window.onscroll = function () {
      if (!that.ifBlack) {
        if (window.scrollY < 20) {
          that.isBlack = false;
          // that.logo = logo_white;
        }
        if (window.scrollY > 20) {
          that.isBlack = true;
          // that.logo = logo_black;
        }
      }
    };
  },
  methods: {
    getDisplayName(){
      return this.name ? this.name:this.email
    },
    loginSuccessCallback() {
      this.signInVisible = false;
      this.uInfoFlg = true;
      this.email = localStorage.getItem("email");
      this.avatar = localStorage.getItem("avatar");
      this.name = localStorage.getItem("name");
      this.userName = this.name ? this.name:this.email;
    },
    onSure() {},
    toRegister() {
      this.$router.push({
        path:'/login',
        query:{
          path:param
        }
      });
    },
    toSearch() {
      this.$router.push("/search");
    },

    toDetail(type) {
      if(!localStorage.getItem('token')) {
        this.signInVisible = true;
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        this.$EventBus.$emit('closeSingIn');
        return;
      }
      switch (type) {
        case 0:
          this.$router.push("/order");

          break;

        case 1:
          this.show1 = true;
          // this.$router.push('/code')
          break;
      }
    },
    toPersonal(){
      this.$router.push("/personal");
    },
    toNoticeList() {
      this.$router.push("/noticeList");
    },

    goCode() {
      this.$router.push("/forget");
    },

    showPopup() {
      this.$forceUpdate();
      this.show = true;
    },

    toHomePage() {
      if (this.$route.path == "/homePage") {
        return;
      }
      this.$router.push({
        path: "homePage",
      });
    },

    changeLang(value) {
      let params = {
        type: -1,
        id: this.$route.query.id,
        language: 0,
      };
      switch (this.$route.path) {
        case '/detailsPage': params.type = 1 //店铺
          break;
        case '/essayDetail': params.type = 2 //文章
          break;
        case '/blockDetail': params.type = 3 //新鲜事
          break;
        default: params.type = -1
          break;
      };
      if (params.type != -1) {
        switch (value) {
          case 'cn': params.language = 0 
            break;
          case 'jp': params.language = 1 
            break;
          case 'en': params.language = 2 
            break;
          case 'hk': params.language = 3 
            break;
          default: params.language = 2
            break;
        }
        getDataCount({ ...params }, (response) => {
          if (response && response.data > 0) {
            localStorage.setItem("language", value);
            this.$i18n.locale = value;
            this.$forceUpdate();
            this.show = false;
            window.location.reload();
            window.scrollTo(0, 0);
          } else {
            this.$dialog.confirm({
              title: "",
              message: this.$t('app.change_language_null_tips'),
              confirmButtonText: this.$t('app.confirm'),
              cancelButtonText: this.$t('app.cancel')
            }).then(() => {
              localStorage.setItem("language", value);
              const route = localStorage.getItem("beforeRouter");
              this.$router.push(route);
              window.location.reload();
            }).catch(() => {});
          }
        })
      } else {
        localStorage.setItem("language", value);
        this.$i18n.locale = value;
        this.$forceUpdate();
        this.show = false;
        window.location.reload();
        window.scrollTo(0, 0);
      }
    },
    logout() {
      this.show2 = true;
    },
    show2Cancel() {
      this.show2 = false;
    },
    goLogout() {
      // this.$router.push("/login");
      // window.localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("name");
      localStorage.removeItem("avatar");
      localStorage.removeItem("userId");
      this.uInfoFlg = false;
      window.location.reload()
    },
    goSearchResult(e, index) {
      this.$router.push({
        path: "/result",
        query: {
          name: e,
          index: index,
        },
      });
    },
    handleClose() {
      this.$refs.personalInformation.reset();
      this.resetHeadVisible = false;
    },

    collectionList(e, index) {
      this.$router.push({
        path: "/result",
        query: {
          name: e,
          index: index,
          collectFlag:1
        },
      });
    },
  },
  beforeDestroy() {
    this.$EventBus.$off('closeSingIn');
  },
};
</script>

<style scoped lang="less">
.navBarBody {
  border-bottom: 1px solid #e0e0e0;
  ::v-deep .van-icon-cross:before {
    color: #000000;
  }

  ::v-deep .el-button.is-round {
    background: #000;
    span {
      color: #fff;
    }
  }

  z-index: 1000;

  &.blackColor {
    background-color: #ffffff !important;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
  }

  position: fixed;
  top: 0;
  left: 0;

  .navBarBodyAll {
    padding: 0.25rem 0;
    box-sizing: border-box;
    // width: 100vw;
    width: 1136px;
    height: 80px;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .nav-logo {
      // position: absolute;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%, -50%);
      width: 250px;
      height: 80px;
      object-fit: cover;
      cursor: grab;
    }

    .uInfo {
      height: 80px;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        margin-right: 15px;
      }
    }

    .propuBotton {
      width: 0.35rem;
      height: 0.035rem;
      padding: 0.06rem 0;
      background-color: white;
      border-top: 0.04rem solid white;
      border-bottom: 0.04rem solid white;
      background-clip: content-box;

      &.blackColor {
        background-color: black;
        border-top: 0.04rem solid black;
        border-bottom: 0.04rem solid black;
      }
    }

    .selectInputIcon {
      position: relative;
      margin-left: 26px;
      width: 28px;

      &::before {
        content: "";
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        display: block;
      }

      &::after {
        content: "";
        width: 5px;
        height: 1px;
        background: #ffffff;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        right: 4px;
        bottom: 0px;
      }

      &.blackColor::before {
        border: 1px solid #000;
      }

      &.blackColor::after {
        background: #000;
      }
    }
  }
}

.propupWin {
  overflow: scroll;
  .propupWinBody {
    padding: 1.1rem 0.3rem 0.5rem;
    .signinButton {
      width: 80%;
      height: 40px;
      background: black;
      color: #ffffff;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      border: none;
      font-weight: 500;
    }
    .body-top {
      font-size: 0.4rem;
      font-weight: 600;
      margin: 0.7rem 0 0.2rem 0.2rem;
      ul {
        li {
          margin: 0.36rem 0;
          text-align: left;
        }
      }
      ::v-deep .van-collapse {
        .van-cell {
          padding: 0;
          border: none;
          &::after {
            border-bottom: none;
          }
        }
        .van-cell__title {
          text-align: left;
          font-size: 0.4rem;
          font-weight: 600;
          margin-left: 0;
        }
        .van-collapse-item__wrapper {
          .van-cell__title {
            text-align: left;
            font-size: 0.3rem;
            padding: 0.1rem 0;
          }
        }

        .van-collapse-item__content {
          text-align: left;
        }

        .van-cell__right-icon {
          color: black;
        }
      }
    }

    //.body-body{
    //  border-top: 0.00001rem solid #ccc;
    //  ul{
    //    li{
    //      text-align: left;
    //      font-size: 0.3rem;
    //      font-weight: 500;
    //      margin: 0.3rem 0;
    //
    //    }
    //  }
    //}
  }
}
.item {
  display: flex;
  align-self: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin: 0 10px;
}
.langList {
  display: flex;
  flex-direction: column;
  color: #000000;
}
.langList > div {
  height: 0.7rem;
  line-height: 0.7rem;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 32px;
}
/deep/ .el-dialog--center{
  border-radius: 8px;
}
</style>
