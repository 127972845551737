<template>
  <div class="scenicDiv">

    <div class="flow-row space-between">
      <div class="distTitle1">{{ $t("app.homeTitle3") }}</div>
      <div class="fs-018" @click="searchMore">{{ $t("app.More") }}</div>
    </div>

    <div class="distTitle2">{{ $t("app.homeSubtitle3") }}</div>

    <!-- <div class="distDiv">
      <div
        class="distItem"
        v-for="(item, index) in scenicList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="imgDiv">
          <img :src="item.img" class="img" alt="" />
          <div class="title">
            <div class="sub_title">{{ item.name }}</div>
            <div class="desc">{{ item.desc | filtersText }}</div>
          </div>
        </div>
      </div>
    </div> -->

    <el-carousel :autoplay="false">
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <div class="distDiv">
          <div
            class="distItem"
            v-for="(item1, index1) in item"
            :key="index1"
            @click="toDetail(item1)"
          >
            <div class="imgDiv">
              <img :src="item1.img" class="img" alt="" />
              <div class="title">
                <div class="sub_title">{{ item1.name }}</div>
                <div class="desc">{{ item1.desc | filtersText }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "scenicPart",
  props: ["scenicList"],
  data() {
    return {
      dataList: [],
    };
  },
  watch: {
    scenicList: {
      handler(dataList) {
        const list = []
        if(dataList.length) {
          dataList.forEach((el, i) => {
            const index = Math.floor(i / 4);
            if(!list[index]) {
              list[index] = [];
            }
            list[index].push(el);
          });
        }
        this.dataList = list;
      }
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "essayDetail",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    searchMore(){
      const _this = this
      this.$router.push({
          path:'/result',
          query:{
              searchKey:"",
              name:'d',
              index:'2'
          }
      })
    }
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
};
</script>

<style scoped lang="less">
.scenicDiv {
  margin-top: 0.4rem;
  text-align: start;
  width: 1136px;
  margin: 0 auto;

  .distTitle1 {
    color: black;
    font-size: 0.28rem;
    font-weight: 600;
  }

  .distTitle2 {
    color: #707070;
    font-size: .16rem;
    padding: 12px 0;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .distItem:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin-right: 16px;
    width: 272px;
    height: 270px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: .04rem;

    // &:first-child {
    //   margin-left: 0.4rem;
    // }

    // &:last-child {
    //   margin-right: 0.2rem;
    // }

    .imgDiv {
      position: relative;
      width: 272px;
      display: flex;
      flex-direction: column;
      cursor: grab;
      .title {
        position: absolute;
        bottom: 0;
        // background: rgba(0, 0, 0, 0.5);
        background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.6) 75%,rgba(0, 0, 0,.0) 100%);
        color: white;
        text-align: start;
        padding: .08rem .18rem;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        min-width: 0;
        border-bottom-left-radius: .16rem;
        border-bottom-right-radius: .16rem;

        .desc {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          min-height: 0.34rem;
          font-size: .15rem;
          margin-top: 0.1rem;
        }
        .sub_title {
          font-size: .2rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

        }
      }

      .img {
        display: flex;
        width: 272px;
        height: 270px;
        object-fit: cover;
        border-radius: .16rem;
      }
    }
  }
}
/deep/ .el-carousel__indicators--horizontal {
  display: none;
}
</style>
