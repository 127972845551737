var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{on:{"goBack":_vm.goBack}}),_c('Loginlogo'),_c('div',{staticClass:"login"},[_c('h1',{staticClass:"login_title"},[_vm._v(_vm._s(_vm.$t("app.forget_pwd")))]),_c('p',{staticClass:"login_describe"},[_vm._v(_vm._s(_vm.$t("app.enter_your_pwd")))]),_c('div',{staticClass:"login_input"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("app.Email_address")))]),_c('van-cell-group',{staticStyle:{"margin-bottom":"2%"},attrs:{"inset":""}},[_c('van-field',{attrs:{"rules":[
              {
                required: true,
                message: _vm.$t('app.Email_required'),
                trigger: 'onBlur',
              },
              {
                validator: (value) => {
                  return /.+@.+\..+/.test(value);
                },
                message: _vm.$t('app.Email_required_must'),
                trigger: 'onBlur',
              },
            ],"name":"value","placeholder":""},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}})],1),_c('div',{staticStyle:{"margin":"0.64rem 0"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("Send Code ")])],1)],1)],1),_c('Footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }