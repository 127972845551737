var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Loginlogo'),_c('div',{staticClass:"login"},[_c('h1',{staticClass:"login_title"},[_vm._v(_vm._s(_vm.$t("app.forget_pwd")))]),_c('p',{staticClass:"login_describe"},[_vm._v(_vm._s(_vm.$t("app.enter_your_pwd")))]),_c('div',{staticClass:"login_input"},[_c('van-form',{ref:"vForm",on:{"submit":_vm.onSubmit}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("app.Email_address")))]),_c('van-cell-group',{attrs:{"inset":""}},[_c('van-field',{attrs:{"rules":[
              {
                required: true,
                message: _vm.$t('app.Email_required'),
                trigger: 'onBlur',
              },
              {
                validator: (value) => {
                  return /.+@.+\..+/.test(value);
                },
                message: _vm.$t('app.Email_required_must'),
                trigger: 'onBlur',
              },
            ],"name":"value","placeholder":""},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}})],1),_c('div',{staticStyle:{"margin":"36px 0"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("Send Code ")])],1)],1)],1),_c('Footer')],1),_c('el-dialog',{attrs:{"center":"","lock-scroll":"","append-to-body":"","width":"500px","visible":_vm.verifyVisible},on:{"update:visible":function($event){_vm.verifyVisible=$event},"close":_vm.resetFrom}},[_c('verify-dialog',{attrs:{"preview":_vm.verifyVisible},on:{"close":_vm.close}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }