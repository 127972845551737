<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1" v-if="tab === 4">{{ collectFlag == 1 ? $t("app.introduction_collect_list") : $t("app.introduction_list") }}</div>
      <div class="distTitle1" v-else-if="tab === 1">{{ collectFlag == 1 ? $t("app.area_collect_list") : $t("app.area_list") }}</div>
      <div class="distTitle1" v-else-if="tab === 2">{{ collectFlag == 1 ? $t("app.scenicSpot_collect_list") : $t("app.scenicSpot_list") }}</div>
      <div class="distTitle1" v-else-if="tab === 5">{{ collectFlag == 1 ? $t("app.shop_collect_list") : $t("app.shop_list") }}</div>
      <div class="distTitle1" v-else-if="tab === 6">{{ collectFlag == 1 ? $t("app.ticket_collect_list") : $t("app.ticket_list") }}</div>
      <div class="fs-018" v-if="showFlg && dataList.length > 3 && collectFlag !='1'" @click="checkMore">{{ $t("app.More") }}</div>
    </div>
    <van-list @load="onLoad" v-if="showFlg && collectFlag !='1'">
      <div class="list_nav" v-for="(item, index) in dataList" :key="index" v-show="index<3">
        <div class="head">
          <div class="portrait" @click="goDetails(tab, item)">
            <img :src="item?.img ? item.img.split(',')[0] : NoPicture" alt="" srcset="" />
            <span v-if="item.type == '4'" class="icon"><img src="../../../assets/img/gonglue2.png" alt="" srcset="" /></span>
            <span v-else-if="item.type == '1'" class="icon"><img src="../../../assets/img/area2.png" alt="" srcset="" /></span>
            <span v-else-if="item.type == '2'" class="icon"><img src="../../../assets/img/jingdian2.png" alt="" srcset="" /></span>
            <span v-else-if="item.type == '5'" class="icon"><img src="../../../assets/img/shop2.png" alt="" srcset="" /></span>
            <span v-else-if="item.type == '6'" class="icon"><img src="../../../assets/img/piao2.png" alt="" srcset="" /></span>
          </div>
          <div class="list_describe">
            <h3 @click="goDetails(tab, item)">{{ item.name }}</h3>
            <div class="text-title" @click="goDetails(tab, item)">
              <div v-html="$options.filters.filtersText(item.desc)"></div>
            </div>
            <div class="viewa-row">
              <p class="list_view">
                {{ item.reviews || 0 }} {{ $t("app.reviews") }}
              </p>
              <collect :favorite="item.favorite" class="collect_right" @getTabList="getTabList"></collect>
            </div>

            <div @click="goDetails(tab, item)" v-if="item.extra && item.type == 6">
              <p class="list_form" v-if="Number(item.extra.price) == 0">
                {{ $t('app.noInventory')  }}
              </p>
              <p class="list_form" v-else>
                <span class="discount_price">
                  <span style="font-size: 14px">
                    {{ $t("app.discount_price") }}
                  </span>
                  {{ $t("app.money") }}{{ priceFormat(item.extra.price) }}
                </span>
                <span class="original_price">{{ $t("app.money") }}{{ priceFormat(item.extra.price * 1.3) }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <van-list @load="onLoad" v-if="!showFlg || collectFlag =='1'">
      <div class="list_nav" v-for="(item, index) in dataList" :key="index">
        <div class="head">
          <div class="portrait">
            <img :src="item?.img ? item.img.split(',')[0] : NoPicture" alt="" srcset="" />
            <span class="icon"><img src="../../../assets/img/piao2.png" alt="" srcset="" /></span>
          </div>
          <div class="list_describe">
            <h3 @click="goDetails(tab, item)">{{ item.name }}</h3>
            <div class="text-title" @click="goDetails(tab, item)">
              <div v-html="$options.filters.filtersText(item.desc)"></div>
            </div>
            <div class="viewa-row">
              <p class="list_view">
                {{ item.reviews }} {{ $t("app.reviews") }}
              </p>
              <collect :favorite="item.favorite" class="collect_right" @getTabList="getTabList"></collect>
            </div>
            <div @click="goDetails(tab, item)" v-if="item.extra && item.type == 6">
              <p class="list_form" v-if="Number(item.extra.price) == 0">
                {{ $t('app.noInventory')  }}
              </p>
              <p class="list_form" v-else>
                <span class="discount_price">
                  <span style="font-size: 14px">
                    {{ $t("app.discount_price") }}
                  </span>
                  {{ $t("app.money") }}{{ priceFormat(item.extra.price) }}
                </span>
                <span class="original_price">{{ $t("app.money") }}{{ priceFormat(item.extra.price * 1.3) }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../../assets/img/down.png" /></div>
    </van-list>
  </div>
</template>

<script>
import NoPicture from "../../../assets/img/no_picture.png";
import Collect from "@/components/collect";

export default {
  components: {
    Collect
  },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    showFlg: {
      type: Boolean,
      default: false,
    },
    collectFlag: {
      type: String,
    },
    dataList: {
      type: Array,
    },
    showMoreFlg: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      NoPicture: NoPicture,
      viewa: '',
    }
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        val = val.replaceAll("</p>", "/p/");
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          ).replaceAll("/p/", "<br>");
      }
    },
  },
  methods: {
    randomNumber() {
      let shareArr = [
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ];
      var str = ""; //重新调用赋值给空
      for (var i = 0; i < shareArr.length; i++) {
        var rond = Math.round(Math.random());
        str += shareArr[rond];
      }
      this.viewa = str;
    },
    getTabList(){
      this.$emit('getTabList',1);
    },
    goDetails(tab, item) {
      if(tab < 5) {
        this.$router.push({
          path: "/essayDetail",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      } else if (tab == 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      }
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    },
    checkMore() {
      this.$emit('showChange', '1')
    },
    loadMore() {
      this.$emit('loadMore')
    }
  }
}
</script>

<style scoped lang="less">
@import url("../../../assets/css/searchResult.less");
.districtDiv {
  text-align: start;
  width: 1136px;
  margin: 0 auto;

  .flow-row {
    margin-top: 0;
  }

  .distTitle1 {
    color: black;
    font-size: .20rem;
    font-weight: 600;
    margin: 18px 0;
  }

  .fs-018{
    font-size: 0.18rem;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 18px;
  }
}
.showMore {
  margin-bottom: 30px;
}
.list_view {
  font-size: .14rem;
}

.discount_price {
  color: #f1b231;
}

.original_price {
  color: #989898;
  font-size: 14px;
  text-decoration: line-through;
  margin-left: 12px;
}
</style>
