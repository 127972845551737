<template>
  <div>
    <van-tabs
      swipeable
      v-model="active"
      title-active-color="#000000"
      @click="onClick"
    >
      <!-- <van-tab :title="$t('app.All')">
        <div v-if="total > 0">
            <van-cell
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="order_nav">
                <div class="order_img">
                  <img :src="item.img" alt="" srcset="" />
                </div>
                <div class="order_cbk">
                  <div class="order_title">{{ item.proName }}</div>
                  <div class="order_status">
                    <span>
                      <van-icon name="checked" v-if="item.orderStatus == 1" />
                      <van-icon name="clear" v-if="item.orderStatus == 2" />
                      <van-icon name="replay" v-if="item.orderStatus == 3" />
                    </span>
                    <span v-if="item.orderStatus == 1">{{
                      $t("app.Success")
                    }}</span>
                    <span v-if="item.orderStatus == 2">{{
                      $t("app.Failure")
                    }}</span>
                    <span v-if="item.orderStatus == 3">{{
                      $t("app.Processing")
                    }}</span>
                  </div>
                  <div class="order_date">
                    <span><van-icon name="underway-o" size="16" /></span>
                    <span>{{ item.createTime }}</span>
                  </div>
                  <div class="order_date">
                    <span>{{ item.buyQuantity }}{{ $t("app.tickets") }}</span>
                  </div>
                  <div class="bo_text">  
                    <div class="order_detail" @click="OrderDetails(item.orderId)">{{ $t('app.OrderDetails') }}</div>
                    <div class="order_price">
                      {{ $t("app.money") }}{{ item.amount }}
                    </div>
                  </div>
                </div>
              </div>
            </van-cell>
            <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../assets/img/down.png" /></div>
        </div>
        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div>
      </van-tab> -->
      <van-tab :title="$t('app.Success')">
        <!--Empty pages-->
        <div v-if="total > 0">
            <van-cell
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="order_nav">
                <div class="order_img">
                  <img :src="item?.img ? item.img : NoPicture" alt="" srcset="" />
                </div>
                <div class="order_cbk">
                  <div class="order_title">{{ item.proName }}</div>
                  <div class="order_status">
                    <span><van-icon name="certificate" size="16" /></span
                    ><span>{{ $t("app.Success") }}</span>
                    <span>{{ item.orderId }}</span>
                  </div>
                  <div class="order_date">
                    <span><van-icon name="underway-o" size="16" /></span>
                    <span>{{ item.createTime }}</span>
                    <!-- <span>|</span>
                    <span>{{ item.buyQuantity }}{{ $t("app.tickets") }}</span> -->
                  </div>
                  <div class="order_date">
                    <span>{{ item.buyQuantity }}{{ getTicket(item.buyQuantity) }}</span>
                  </div>
                  <div class="bo_text">
                    <div class="order_detail" @click="OrderDetails(item.orderId)">{{ $t('app.OrderDetails') }}</div>
                    <div v-if="Number(item.amount) == 0" class="order_price">{{ $t('app.noInventory') }}</div>
                    <div v-else class="order_price">
                      {{ $t("app.money") }}{{ priceFormat(item.amount) }}
                    </div>
                  </div>
                </div>
              </div>
            </van-cell>
            <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../assets/img/down.png" /></div>
        </div>
        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div>
      </van-tab>
      <van-tab :title="$t('app.Failure')">
        <!--Empty pages-->
        <!-- <Empty></Empty> -->
        <div v-if="total > 0">
            <van-cell
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="order_nav">
                <div class="order_img">
                  <img :src="item?.img ? item.img : NoPicture" alt="" srcset="" />
                </div>
                <div class="order_cbk">
                  <div class="order_title">{{ item.proName }}</div>
                  <div class="order_status">
                    <span><van-icon name="close" size="16" /></span
                    ><span>{{ $t("app.Failure") }}</span>
                    <span>{{ item.orderId }}</span>
                  </div>
                  <div class="order_date">
                    <span><van-icon name="underway-o" size="16" /></span>
                    <span>{{ item.createTime }}</span>
                    <!-- <span>|</span>
                    <span>{{ item.buyQuantity }}{{ $t("app.tickets") }}</span> -->
                  </div>
                  <div class="order_date">
                    <span>{{ item.buyQuantity }}{{  getTicket(item.buyQuantity)  }}</span>
                  </div>
                  <div class="bo_text">
                    <div class="order_detail" @click="OrderDetails(item.orderId)">{{ $t('app.OrderDetails') }}</div>
                    <div v-if="Number(item.amount) == 0" class="order_price">{{ $t('app.noInventory') }}</div>
                    <div v-else class="order_price">
                      {{ $t("app.money") }}{{ priceFormat(item.amount) }}
                    </div>
                  </div>
                </div>
              </div>
            </van-cell>
            <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../assets/img/down.png" /></div>
        </div>
        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div>
      </van-tab>
      <van-tab :title="$t('app.Processing')">
        <!--Empty pages-->
        <!-- <Empty></Empty> -->
        <div v-if="total > 0">
            <van-cell
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="order_nav">
                <div class="order_img">
                  <img :src="item?.img ? item.img : NoPicture" alt="" srcset="" />
                </div>
                <div class="order_cbk">
                  <div class="order_title">{{ item.proName }}</div>
                  <div class="order_status">
                    <span><van-icon name="replay" /> </span
                    ><span>{{ $t("app.Processing") }}</span>
                    <span>{{ item.orderId }}</span>
                  </div>
                  <div class="order_date">
                    <span><van-icon name="underway-o" size="16" /></span>
                    <span>{{ item.createTime }}</span>
                    <!-- <span>|</span>
                    <span>{{ item.buyQuantity }}{{ $t("app.tickets") }}</span> -->
                  </div>
                  <div class="order_date">
                    <span>{{ item.buyQuantity }}{{ getTicket(item.buyQuantity) }}</span>
                  </div>
                  <div class="bo_text">
                    <div class="order_detail" @click="OrderDetails(item.orderId)">{{ $t('app.OrderDetails') }}</div>
                    <div v-if="Number(item.amount) == 0" class="order_price">{{ $t('app.noInventory') }}</div>
                    <div v-else class="order_price">
                      {{ $t("app.money") }}{{ priceFormat(item.amount) }}
                    </div>
                  </div>
                </div>
              </div>
            </van-cell>
            <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../assets/img/down.png" /></div>
        </div>
        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div>
      </van-tab>
    </van-tabs>

    <!-- order detail dialog -->
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="1050px"
      :visible.sync="orderDetailVisible"
    >
        <order-detail ref="orderDetail" :orderId="orderId" @closeDialog="orderDetailVisible = false" />
    </el-dialog>

    <!-- singIn dialog -->
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="signInVisible"
    >
        <sing-in-dialog :preview="signInVisible" @loginSuccess="loginSuccess"/>
    </el-dialog>
  </div>
</template>
<script>
import apis from "../../utils/apis";
import Vue from "vue";
import {
  NavBar,
  Tab,
  Tabs,
  NoticeBar,
  List,
  Button,
  Empty,
  Image as VanImage,
} from "vant";
// import Empty from "../../components/order/empty.vue";
import OrderDetail from "./components/OrderDetailDialog.vue";
import SingInDialog from "../../components/Login/SingInDialog.vue";
import NoPicture from "../../assets/img/no_picture.png";

Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NoticeBar);
Vue.use(List);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Empty);
let { OrderList } = apis;
export default {
  components: {
    // Empty,
    OrderDetail,
    SingInDialog,
  },
  data() {
    return {
      NoPicture: NoPicture,
      list: [],
      loading: false,
      finished: false,
      active: 0,
      num: 1,
      total: "",
      currentPage: 1,
      pageSize: 10,
      pageNum: 1,
      orderDetailVisible: false,
      showMoreFlg: false,
      signInVisible: false,
      orderId: "",
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    getTicket(num){
      if (!isNaN(num)) {
        return Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets')
      } else {
        return this.$t('app.tickets');
      }
    },
    getOrderList() {
      let params = {
        "mail": localStorage.getItem("email"), //注册用的邮箱，接收系统发送的验证码。
        "searchType": this.num, //搜索类型：0-全部订单；2-失败订单；1-成功订单；3-处理中订单
        "pageNum": this.currentPage,
        "pageSize": this.pageSize,
      };
      OrderList({ ...params }, (data) => {
        this.total = data.data.total;
        this.list = data.data.list;
        if(data.data.total > 10) this.showMoreFlg = true;
        else this.showMoreFlg = false;
      });
    },
    loadMore() {
      this.currentPage += 1;
      let params = {
        "mail": localStorage.getItem("email"), //注册用的邮箱，接收系统发送的验证码。
        "searchType": this.num, //搜索类型：0-全部订单；2-失败订单；1-成功订单；3-处理中订单
        "pageNum": this.currentPage,
        "pageSize": this.pageSize,
      };
      OrderList({ ...params }, (data) => {
        this.total = data.data.total;

        this.list = [...this.list, ...data.data.list]

        if(Number(this.total) === this.list.length) this.showMoreFlg = false;
        else this.showMoreFlg = true;
      });
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      //   setTimeout(() => {
      for (let i = 0; i < 2; i++) {
        this.list.push(this.list.length + 1);
      }
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.list.length >= 40) {
        this.finished = true;
      }
      //   }, );
    },
    onClickLeft() {
      this.$router.back();
    },
    onClick(name, title) {
      this.currentPage = 1;
      if (title == this.$t("app.Success")) {
        this.num = 1;
        this.getOrderList();
      } else if (title == this.$t("app.Failure")) {
        this.num = 2;
        this.getOrderList();
      } else if (title == this.$t("app.Processing")) {
        this.num = 3;
        this.getOrderList();
      } else {
        this.num = 0;
        this.getOrderList();
      }
      // console.log(title);
    },
    OrderDetails(orderId) {
      if(orderId === '' || orderId === null ) {
        this.$message({message: this.$t('app.orderExcption'), customClass: 'messageIndex'});
        return
      }
      if(!localStorage.getItem('token')) {
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        this.$EventBus.$emit('closeSingIn');
        this.signInVisible = true;
      } else {
        this.orderDetailVisible = true;
        this.orderId = orderId;
        this.$nextTick(() => {
          this.$refs.orderDetail.getOrderDetails();
        });
      }
    },
    loginSuccess() {
      this.signInVisible = false;
      this.$EventBus.$emit('closeSingIn')
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-tabs {
  width: 1136px;
  margin: 80px auto 30px;
  position: static;
  .van-tab {
    font-size: 19px;
  }
  .van-tabs__content {
    border: 1px solid #e0e0e0;
  }
}
/deep/ .van-tabs--line .van-tabs__wrap {
  width: 400px;
  margin-bottom: 30px;
}
/deep/ .van-icon {
  color: black;
}
/deep/.van-icon-certificate {
  color: #4aa772;
}
/deep/ .van-tabs__line {
  background-color: rgba(0, 170, 108);
}

/deep/ .van-cell::after {
  border-bottom: 0.04rem solid #ebebeb;
}

// /deep/ .van-tab--active {
//   font-weight: bold;
//   font-size: 0.28rem;
// }

/deep/ .van-notice-bar__left-icon {
  color: rgba(237, 106, 12);
}

/deep/ .van-nav-bar__title {
  font-weight: bold;
}

/deep/ .van-button__content {
  padding: 0 0.28rem;
}

/deep/ .van-tabs__content .van-cell {
  height: 174px;
  padding: 14px 16px;
  width: 100%;
}

.van-hairline--bottom:after {
  border-bottom-width: 0;
}

/deep/ .van-icon-checked {
  color: #4aa772;
}

/deep/ .van-icon-clock {
  color: #727171;
}

/deep/ .van-icon-clear {
  color: #ff0000;
}

/deep/ .van-icon-replay {
  color: #ff9000;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 0 32px 32px;
}
/deep/ .el-dialog--center{
  border-radius: 8px;
}

.order_nav {
  display: flex;
  justify-content: space-between;

  .order_img {
    img {
      border-radius: 0.12rem;
      object-fit: cover;
      width: 178px;
      height: 140px;
    }
  }

  .order_cbk {
    flex: 1;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0.36rem;
    min-width: 0;

    .order_title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.25rem;
      height: 0.26rem;
    }

    .order_status {
      span {
        color: #595757;
        font-size: 0.15rem;
        padding-right: 0.1rem;
      }
    }

    .order_date {
      span {
        font-size: 0.15rem;
        padding-right: 0.1rem;
      }
    }

    .bo_text {
      display: flex;
      justify-content: space-between;
      .order_detail {
        font-size: .18rem;
        color: #006699;
        cursor: default;
      }
      .order_price {
        font-size: .2rem;
      }
    }

  }
}
.showMore {
    width: 1114px;
    margin: 0 auto;
    background: #fff;
    height: 60px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    font-size: 0.18rem;
    font-weight: bold;
    color: #448CBB;
    margin-bottom: 30px;
    img {
      width: 0.2rem;
      margin-left: 0.1rem;
    }
}
</style>
