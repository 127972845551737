export default {
    app: {
        hello: 'Hello.World',
        cn: '中文（简体)',
        hk: '中文（繁体)',
        en: 'English',
        jp: '日本語',
        language: 'Language',
        signIn: 'Sign In',
        personal: 'Personal Homepage',
        editProfile: 'Edit Profile',
        orderList: 'Booking',
        changePwd: 'Reset Password',
        personalInformation:'personal information',
        logOut: 'Sign Out',
        collectionList: 'Favorite List',
        register: 'Join',

        search: 'Search',

        introduction: 'Tourism Strategy',
        area: 'Area',
        scenicSpot: 'Scenic Spot',
        shop: 'Merchant',
        ticket: 'Ticket',

        whereTo: 'Where to',

        enterBasicInfo: 'Basic Information Filling',
        userName: 'User Name',
        submit: 'Submit',

        all_area: 'All Regions',
        other_area: 'Other Regions',
        recommendedScenicSpots: 'Recommended Scenic Spots',

        Welcome_back: 'Welcome back.',
        Email_address: 'Email address',
        Password: 'Password',
        Forgot_password: 'Forgot password?',
        restPsw: 'The passwords do not match twice',
        Sign_in: "Sign in",
        line_login: 'Login With LINE',
        email_login: 'Login With Email',
        Not_a_member: "Not a member?",
        medin_bank: "Join to unlock the best of Media Bank Inc.",
        cookie_state: "By proceeding, you agree to our Terms of Use and confirm you have read our Privacy and Cookie Statement.",
        forget_pwd: "Forgot your password?",
        enter_your_pwd: "No problem. Just enter your email address below — we'll send you a code to reset it.",
        login_code: "Please enter the verification code",
        code: "Verification code",
        reset_pwd: "Reset password",
        new_pwd: "New password",
        new_pwd_confirmation: "New password confirmation",
        nike_name: "Nick name",
        Create_Account: "Create Account",
        create_pwd: "Create a password",
        displayed_title: " Only Media Bank pre-order is displayed.",
        All: "All",
        Success: "Success",
        Failure: "Failure",
        Processing: "Processing",
        Forest_zoo: "Forest zoo",
        Business_Hours: "Business Hours",
        Address: "Address",
        Notice: "Notice",
        Peripheral_Selection: "Peripheral Selection",
        Explore_now: "Explore now",
        space_for_lese: "Ad space for lease",
        we_got: "We've got everything you need to travel Japan in 2023.",
        Guideline: "Introduction",
        Area: "Area",
        Scenic_spot: "Scenic Spot",
        Shop: "Merchant",
        Ticket: "Ticket",
        topResult: "Top result in matching “{key}” in All Category",
        topResult1: "Top result in matching in All Category",
        collectResult: "Top result in matching “{key}” in All collections",
        collectResult1: "Top result in matching in All collections",
        All_Category: "Top result in {value}",
        book: "Book",
        Zoo_ticket: "Zoo ticket",
        Products: "Products that meet the requirements. ",
        Ticket_type: "Ticket type",
        Children: "Children",
        Date_selection: "Date selection",
        Purchase_quantity: "Purchase quantity",
        Next: "Next",
        pay_successful: "Payment successful, please wait for the ticket to be issued",
        ticket_issued_successful: "Ticket issued successfully. Please check your provided email for details",
        Complete: "Complete",
        My_Order: "My Order ",
        need_people: "Need to fill in the information of 2 people",
        Traveler: "Traveler ",
        Traveler_wo: "Traveler 2",
        Contact_person: "Contact person",
        Surname: "Surname :",
        Given_name: "Givenname :",
        Full_name: "Fullname :",
        Phone: "Phone+86 :",
        Email: "Email :",
        Please_surname: "Please fill in your surname",
        Please_name: "Please fill in your givenname",
        Please_number: "Please enter your mobile number",
        Please_email: "Please fill in the email",
        Email_required: "E-mail is required",
        Email_required_must: "E-mail must be valid",
        enter_pwd: "enter one user password",
        UserName_required: 'User name is required',
        Address_required: 'Address is required',
        verification_code: "Please enter the verification code",
        Please_pwd: "Please fill in the new password",
        Please_newpwd: "Please fill in the new password again",
        There_is_present: "There is no order of this type at present",
        To_explore: "To explore",
        money: "￥",
        bookNow: "Book",
        Traveler_Details: "Traveler Details",
        No_data:"No data Available",
        Required:"Required",
        human:"Human information",
        Order_Status:"Order Status",
        Payment_Status:"Payment Status",
        Type:"Type",
        Date:"Date",
        Quantity:"Quantity",
        reviews:"Views",
        recommend:"recommend",
        About_MEDIA_BANK: 'About Media Bank',
        MEDIA_BANK_Sites: 'Media Bank Sites',
        TrendingTravel:"Trending in Travel",
        Privacy: 'Privacy and Cookies Statement',
        Contactus: 'Contact Us',
        termsOfUse: 'Terms of Use',
        Places_Go:"Places to Go",
        howToContact: 'If you encounter any issues. please contact us using the following methods:',
        tel: 'Tel',
        mail: 'Mail',
        Adventure:"Adventure Tours",
        translate: 'English(EN) translation',
        translateFrom: 'English translation',
        originalContent: 'Show original text',
        translateContent: 'Translation',

        exploreNow: 'Explore now',
        exploreAll: 'Explore All',

        thingsToDoIn: 'Things to Do in',
        thingsToDo: 'Things to Do',

        OrderDetails: 'Order Details',
        orderExcption: 'Order Data Anomaly',


        liaisonDetail: 'Contact Details',
        customerName: 'Name',
        customerTelephone: 'Mobile Number',
        customerEmail: 'Email Address',
        customerWechat: 'WeChat ID',
        customerSex: 'Gender',
        man: 'Male',
        woman: 'Female',
        customerUseDate: 'Usage Date',
        customerCode: 'Postal Code',
        customerAddress: 'Address',
        customerCreditType: 'Identification Type',
        customerCreditNo: 'Identification Number',
        customerReceiveDate: 'Pick-up Date',
        customerNameRequired: 'Please enter your name',
        customerTelephoneRequired: 'Please enter your mobile number',
        customerWechatRequired: 'Please enter your WeChat ID',
        customerPassportRequired: 'Please enter your passport',
        customerSexRequired: 'Please select your gender',
        customerUseDateRequired: 'Please select your usage date',
        customerCodeRequired: 'Please enter your postal code',
        customerAddressRequired: 'Please enter your address',
        customerCreditTypeRequired: 'Please select your identification type',
        customerCreditNoRequired: 'Please enter your identification number',
        customerReceiveDateRequired: 'Please select your pick-up date',
        pleaseEnter: 'Please enter',
        reservation: 'Reservation Notice',

        identityCard: 'ID Card',
        studentIDCard: 'Student ID Card',
        militaryIDCard: 'Military ID Card',
        passport: 'Passport',
        residenceBooklet: 'Residence Booklet',
        hongKongAndMacaoPass: 'Hong Kong/Macau Pass',
        taiwanSyndrome: 'Taiwan Compatriot Permit',

        productDescription: 'Product Description',
        detailsIntro: 'Details Introduction',
        reserveNotice: 'Reservation Notice',
        productUseFlow: '【Usage Flow】',
        productLanguage: '【Service Language】',
        productAudienceAsk: '【Product Audience Requirements】',
        travelDuration: '【Travel Duration】',
        groupSize: '【Group Size】',
        proAddress: '【Address】',
        businessHours: '【Business Hours】',
        productCostInclude: '【Cost Includes】',
        productCostExclusive: '【Cost Excludes】',
        advanceDays: '【Advance Booking】',
        confirmWay: '【Confirmation Method】',
        effectiveDate: '【Effective Date】',
        refundInstructions: '【Refund Instructions】',
        predetermineNotice: '【Reservation Notice】',
        automaticConfirm: 'Automatic Confirmation',
        secondaryConfirm: 'Secondary Confirmation',
        English: 'English',
        Chinese: 'Chinese',
        Japanese: 'Japanese',
        Korean: 'Korean',
        advanceDaysText: 'Book at least {day} days in advance before {time}',
        orderValidText0: 'The selected date is currently valid',
        orderValidText1: 'Valid {day} days after the reservation date',
        orderValidText2: 'Valid until {date} for reservation date',
        orderValidText3: 'Valid {day} days after the selected date',
        orderValidText4: 'Valid until {date} for the selected date',
        orderValidText5: 'Valid from {startDate} to {endDate}',
        orderValidText6: 'Valid from {startDay} days before the specified usage date to {endDay} days after the specified date',




        total: 'Total',
        Contact_information: 'Contact information',
        name: 'Name:',
        eMail: 'Email:',
        close: 'Close',
        More_Detail: 'More Detail',
        More: 'More',
        System_Order_ID: 'System Order ID:',
        Payment_Order_ID: 'Payment Order ID:',
        Ticket_Order_ID: 'Ticket Order ID:',
        System_Order_Status: 'System Order Status:',
        Ticket_PDF_Url: 'Ticket PDF Url:',
        about:'About',
        Rating:"Star",
        homeTitle1: 'Ways to tour Japan',
        homeSubtitle1: "Spots at the top of travelers' must-go lists",
        homeTitle2: 'Top-Rated Merchants',
        homeSubtitle2: 'Book these experiences for a close-up look at Japan.',
        homeTitle3: 'Recommended Destinations',
        homeSubtitle3: "Spots at the top of travelers' must-go lists",
        homeTitle4: 'Featured Ticket',
        homeSubtitle4: 'Book these experiences for a close-up look at Japan.',
        homeTitle5: 'Travel Stories',
        homeSubtitle5: 'Introduce popular sightseeing spots, gourmet information, model courses, etc',
        DateList:"Failed to get price calendar",
        calendarConfirmText: 'Confirm',
        noInventory: 'Out of stock',
        priceVaries: 'price varies by group size',
        change_language_null_tips: 'No data in the selected language, whether to return to the previous page',
        data_null_tips: 'There is currently no data available',

        noticeList: 'Message List',
        noticeDetail: 'Message Detail',
        noticeTitle: 'Message Title: ',
        sendTime: 'Publish Time: ',
        noticeContent: 'Message Content: ',
        read: 'Read',
        unRead: 'UnRead',
        delete: 'Delete',
        cancel: 'Cancel',
        allNotice: '{total}message in total',
        allUnRead: '{total}unread message in total',
        allRead: '{total}read message in total',

        joininMEDIN_BANK: 'Sign in using your Media Bank Inc account.',
        Already_a_member: 'Already a member?',

        secureCheckout: 'Secure Checkout',
        secureCheckoutText: 'Secure Checkout - it takes only a few minutes',
        PayNow: 'PayNow',
        trade_tips:'Please complete the payment within two minutes. The order will be canceled if the payment is interrupted midway.',
        paymentProtection: 'Payment Protection',
        non_refundable: 'non-refundable',
        minimum_order: '1 minimum order',

        aboutPlace: 'About this place',
        aboutJTA:'About Japan Tourism Agency',
        aboutJED:'About\"Japan. Endless Discovery.\"logo',

        tickets:'ticket',
        tickets_s:'tickets',

        videoList:'Hot Videos',
        videoList_sub:'Share What You Like',
        allVideos: 'All Videos',

        readMore: 'Read more',
        Check_availability: 'Price Calendar',
        copyright:'Copyright © 2023 Media Bank Inc. All rights reserved.',

        plz_login: 'Please Login First',
        showAllAreaContent: 'View All Content About The Area',

        emailEntryHint: 'This email will be used to receive electronic ticket vouchers. Please fill it out carefully. If filled in incorrectly, you will not be able to obtain an electronic ticket.',
        auth_loading:'Authorization in progress, please wait...',
        auth_failed:'Authorization failed, please retry later',
        back:'Back',
        pay_failed:'Payment failed. Please check the payment information you entered for accuracy.',
        verygood:'Very good',
        createdOrderFailed: 'Order Creation Failed',

        website:'Website',
        position:'Position',
        go_order:'Go To Order',
        noOperation: 'Closed',
        businessWeeks:{ week1:'Monday',week2:'Tuesday',week3:'Wednesday',week4:'Thursday',week5:'Friday',week6:'Saturday',week7:'Sunday'},
        store_operation:'Operating hours',
        store_introduction:'Introduce',
        store_Information:'Blog',
        store_periphery:'Periphery',
        store_comment:'Evaluation',
        store_comment_addBtn:'Evaluate',
        store_comment_placeholder: 'Please enter evaluation',
        store_comment_title: 'User evaluation',
        store_comment_null: 'The evaluation content cannot be empty',
        store_comment_success: 'Evaluate success',
        store_comment_del: 'Successfully deleted',
        confirm: 'confirm',
        store_comment_delTips: 'Confirm whether to delete the evaluation',
        copy_div:"Has been copied to the clipboard",
        copy_entry_div:"There is no relevant information for this store",

        fresh_news_title:"Fresh News",
        fresh_news_subtitle:"Merchant Hot Search Consultation",
        fresh_news_detail_title:"Hot Search Consultation",
        new_nike_name:'New Nike Name',
        Please_new_nike_pwd: "Please fill in nickname",
        user_pic:"Avatar",
        user_info:'User Info',

        image_require: 'Please upload image file',
        image_max_2: 'Image size cannot exceed 2MB!',

        plan_go: 'Pick',
        user_plan_go: ' people plan to go!',
        plan_added: 'Plan added',
        come_plan: 'No one is planning to go yet, come join us!',
        more_plan: 'm o r e ...',
        add_plan_success: 'Plan added',
        cancel_plan_success: 'Successful Cancel',

        pop_introduction_recommend: "Popular Strategy Recommendations",
        pop_area_recommend: "Recommended By Popular Regions",
        pop_scenicSpot_recommend: "Recommended Popular Tourist Attractions",
        pop_shop_recommend: "Popular Merchant Recommendations",
        guess_you_like: "Guess Your Like",
        everyone_plans_go: "Everyone Plans To Go",
        introduction_list: "Introduction List",
        introduction_collect_list: "Introduction Favorite List",
        area_list: "Area List",
        area_collect_list: "Area Favorite List",
        scenicSpot_list: "Scenic List",
        scenicSpot_collect_list: "Scenic Favorite List",
        shop_list: "Merchant List",
        shop_collect_list: "Merchant Favorite List",
        ticket_list: 'Ticket List',
        ticket_collect_list: 'Ticket Favorite List',

        merchant_enter: 'Merchants enter',
        discount_price: 'Discount price',

        SCTAI_title:'Specific Commercial Transactions Act Information ',
        SCTAI_col1_title:'Entity',
        SCTAI_col1_val:'Media Bank Inc.',
        SCTAI_col2_title:'Company Location',
        SCTAI_col2_val:'11F KDX Toranomon 1-chome Bldg. 1-10-5, Toranomon, Minato-ku, Tokyo 105-0001, Japan',
        SCTAI_col3_title:'Chief Operating Officer',
        SCTAI_col3_val:'Yayin Li',
        SCTAI_col4_title:'代表者または運営統括責任者（フリガナ）',
        SCTAI_col4_val:'リ アイン',
        SCTAI_col5_title:'Contact Information/Company Homepage',
        SCTAI_col5_val:'https://www.media-bank.co/',
        SCTAI_col6_title:'Contact Information/E-mail (Customer Service Email)',
        SCTAI_col6_val:'info@media-bank.info',
        SCTAI_col7_title:'Contact Information/TEL (Customer Service)',
        SCTAI_col7_val:'+81-3-4400-0707',
        SCTAI_col8_title:'Contact/Product Website',
        SCTAI_col8_val:'https://gotoja.media-bank.info/',
        SCTAI_col9_title:'Website name',
        SCTAI_col9_val:'Go-to-Japan',
        SCTAI_col10_title:'Minimum price',
        SCTAI_col10_val:'1,000 yen ',
        SCTAI_col11_title:'Maximum price',
        SCTAI_col11_val:' 50,000 yen',
        SCTAI_col12_title:'Delivery time (number of days) of goods (including services), shipping method',
        SCTAI_col12_val1:'Delivery time: after payment completion.',
        SCTAI_col12_val2:'After consumers purchase goods from the website, the relevant e-coupon will be sent by email to the registered email.',
        SCTAI_col12_val3:'Some products require a second confirmation, please refer to the instructions for each product for more details.',
        SCTAI_col13_title:'Payment time and method',
        SCTAI_col13_val1:'Payment time: after order confirmation ',
        SCTAI_col13_val2:' Payment Methods: All payment methods represented by the icons displayed on the website are available.',
        SCTAI_col14_title:'Other expenses other than the price of goods (including services) / shipping costs, consumption tax, etc.',
        SCTAI_col14_val:'Communication fees and payment processing fees related to the use of the Internet shall be borne by the customer.',
        SCTAI_col15_title:'Necessary expenses other than the price of goods (including services)/shipping fees, consumption tax, etc.: ',
        SCTAI_col15_val:'Customers are responsible for communication fees and payment processing fees related to the use of the Internet.',
        SCTAI_col16_title:'Return handling conditions/return deadlines, responsibility for return shipping costs, or cancellation and withdrawal conditions/agreements for cancellation and withdrawa',
        SCTAI_col16_val:'Due to the nature of the product, returns or refunds are not accepted after purchase is confirmed.',
        SCTAI_col17_title:'Handling conditions for defective products',
        SCTAI_col17_val:'If the vouchers provided by this website have defects, please contact our customer service to obtain vouchers without defects. Please note that our company does not accept refunds.',
        time_out: 'Request timed out',

        favorite: {
            share: 'Share',
            collect: 'Collect',
            collected: 'Collected'
        },
        merchant_closed: 'Closed'
    }
}
