<template>
  <div>
    <van-swipe class="homePage-swipe" :autoplay="4000">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="toDetail(item)">
        <img :src=item.banner>
      </van-swipe-item >
    </van-swipe>
  </div>

</template>

<script>

import {Swipe, SwipeItem} from 'vant';

export default {
  name: "homeBanner",
  props: ['bannerList'],
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
    };
  },
  methods: {
    toDetail(item){
      if(item.url){
        if(item.url.indexOf("http://") != -1 || item.url.indexOf("https://" != -1)) {
          window.location.href = item.url;
        } else {
          window.location.href = window.location.protocol + '//' + item.url;
        }
      }else if(item.refId){
        this.$router.push({
          path: "essayDetail",
          query: {
            type: item.refType,
            id: item.refId,
          },
        });
      }
    }
  }
}
</script>

<style lang="less">
.homePage-swipe {
  width: 1136px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 348px;
    display: block;
    object-fit: cover;
  }

  .van-swipe__indicators {
    .van-swipe__indicator {
      background-color: white;
      opacity: 1;
      border: 1px #000 solid;
    }

    .van-swipe__indicator--active {
      width: 18px;
      border-radius: 6px;
    }
  }


}

</style>
