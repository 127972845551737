<template>
  <div style="margin-top: 110px"> 
    <div class="list">
      <van-tabs swipeable v-model="queryParams.readStatus" @click="onNav">
        <van-tab :title="$t('app.All')" name="3">
          <van-list v-if="total > 0">
            <van-cell>
              <div class="line_height"></div>
              <div class="nav_list">
                <div class="list_title">
                  <span>{{ $t('app.allNotice', {total: total} ) }}</span>
                  <!-- <van-icon name="delete-o" /> -->
                </div>
                <div
                  class="list_nav"
                  v-for="(item, index) in searchList"
                  :key="index"
                  @click="goDetails(item)"
                >
                    <div class="head">
                        <div class="list_describe">
                        <van-badge v-if="item.readStatus === 0" dot />
                        <span v-bind:class="{ 'unread_msg': item.readStatus === 0, 'read_msg':item.readStatus != 0,'_h3':true }">{{ item.title }}</span>
                        <p class="text-title" >{{ item.sendTime }}</p>
                        <span v-bind:class="{ 'unread_msg': item.readStatus === 0, 'read_msg': item.readStatus != 0 ,'list_view':true}">{{ item.content | filtersText }} </span>
                        </div>
                    </div>
                </div>
              </div>
              <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../assets/img/down.png" /></div>
            </van-cell>
          </van-list>
          <div v-else>
            <van-empty :description="$t('app.No_data')" />
          </div>
        </van-tab>
        <van-tab :title="$t('app.unRead')" name="0">
          <van-list v-if="total > 0">
            <van-cell>
              <div class="line_height"></div>
              <div class="nav_list">
                <div class="list_title">
                  {{ $t('app.allUnRead', {total: total} ) }}
                </div>
                <div
                  class="list_nav"
                  v-for="(item, index) in searchList"
                  :key="index"
                  @click="goDetails(item)"
                >
                  <div class="head">
                    <div class="list_describe">
                      <van-badge v-if="item.readStatus === 0" dot />
                      <h3>{{ item.title }}</h3>
                      <p class="text-title">{{ item.sendTime }}</p>
                      <span class="list_view">{{ item.content | filtersText }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../assets/img/down.png" /></div>
            </van-cell>
          </van-list>
          <div v-else>
            <van-empty :description="$t('app.No_data')" />
          </div>
        </van-tab>
        <van-tab :title="$t('app.read')" name="1">
          <van-list v-if="total > 0">
            <van-cell>
              <div class="line_height"></div>
              <div class="nav_list">
                <div class="list_title">
                  <span>{{ $t('app.allRead', {total: total} ) }}</span>
                  <!-- <van-icon name="delete-o" /> -->
                </div>
                <div
                  class="list_nav"
                  v-for="(item, index) in searchList"
                  :key="index"
                  @click="goDetails(item)"
                >
                  <div class="head">
                    <div class="list_describe">
                      <h3>{{ item.title }}</h3>
                      <p class="text-title">{{ item.sendTime }}</p>
                      <span class="list_view">{{ item.content | filtersText }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../assets/img/down.png" /></div>
            </van-cell>
          </van-list>
          <div v-else>
            <van-empty :description="$t('app.No_data')" />
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <el-dialog
      :title="$t('app.noticeDetail')"
      center
      lock-scroll
      append-to-body
      width="800px"
      :visible.sync="detailVisible"
      @close="cancel"
    >
        <el-form 
            size="small"
            label-position="left"
            :label-width="setWidth"
        >
            <el-form-item :label="$t('app.noticeTitle')">
                <span>{{ nocDetail.title }}</span>
            </el-form-item>
            <el-form-item :label="$t('app.sendTime')">
                <span>{{ nocDetail.sendTime }}</span>
            </el-form-item>
            <el-form-item :label="$t('app.noticeContent')">
                <span class="noc_content">{{ nocDetail.content }}</span>
            </el-form-item>
            <el-form-item :label="'\u3000'">
                <el-button type="primary" @click="deleteMsg">{{ $t('app.delete') }}</el-button>
                <el-button @click="cancel">{{ $t('app.cancel') }}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>
<script>
import apis from "../../utils/apis";
import Vue from "vue";
import {
  Tab,
  Tabs,
  List,
  Cell,
  Empty,
  Badge
} from "vant";
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(Cell);
Vue.use(Badge);
Vue.use(Empty);

export default {
  data() {
    return {
      total: 0,
      showMoreFlg: false,
      detailVisible: false,
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        readStatus: 0,
        languageType: null,
        userId: null,
      },
      searchList: [],
      nocDetail: {},
    }
  },
  computed: {
    setWidth() {
      const lankey = localStorage.getItem("language");
      return lankey === 'cn' || lankey === 'hk' ? '88px' : '155px';
    }
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
  created() {
    this.getList()
  },
  methods: {
    onNav(name, title) {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    getList() {
      let types = {
      "en": 2,
      "cn": 0,
      "jp": 1,
      "hk": 3
      }
      this.queryParams.languageType = localStorage.getItem("language") == null ? 2 : types[localStorage.getItem("language")];
      this.queryParams.userId = localStorage.getItem("userId");
      // this.queryParams.userId = '1643875911356440576';
      apis.getMessagetList(this.queryParams, (data) => {
        this.searchList = data.data.list;
        this.total = data.data.total;

        if(data.data.list.length === 20) this.showMoreFlg = true;
        else this.showMoreFlg = false;
      });
    },
    loadMore() {
      this.queryParams.pageNum = this.queryParams.pageNum + 1;
      let types = {
        "en": 2,
        "cn": 0,
        "jp": 1,
        "hk": 3
      }
      this.queryParams.languageType = localStorage.getItem("language") == null ? 2 : types[localStorage.getItem("language")];
      // this.queryParams.userId = localStorage.getItem("userId");
      this.queryParams.userId = '1643875911356440576';
      apis.getMessagetList(this.queryParams, (data) => {

        this.searchList = [...this.searchList, ...data.data.list];
        this.total = data.data.total;

        if(this.searchList.length === this.total) this.showMoreFlg = false;
        else this.showMoreFlg = true;
      });

    },
    goDetails(item) {
      this.detailVisible = true;
      this.nocDetail = item;
      if(item.readStatus === 0) {
        apis.updateMsg({readStatus: 1, id: item.id}, (data) => {
          // console.log(data);
        })
      }
    },
    deleteMsg() {
      apis.deleteMsg({id: this.nocDetail.id}, (data) => {
        if(data.code === 200) {
            this.detailVisible = false;
            this.getList();
        }
      })
    },
    cancel() {
      this.detailVisible = false;
      this.getList();
    }
  }
}
</script>
<style scoped lang="less">
@import url("../../assets/css/notice.less");
</style>