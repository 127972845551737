<template>
  <div style="text-align: left; width: 1136px; margin: 0 auto;">
    <p style="margin-top: 1.4rem; text-align: center; font-size: .35rem">{{ $t('app.SCTAI_title') }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col1_title')  }}:</a>{{ $t('app.SCTAI_col1_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col2_title') }}:</a><br>{{ $t('app.SCTAI_col2_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col3_title')  }}:</a>{{ $t('app.SCTAI_col3_val')  }}</p>
    <p v-show="isJp()" ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col4_title')  }}:</a>{{ $t('app.SCTAI_col4_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col5_title')  }}:</a><a :href="getHref(5)">{{ $t('app.SCTAI_col5_val')  }}</a></p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col6_title')  }}:</a><a :href="getMail(6)">{{ $t('app.SCTAI_col6_val')  }}</a></p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col7_title')  }}:</a><a :href="getPhone(7)">{{ $t('app.SCTAI_col7_val')  }}</a></p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col8_title')  }}:</a><a :href="getHref(8)">{{ $t('app.SCTAI_col8_val')  }}</a></p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col9_title')  }}:</a>{{ $t('app.SCTAI_col9_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col10_title')  }}:</a>{{ $t('app.SCTAI_col10_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col11_title')  }}:</a>{{ $t('app.SCTAI_col11_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col12_title')  }}:</a><br>{{ $t('app.SCTAI_col12_val1')  }}<br>{{ $t('app.SCTAI_col12_val2')  }}<br>{{ $t('app.SCTAI_col12_val3')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col13_title')  }}:</a><br>{{ $t('app.SCTAI_col13_val1')  }}<br>{{ $t('app.SCTAI_col13_val2')  }}</p>
    <p v-show="isEN()"><a style="font-weight:bold ;">{{ $t('app.SCTAI_col14_title')  }}:</a><br>{{ $t('app.SCTAI_col14_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col15_title')  }}:</a><br>{{ $t('app.SCTAI_col15_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col16_title')  }}:</a><br>{{ $t('app.SCTAI_col16_val')  }}</p>
    <p ><a style="font-weight:bold ;">{{ $t('app.SCTAI_col17_title')  }}:</a><br>{{ $t('app.SCTAI_col17_val')  }}</p>
  
  </div>
</template>


<script>

export default {
  data() {
    return {
    }
  },
  methods:{
    isJp(){
      let lang = localStorage.getItem("language");
      return lang === 'jp'
    },
    isEN(){
      let lang = localStorage.getItem("language");
      return lang === 'en'
    },
    getHref(rowId,colId = ""){
      return this.$t('app.SCTAI_col'+rowId+'_val'+colId);
    },
    getMail(rowId,colId = ""){
      return "mailto:"+this.$t('app.SCTAI_col'+rowId+'_val'+colId);
    },
    getPhone(rowId,colId = ""){
      return "tel:"+this.$t('app.SCTAI_col'+rowId+'_val'+colId);
    }
  }
}
</script>
<style scoped>
.indent {
  text-indent: 2em;
}
p {
  line-height: .5rem;
  font-size: .2rem;
}
</style>
