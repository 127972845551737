<template>
  <div style="text-align: left; width: 1136px; margin: 0 auto;">
    <p style="margin-top: 1.4rem; text-align: center; font-size: .35rem">MediaBank「GotoJapan」の基本規約</p>
    <p >「MediaBank「GotoJapan」」（具体的には、第3条第1項第1号の「本サービス」を指します。）は、株式会社MediaBank（以下「当社」といいます。）の「行動者発の情報が、人の心を揺さぶる時代へ」という理念と別途定める「MediaBank「GotoJapan」運営者ポリシー」（以下「運営ポリシー」といいます。）に基づき、プレスリリース等の情報流通を通じて、企業（法人、団体のみならず、個人事業主を含み、その他あらゆる事業者を含みます。以下同じ。）とそこに所属する個人、メディアや報道関係者、そして一般の生活者をつなぐプラットフォームを提供することを主な目的としています。</p>
    <p>この「MediaBank「GotoJapan」の基本規約」（以下「基本規約」といいます。）は、本サービス（第3条第1号の「本サービス」を指します。以下同じ。）の利用（第3条第2号の「利用」を指します。以下同じ。）に関する基本的な条件を、本サービスをご利用頂く全ての皆様と当社の間で定めるものです。</p>
    <p class="indent">第1条 各利用者に適用される規約の構成と各規約の優先関係</p>
    <p >1. 本サービスをご利用頂く場合に適用される規約は、本サービスをご利用頂く全ての皆様に適用される基本規約と、本サービスの利用方法に応じて適用される以下の各号に定める各規約（以下「個別規約」と総称します。）によって構成されます（以下、基本規約および個別規約を総称して「各規約」といいます。）。</p>
    <p >① 本サービスにプレスリリース等その他自己の情報を掲載する目的で利用する場合には、基本規約と併せて「MediaBank「GotoJapan」企業規約」（以下「企業規約」といいます。）が適用されます。</p>
    <p >② 本サービスに掲載された情報を報道関係者（第3条第8号の「報道関係者」を指します。）として利用する場合、基本規約と併せて「MediaBank「GotoJapan」報道規約 」（以下「報道規約」といいます。）が適用されます。</p>
    <p >③ 本サービスに掲載された情報を上記各号の目的以外の目的（情報閲覧・取得目的等）で一般生活者等として利用する場合、基本規約と併せて（MediaBank「GotoJapan」一般規約 」（以下「一般規約」といいます。）が適用されます。</p>
    <p >2. 基本規約と個別規約が矛盾する場合は、当該矛盾する部分において、個別規約が優先するものとし、その他の部分については、基本規約と個別規約がいずれも適用されるものとします。 </p>
    <p class="indent">第2条 各規約への同意</p>
    <p >1. 本サービスをご利用頂くにあたっては、適用される各規約の全文をお読み頂いたうえで、その全ての条項について同意頂く必要があります。</p>
    <p >2. 本サービスをご利用頂いた場合、適用される各規約の内容を理解しており、かつ、その全ての条項について同意したものとみなします。 </p>
    <p class="indent">第3条 定義</p>
    <p >各規約における用語の定義は、特に断らない限り、以下のとおりとします。なお、以下の各号の用語のほか、基本規約上の他の箇所で定義された用語についても、特に断らない限り、各規約において共通の意味を有するものとします。</p>
    <p >① 「本サービス」とは、当社が、「MediaBank「GotoJapan」」との名称の下、基本規約前文に記載の目的で提供する、利用者（第10号の「利用者」をいいます。）に対してプレスリリースをはじめとした情報の流通を可能とするプラットフォームサービス及びこれに付随する一切のサービスをいいます。</p>
    <p >② 本サービスの「利用」とは、本サービスに関連し若しくは本サービスを介して行う一切の活動をいい、本サービスに情報を掲載する行為や本サービスにおける情報を活用する行為のみならず、本サービスにおける情報を閲覧・受信する行為なども含みます。ただし、各利用者が本サービスを利用可能な範囲は、各規約および別途当社が指定する条件、内容および方法等の範囲内に限られます。</p>
    <p >③ 「本サイト」とは、本サービスを提供するためのWebサイトおよびスマートフォンやタブレット端末用 OS向けのアプリケーションソフトウェアをいいます。</p>
    <p >④ 「ご利用企業」とは、基本規約と企業規約に同意したうえで当社が指定する方法に従って本サービスの利用登録を申請し、当社が審査して利用登録を承認した企業や団体、または個人事業主をいいます。</p>
    <p >⑤ 「メディアユーザー」とは、報道関係者（第8号の「報道関係者」をいいます。）であって、基本規約と報道規約に同意したうえ当社が指定する方法に従って本サービスの利用登録を申請し、当社が審査して利用登録を承認した方（記者、編集者、ライター、または番組制作者等を含みますが、これらに限られません。）をいいます。</p>
    <p >⑥ 「一般ユーザー」とは、基本規約と一般規約に同意したうえ当社が指定する方法に従って本サービスの利用登録を申請し、利用登録した方をいいます。</p>
    <p >⑦ 「本登録者」とは、ご利用企業、メディアユーザー、一般ユーザーのいずれかまたはその複数の資格を有する方をいいます。</p>
    <p >⑧ 「報道関係者」とは、メディアユーザーとしての資格を有するか否かにかかわらず、本サービスに掲載された情報を、報道を事業内容に含む事業者あるいはその役職員として、または報道目的（本サービスから受領した情報を、自身または第三者のメディアにおいて情報発信する目的。プレスリリースやストーリーの転載も含みます。以下同じ。）で利用する方をいいます。</p>
    <p >⑨ 「ゲスト」とは、一般ユーザーとしての本サービスの利用登録を行わずに、一般ユーザーと同様の目的（本サービスに掲載された情報の閲覧・受領目的等）で、本サービスを利用する方をいいます。</p>
    <p >⑩ 「利用者」とは、本サービスを利用する一切の方をいい、本登録者ならびに本登録者に該当しない報道関係者およびゲストを含みます。</p>
    <p >⑪ 「アカウント」とは、本登録者として本サービスを登録のうえ利用いただくためのアカウントをいいます。</p>
    <p >⑫ 「企業コンテンツ」とは、ご利用企業が本サービスに投稿する（本サービスにおいてまたは本サービスを介するなど本サービスに関連して情報を提供または送信等する一切の行為を含みます。）プレスリリースをはじめとした一切のコンテンツ（文章や、それに付随する画像、動画、音声、図、表、電子ファイル等その他一切の情報をいいます。）をいいます。</p>
    <p >⑬ 「パートナーメディア」とは、当社が提携し、ご利用企業が本サービスに投稿した企業コンテンツを転載するWebサイト等の情報媒体をいいます。</p>
    <p >⑭ 「プレスリリース」とは、企業コンテンツのうち、企業規約第3条第2項第1号の趣旨に該当するコンテンツであって、当社が別途定める「MediaBank「GotoJapan」コンテンツ基準」（以下「コンテンツ基準」といいます。）を遵守したものをいいます。</p>
    <p >⑮ 「ストーリー」とは、企業コンテンツのうち、企業規約第3条第2項第2号の趣旨に該当するコンテンツであって、コンテンツ基準を遵守したものをいいます。 </p>
    <p class="indent">第4条 本規約の変更</p>
    <p >1. 当社は、利用者の承諾を得ることなく、各規約を変更できるものとします。本サービスをご利用する際には、最新の各規約の内容をご確認ください。</p>
    <p >2. 当社は、各規約の変更を行う場合、当該変更内容および変更日を、本サイトに掲載して告知するものとします。</p>
    <p >3. 本規約の変更後、利用者が本サービスを利用した場合、利用者は本規約の変更に同意したものとみなします。 </p>
    <p class="indent">第5条 本サービスの利用登録</p>
    <p >1. 利用者は、自己の責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果について一切の責任を負うものとします。</p>
    <p >2. 本登録者として本サービスを利用するためには、本登録者の種別（ご利用企業、メディアユーザーまたは一般ユーザー）に応じた本サービスの利用登録（以下「利用登録」といいます。）が必要となります。また、利用登録には、以下の全てを満たす方であることを前提に、該当する個別規約で定める条件を満たすことが必要となります（個別規約で定める登録拒否事由に該当しないこと等を含みますが、これに限られません。）。</p>
    <p >① 別途定める運営ポリシーを尊重してくださる方</p>
    <p >② 各規約に同意の上、継続して遵守できる方</p>
    <p >③ 当該利用者としての契約締結について法的に要求される権限及び能力を有している方</p>
    <p >④ 暴力団、暴力団員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治運動標ぼうゴロ、特殊知能暴力集団、その他反社会的勢力（以下「反社会的勢力」といいます。）に一切関与していない方および関与している疑いのない方</p>
    <p >⑤ 過去に本登録者としての資格（以下「本登録者資格」といいます。）を抹消された経験がない方</p>
    <p >⑥ その他、当社が不適切と判断する事由に該当しない方</p>
    <p >3. 利用者は、本サービスの利用登録の申請時に、登録を希望する本登録者の種別（ご利用企業、メディアユーザーまたは一般ユーザー）を選択するものとし、利用登録が承認された場合、当該選択した種別にかかる本登録者向けのサービス内容のみ利用できるものとします。本登録者が利用登録した本登録者の種別を変更または追加する場合、当社が別途定める手続きに従うものとします。 </p>
    <p class="indent">第6条 本登録者の登録情報とその取扱い</p>
    <p >1. 当社は、本登録者が本サービスを利用するにあたり、個人情報その他利用者に関する一定の情報（以下「本登録者情報」といいます。）を取得するものとします。</p>
    <p >2. 当社は、本登録者から取得する個人情報を、当社が別途定める「プライバシーポリシー」に基づき、適切に取り扱います。本登録者は、当該「プライバシーポリシー」に基づく個人情報等の取扱いに同意するものとします。</p>
    <p >3. 本登録者は、自己の責任において、正確な本登録者情報を登録するものとします。登録情報に変更が生じた場合には、直ちに当社所定の⼿続により修正しなければならず、常に本登録者⾃⾝の正確な情報が登録されているよう、本登録者は、登録情報を管理・修正する責任を負うものとします。登録情報に変更があったにもかかわらず、本登録者が当社所定の⼿続により変更の届出をしていない場合、当社は、登録情報に変更がないものとして取り扱うことができます。変更の届出があった場合でも、当該変更が登録情報に反映される前に⾏われた取引や各種⼿続は、変更前の情報に準拠することがあります。</p>
    <p >4. 本登録者の登録情報に虚偽、誤りまたは記載漏れがあったことにより本登録者に損害が生じたとしても、当社は一切責任を負いません。 </p>
    <p class="indent">第7条 本登録者のアカウント等</p>
    <p >1. 当社は、本登録者に対して、本サービスの利用登録の承認の際、本登録者の指定に基づき本登録者のIDおよびパスワードを付与し、これらにより本登録者としての本サービスの利用が可能となるアカウントを発行するものとします。なお、本登録者は、当社の承認がある場合を除き、1名または1法人につき1つのアカウントのみ発行を受けることができます。ただし、当社は、ご利用企業に対しては、1つの企業アカウントおよび1つのユーザーアカウントの計2種類のアカウントを発行するものとし、そのうちユーザーアカウントについては、ご利用企業自身の判断により、別途当社が指定する方法に従って、アカウント数を増減させることができるものとします。</p>
    <p >2. 本登録者およびご利用企業によりユーザーアカウントを割り当てられた方は、自己のアカウント（企業アカウントおよびユーザーアカウントを含みます。以下同じ。）、IDまたはパスワード（以下「アカウント等」といいます。）を第三者に譲渡または貸与できません。またパスワードを第三者に開示せず、自己の責任で厳重に管理するものとします。</p>
    <p >3. 本登録者は、アカウント、IDおよびパスワードの管理に全責任を持つものとし、管理不十分、使用上の過誤、第三者の使用等による損害について、当社は一切の責任を負いません。また、本登録者のアカウント等を利用して行われた行為の責任は、当該アカウント等を付与された本登録者の責任とみなします。</p>
    <p >4. 本登録者は、アカウント等が第三者に使用されていることが判明した場合には、直ちにその旨を当社に届け出るものとし、当社からの指示に従うものとします。</p>
    <p >5. ご利用企業の各ユーザーアカウントを利用する方は、当該ご利用企業の企業アカウントの管理者であるとみなされるとともに、各ユーザーアカウントを利用して行われた行為の一切は当該ご利用企業の行為とみなし、かかる行為の責任は当該ご利用企業の責任とみなします。また、ご利用企業は、当社からの利用料金等の請求書の送付先として、企業アカウントまたはユーザーアカウント以外の連絡先を登録することができますが、当社が当該連絡先との間での円滑な連絡が困難と判断した場合には、当社の判断により、当該ご利用企業の企業アカウントまたはユーザーアカウント（そのいずれかまたはその全て）宛に利用料金等の請求をはじめ各種連絡・通知・請求等を行うことができるものとします。かかる連絡等を行った場合、当該ご利用企業をはじめ、その企業アカウントおよびユーザーアカウント全てに対して当該連絡等がなされたものとみなします。なお、ご利用企業、当該ご利用企業のユーザーアカウントを利用する方および請求書の送付先として登録された方その他のご利用企業の関係者は、本項の取り扱いについて、予め承諾するものとし、これに対して一切の異議を述べないものとします。 </p>
    <p class="indent">第8条 本登録者からの解約</p>
    <p >1. 本登録者は、当社所定の方法によって本サービスの利用を解約できるものとします。この場合、本登録者は、本サービスを即時利用できなくなります。</p>
    <p >2. 本登録者は、解約後も、本サービス利用期間中に生じた一切の義務および債務を免れるものではありません。 </p>
    <p class="indent">第9条 本サービスの利用停止、本登録者資格の抹消</p>
    <p >1. 当社は、利用者が以下のいずれかに該当すると判断した場合、当該利用者の承諾を得ることなく、当該利用者による本サービスの利用を停止できるものとします。</p>
    <p >① 第5条（本サービスの利用登録）第2項各号のいずれかに該当しない場合、第15条（禁止事項）に違反した場合、またはそれらの場合に該当するおそれがある場合</p>
    <p >② 前号のほか、各規約のいずれかの条項に違反した場合、またはそのおそれがある場合</p>
    <p >③ 本登録者の登録情報に虚偽、誤りまたは記載漏れ等がある場合</p>
    <p >④ 過去に本登録者資格を抹消された者またはその関係者である場合</p>
    <p >⑤ 電話、メール、郵便等による連絡がとれない、または当社からの問い合わせもしくは通知に対して30日間以上応答がない場合</p>
    <p >⑥ その他、当社が必要と判断した場合</p>
    <p >2. 当社は、本登録者が以下のいずれかに該当すると判断した場合、本登録者の承諾を得ることなく、本登録者資格を抹消できるものとします。</p>
    <p >① 本サービスの利用停止処分事由（前項各号の事由をいいます。以下、本項において同じ。）に該当し、当該事由が重大である場合</p>
    <p >② 本サービスの利用停止処分事由に該当し、当社が指定する方法により当該事由の再発防止を約束頂けない場合</p>
    <p >③ 直近3年以内に2回以上、本サービスの利用停止処分が下った場合</p>
    <p >④ その他、当社が必要と判断した場合</p>
    <p >3. 当社は、本サービスの利用の停止、または本登録者資格の抹消を行う場合、第1項第5号に該当すると当社が判断する場合を除き、各処分後、当該本登録者へ通知するよう努めるものとします。 </p>
    <p class="indent">第10条 本サービスに関する通知方法</p>
    <p >1. 当社は、本サービスに関連して利用者に通知をする場合には、本サイトへ掲示するものとします。さらに、当社は、本登録者に対しては、上記本サイトへの掲示による方法のほか、当該利用者が本サービスに登録した電子メールアドレス宛へ電子メールを送信する等、その他当社が適当と判断する方法で通知することができるものとします。</p>
    <p >2. 当社は、本登録者により登録された電子メールアドレスに宛てた電子メールが何らかの事情で当該本登録者に届かなかった場合であっても当該通知内容が当該本登録者に通知されたものとみなすこととします。なお、この場合、当社は、当該本登録者が本サービスにおいて当該電子メールアドレスへの配信を希望している各情報について、以後の配信を停止できるものとします。</p>
    <p >3. 本登録者が当社からの電子メールを受信できなかったこと、および当社が本登録者への電子メールの配信を停止したことにより、本登録者に損害が生じたとしても、当社は一切の責任を負いません。 </p>
    <p class="indent">第11条 本サービスの利用</p>
    <p >1. 利用者は、各規約とその他当社が随時通知する内容に従い、各規約および別途当社が指定する条件、内容および方法等の範囲内で、本サービスを利用するものとします。</p>
    <p >2. 利用者は、本サービスの利用および本サービスを通じて送受信する情報につき一切の責任を負うものとし、当社に何等の迷惑または損害を与えないものとします。</p>
    <p >3. 利用者は、本サービスの利用に関連して、利用者と他の利用者または第三者との間でトラブル、紛争等が生じた場合、自己の責任と費用負担で解決するものとします。 </p>
    <p class="indent">第12条 本サービスの変更、中断</p>
    <p >1. 当社は、利用者に通知することなく、本サービスの内容や仕様を変更できるものとします。</p>
    <p >2. 当社は、利用者に通知することなく、本サービスの機能を追加、変更、廃止できるものとします。</p>
    <p >3. 当社は、以下のいずれかに該当する場合、利用者に通知することなく、本サービスの提供を中断できるものとします。</p>
    <p >① 本サービスに必要な機器、システムの保守点検と更新を行う場合</p>
    <p >② 本サービスでシステム障害が発生した場合</p>
    <p >③ 火災、停電、天変地変等の不可抗力によって本サービスの提供が困難な場合</p>
    <p >④ その他、不測の事態により、当社が本サービスの提供が困難と判断した場合</p>
    <p >4. 当社は、本条に基づき当社が行った措置により利用者に生じた損害について一切の責任を負いません。 </p>
    <p class="indent">第13条 利用環境の準備</p>
    <p >1. 当社は、利用者の承諾を得ることなく、本サービスの利用環境（本サービスを利用可能な環境として当社が指定するものをいいます。以下、単に「利用環境」といいます。）を変更できるものとします。</p>
    <p >2. 利用者は、利用環境に適合するコンピュータ、通信機器、ソフトウェア、インターネットへの接続環境等の必要な設備その他の利用環境を、自己の責任と費用負担で準備するものとします。 </p>
    <p class="indent">第14条 知的財産権の帰属</p>
    <p >1. 企業コンテンツに関わる著作権を含む一切の知的財産権は、当該コンテンツを投稿したご利用企業またはご利用企業に対してライセンスを許諾した者に帰属します。</p>
    <p >2. 当社は企業コンテンツを含む本サービスを通じて提供される情報について統計処理した結果にかかる表現その他の情報等について著作権を含む一切の知的財産権を保有するものとします。</p>
    <p >3. 企業コンテンツを除く本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者（ライセンサー）に帰属しています。各規約に基づく本サービスの利用許諾は、本サービスに関する当社またはライセンサーの知的財産権の使用許諾を意味するものではありません。 </p>
    <p class="indent">第15条 禁止事項</p>
    <p >利用者は、本サービスの利用に当たり、以下の行為を行ってはならないものとします。</p>
    <p >① 各規約に違反する行為</p>
    <p >② 利用登録の際をはじめその他一切の場合において、当社へ申告する情報を偽る行為または不備のある情報を申告する行為</p>
    <p >③ 他者の知的財産権、肖像権、プライバシー、名誉、信用等その他一切の権利または法的に保護される利益を侵害する行為</p>
    <p >④ 当社または第三者になりすます⾏為、当社または第三者との提携、協⼒関係の有無を偽る⾏為</p>
    <p >⑤ 公序良俗に反する行為（猥褻または暴⼒的なメッセージ・画像・映像・⾳声等を投稿、送信、掲⽰、発信する⾏為等を含みますが、これらに限られません）</p>
    <p >⑥ 犯罪的行為または犯罪的行為に結びつく行為</p>
    <p >⑦ 本サービスにおいて事実に反した情報または架空の情報を提供する行為</p>
    <p >⑧ 本サービスの運営を妨げる行為</p>
    <p >⑨ 本サービスの利用に関連して、誹謗、中傷、脅迫、威嚇、悪態等、利用者としてふさわしくない言動や行為</p>
    <p >⑩ 当社や本サービスの信用等を毀損する行為</p>
    <p >⑪ 当社の許可なく行われる、選挙の事前運動、選挙運動またはこれらに類似する行為</p>
    <p >⑫ 当社の許可なく行われる、宗教活動、団体への勧誘行為</p>
    <p >⑬ 当社の許可なく行われる、広告、営業活動、営利を目的とした利用またはその準備をする行為</p>
    <p >⑭ 第三者の個⼈情報を収集、蓄積する⾏為</p>
    <p >⑮ アカウント等を第三者に譲渡もしくは貸与し、またはその他不正に使用する行為（第三者のアカウント等を使用して本サービスを利用する行為を含みますが、これに限られません）</p>
    <p >⑯ 迷惑メール、スパムメール等を開⽰、掲載、送信、頒布する⾏為</p>
    <p >⑰ 当社のサーバーに不正アクセスする⾏為</p>
    <p >⑱ コンピュータウイルス等の有害なプログラムやスクリプトを開⽰、掲載、送信、頒布する⾏為</p>
    <p >⑲ 本サービスにおいて使⽤されているソフトウェアまたはデータの全部または⼀部を複製、改変または⼆次利⽤する⾏為</p>
    <p >⑳ 本サービスや本サービスのサーバー等に不正アクセスや大量のパケット送信をする行為</p>
    <p >㉑ その他、法令、政令、省令、規則、行政指導またはガイドライン・業界自主基準等（利用者が所在する国・地域のものを含み、以下「法令等」と総称します。）に違反する行為</p>
    <p >㉒ その他、当社が本サイトにおいて禁⽌を告知した⾏為</p>
    <p >㉓ 前各号の行為を試みる行為、前各号に類する行為</p>
    <p >㉔ 前各号の行為に該当するおそれがあると当社が判断する行為</p>
    <p >㉕ その他、当社が不適切と判断する行為</p> 
    <p class="indent">第16条 免責と損害賠償</p>
    <p >1. 当社は、本サービスの変更、中断、不具合、故障等により、利用者に損害（間接損害、特別損害、付随損害、派⽣損害、逸失利益を含みますがこれらに限られません。以下、本条第４項までにおいて同じ。）が生じたとしても、これについて一切の責任を負いません。</p>
    <p >2. 企業コンテンツはご利用企業の責任により投稿されるものであるため、当社は、企業コンテンツの正確性、確実性、有効性、安全性、適法性等その他あらゆる事項について一切保証しません。また、当該企業コンテンツ等に基づいて被ったとされるいかなる損害についても当社は一切の責任を負いません。</p>
    <p >3. 本サービスにおいて、利用者とその他の第三者（他の利用者を含みます）との間に生じた紛争等に関して、当社は一切の責任を負いません。</p>
    <p >4. 利用者が、各規約に違反してまたはその他本サービスに関して当社に損害を与えた場合には、その一切の損害を直ちに賠償する責任を負うものとします。</p>
    <p >5. 各規約に定める免責条項が適用されない等の理由により、当社が利用者に対して責任を負うべき場合、それらの責任に基づく当社の損害賠償は、利用者に直接かつ現実に生じた通常の損害のみを対象とし、かつ、損害賠償額はいかなる場合でも、10万円（ただし、直近3カ月以内に当社に対する本サービスの利用料金の支払いがある場合において、当該直近3ヶ月以内の支払合計額が10万円を下回るときは当該支払合計額とします。）を上限額とすることに、利用者は予め承諾するものとします。また、本条を含む各規約の定めにかかわらず、法律上、当社が利用者に対して損害賠償責任を負う場合であって、かつ、当該責任の発生が当社の故意または重過失に基づく場合には、当社は、上記の損害および上限額の範囲内で、損害賠償の責任を負うものとします。 </p>
    <p class="indent">第17条 権利義務の譲渡禁止</p>
    <p >利用者は、当社の事前の承諾なく、本規約に基づく契約上の地位を移転し、または本規約により生じた一切の権利義務の全部もしくは一部を、第三者に譲渡し、もしくは第三者の担保に供することはできません。 </p>
    <p class="indent">第18条 準拠法と合意管轄</p>
    <p >1. 各規約は日本法に準拠するものとします。</p>
    <p >2. 各規約または本サービスに関連して利用者と当社の間で問題が生じた場合には、利用者と当社で誠意をもって協議するものとします。</p>
    <p >3. 前項の協議をしても解決せず、訴訟の必要が生じた場合には、訴額に応じて、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p> 
  </div>
</template>
<script>

export default {
  data() {
    return {
    }
  }
}
</script>
<style scoped>
.indent {
  text-indent: 2em;
}
p {
  line-height: .5rem;
  font-size: .3rem;
}
</style>