<template>
  <div class="shopDiv" v-if="dataList && dataList.length > 0">
    <div class="title">
      <div class="distTitle1">{{ $t(`app.${title}`) }}</div>
      <!-- <span @click="toModule">{{ $t("app.More") }}</span> -->
    </div>
    <!-- <div class="distDiv">
      <div
        class="distItem"
        v-for="(item, index) in dataList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="shopInfo">
          <img :src="item.img" class="img" alt="" style="margin-bottom: .1rem"/>
          <div v-if="item.tag && item.tag.length > 0" style="margin-bottom: .1rem">
            <van-tag v-for="(obj, i) in item.tag" :key="i" plain type="primary">{{ obj }}</van-tag>
          </div>
          <div class="shopName">{{ item.name }}</div>
          <div class="loc">{{ item.desc | filtersText }}</div>
        </div>
      </div>
    </div> -->


    <el-carousel :autoplay="false">
      <el-carousel-item v-for="(item, index) in dataArr" :key="index">
        <div class="distDiv">
          <div
            class="distItem"
            v-for="(item1, index1) in item"
            :key="index1"
          >
            <div class="shopInfo">
              <img :src="item1.img" class="img" alt="" style="margin-bottom: .1rem" @click="toDetail(item1)"/>
              <div v-if="item1.tag && item1.tag.length > 0" style="margin-bottom: .1rem;width: 100%;overflow: auto">
                <van-tag v-for="(obj, i) in item1.tag" :key="i" plain type="primary">{{ obj }}</van-tag>
              </div>
              <div v-else class="no_tag"></div>
              <div class="shopName">{{ item1.name }}</div>
              <div class="loc">{{ item1.desc | filtersText }}</div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Vue from "vue";
import { Tag } from "vant";
Vue.use(Tag);

export default {
  props: ["dataList", "title"],
  data() {
    return {
      dataArr: [],
    };
  },
  watch: {
    dataList: {
      handler(dataList) {
        const list = []
        if(dataList.length) {
          dataList.forEach((el, i) => {
            const index = Math.floor(i / 3);
            if(!list[index]) {
              list[index] = [];
            }
            list[index].push(el);
          });
        }
        this.dataArr = list;
      }
    }
  },
  methods: {
    toModule() {
      let name = '', index = 0;
      if(this.title === 'introduction') {
        name = 'b';
        index = '4';
      }
      if(this.title === 'area') {
        name = 'c';
        index = '1';
      }
      if(this.title === 'scenicSpot') {
        name = 'd';
        index = '2';
      }
      if(this.title === 'shop') {
        name = 'e';
        index = '5';
      }
      if(this.title === 'ticket') {
        name = 'f';
        index = '6';
      }
      this.$router.push({
        path: "/result",
        query: {
          name: name,
          index: index,
        },
      });
    },
    toDetail(item) {
      if (item.type <= 5) {
        this.$router.push({
          path: "/essayDetail",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      } else if (item.type == 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      }
    },
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
} 
.shopDiv {
  margin-top: 50px;
  text-align: start;
  color: black;
  padding-bottom: 0.4rem;

  .title {
    display: flex;
    width: 1136px;
    margin: 0 auto 25px;
    justify-content: space-between;
    span {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .distTitle1 {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1px;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    width: 1136px;
    margin: 0 auto;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin-right: 16px;
    width: 368px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    // &:first-child {
    //   margin-left: 0.4rem;
    // }

    // &:last-child {
    //   margin-right: 0.4rem;
    // }

    .shopInfo:hover {
      transform: translateY(-.04rem);
      transition-duration: 0.5s;
    }

    .shopInfo {
      width: 368px;
      display: flex;
      flex-direction: column;
      margin-top: .04rem;
      .shopName {
        background: #fff;
        line-height: 0.35rem;
        width: 368px;
        font-size: 25px;
        text-align: start;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
      }

      .loc {
        background: #fff;
        line-height: 30px;
        width: 368px;
        font-size: 15px;
        text-align: start;
        box-sizing: border-box;

        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        font-weight: 600;
      }

      .img {
        display: flex;
        width: 368px;
        height: 328px;
        object-fit: cover;
        border-radius: .16rem;
      }

      .no_tag {
        height: .45rem;
        font-size: .12rem;
        display: flex;
        align-items: center;
      }
    }
  }
}
/deep/ .van-tag {
  width: fit-content;
  display: inline-block;
  margin-right: .1rem;
  padding: 0.03rem 0.06rem;
  color: #333333;
}
/deep/ .el-carousel  {
  position: relative;
  white-space: nowrap;
  width: 1136px;
  margin: 0 auto;
  .el-carousel__container {
    height: 543px;
  }
}
/deep/ .el-carousel__indicators--horizontal {
  display: none;
}
</style>
