<template>
  <div>
    <Header @goBack="goBack"></Header>
    <Loginlogo></Loginlogo>
    <div class="login">
      <h1 class="login_title">{{ $t("app.reset_pwd") }}</h1>
      <div class="login_input">
        <van-form @submit="onSubmit">
          <p class="label">{{ $t("app.new_pwd") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
              v-model="newpassword"
              type="password"
              name="New password"
              placeholder=""
              :rules="[{ required: true, message: $t('app.Please_pwd') }]"
            />
          </van-cell-group>
          <p class="label">{{ $t("app.new_pwd_confirmation") }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field
              v-model="pwd"
              type="password"
              name="pwd"
              placeholder=""
              :rules="[{ required: true, message: $t('app.Please_newpwd') }]"
            />
          </van-cell-group>
          <div style="margin: 35px 0 45px 0">
            <van-button round block type="info" native-type="submit"
              >Confirm
            </van-button>
          </div>
        </van-form>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import apis from "../../utils/apis";
import Vue from "vue";
import { NavBar, Icon, Form, Field, Button, Toast } from "vant";
import Header from "../../components/Login/Header.vue";
import Footer from "../../components/Login/Footer.vue";
import Loginlogo from "../../components/Login/Loginlogo.vue";
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
let { modifyPwd } = apis;
export default {
  components: {
    Header,
    Footer,
    Loginlogo,
  },
  data() {
    return {
      value1:
        localStorage.getItem("language") == null
          ? "en"
          : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      mail: "1537694643@qq.com",
      pwd: "",
      newpassword: "",
    };
  },
  methods: {
    onSubmit(values) {
      // console.log("submit", values);
      if (this.pwd != this.newpassword) {
        Toast.fail(this.$t('app.restPsw'));
        return;
      }
      let params = {
        mail: localStorage.getItem("email"),
        pwd: this.pwd,
      };
      modifyPwd({ ...params }, (data) => {
        if (data.code == 200) {
          if (data.data.code == 500) {
            Toast.fail(data.data.msg);
            this.$router.push("/register");
            return;
          }
          Toast.success(data.msg);
          this.$router.push("/login");
        }
        // console.log("1111", data);
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
/deep/.van-cell-group--inset {
  margin: 0;
}
/deep/.van-cell {
  padding: 0;
}

/deep/.van-field__body {
  height: 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.1rem;
  padding-left: 0.24rem;
}

.label {
  text-align: left;
}

.van-button {
  background-color: #333;
  border: none;
}
.login {
  padding: 0 0.5rem;

  .login_title {
    font-size: 0.48rem;
    text-align: left;
    margin: 0 0 0.7rem 0;
  }
  .login_describe {
    text-align: left;
    display: block;
    font-weight: bold;
    font-size: 12px;
    margin: 0 0 0.6rem 0;
  }
}
</style>
