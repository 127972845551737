import Vue from 'vue';
import Router from 'vue-router';
import Login from "./pages/Login";
import Forget from "./pages/Login/forgetPwd";
import Code from "./pages/Login/loginCode";
import ResetPwd from "./pages/Login/resetPwd";
import Register from "./pages/Login/register";
import Search from "./pages/search";
import Result from "./pages/search/searchResult";
import Personal from "./pages/personal";
import Order from "./pages/order";
import OrderNumber from "./pages/order/orderNumber";
import OrderDetails from "./pages/order/orderDetails";
import PayResult from "./pages/order/payResult";
import Contact from './pages/contacts/index';
import ContactPc from './pages/contacts/IndexPc';
import SearchClassify from "./pages/search/searchClassify";
import VideoList from "./pages/videoList";
import BaseTerms from "./pages/baseTerms";
import SpecificCommercialTransactionsActInformation from "./pages/specificCommercialTransactionsActInformation";
import LineCallBack from "./pages/line/callback";
import NoticeList from "./pages/notice";
import AreaList from "./pages/areaList";
import searchFresh from "./pages/search/serachFresh";

Vue.use(Router);

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [

    {
        path: '*',
        redirect: '/homePage'
    },
    {
        name: 'homePage',
        component: () => import('./pages/homePage/homePage'),
        meta: {
            title: 'GotoJapan MediaBank'
        }
    },
    {
        name: 'detailsPage',
        path: '/detailsPage',
        component: () => import('./pages/homePage/detailsPage'),
        meta: {
            title: 'PC_Details'
        }
    },
    {
        name: 'essayDetail',
        path: '/essayDetail',
        component: () => import('./pages/homePage/essayDetail'),
        meta: {
            title: 'PC_EssayDetail'
        }
    },
    {
        name: 'myOrderDetails',
        component: () => import('./pages/homePage/myOrderDetails'),
        meta: {
            title: 'PC_Order Details'
        }
    },
    {
        name: 'orderPaynow',
        component: () => import('./pages/homePage/orderPaynow'),
        meta: {
            title: 'PC_Order Details'
        }
    },
    {
        path: '/login',
        name: '/login',
        component: Login,
        meta: {
            title: 'PC_Login'
        }
    },
    {
        path: '/forget',
        name: '/forget',
        component: Forget,
        meta: {
            title: 'PC_Forget'
        }
    },
    {
        path: '/code',
        name: '/code',
        component: Code,
        meta: {
            title: 'PC_Code'
        }
    },
    {
        path: '/repwd',
        name: '/repwd',
        component: ResetPwd,
        meta: {
            title: 'PC_ResetPwd'
        }
    },
    {
        path: '/register',
        name: '/register',
        component: Register,
        meta: {
            title: 'PC_Register'
        }
    },
    {
        path: '/search',
        name: '/search',
        component: Search,
        meta: {
            title: 'PC_Search'
        }
    },
    {
        path: '/result',
        name: '/result',
        component: Result,
        meta: {
            title: 'PC_Result'
        }
    },
    {
        path: '/personal',
        name: '/personal',
        component: Personal,
        meta: {
            title: 'PC_Personal'
        }
    },
    {
        path: '/order',
        name: '/order',
        component: Order,
        meta: {
            title: 'PC_Order'
        }
    },
    {
        path: '/orderNum',
        name: '/orderNum',
        component: OrderNumber,
        meta: {
            title: 'PC_OrderNumber'
        }
    },
    {
        path: '/orderXq',
        name: '/orderXq',
        component: OrderDetails,
        meta: {
            title: 'PC_OrderDetails'
        }
    },
    {
        path: '/payResult',
        name: '/payResult',
        component: PayResult,
        meta: {
            title: 'PC_PayResult'
        }
    },
    {
        path: '/linecallback',
        name: '/linecallback',
        component: LineCallBack,
        meta: {
            title: 'Line Login'
        }
    },
    {
        path: '/contacts',
        name: '/contacts',
        component: Contact,
        meta: {
            title: 'PC_Contact'
        }
    },
    {
        path: '/contactsPc',
        name: '/contactsPc',
        component: ContactPc,
        meta: {
            title: 'ContactPc'
        }
    },
    {
        path: '/searchClassify',
        name: '/SearchClassify',
        component: SearchClassify,
        meta: {
            title: 'PC_SearchClassify'
        }
    },
    {
        path: '/videoList',
        name: '/videoList',
        component: VideoList,
        meta: {
            title: 'PC_VideoList'
        }
    },
    {
        path: '/BaseTerms',
        name: '/BaseTerms',
        component: BaseTerms,
        meta: {
            title: "PC_BaseTerms"
        }
    },
    {
        path: '/SpecificCommercialTransactionsActInformation',
        name: '/SpecificCommercialTransactionsActInformation',
        component: SpecificCommercialTransactionsActInformation,
        meta: {
            title: "SpecificCommercialTransactionsActInformation"
        }
    },
    {
        path: '/noticeList',
        name: '/noticeList',
        component: NoticeList,
        meta: {
            title: "PC_NoticeList"
        }
    },
    {
        path: '/areaList',
        name: '/areaList',
        component: AreaList,
        metaL: {
            title: "AreaList"
        }
    },
    {
        name: 'blockDetail',
        path: '/blockDetail',
        component: () => import('./pages/homePage/blockDetail'),
        meta: {
            title: 'PC_BlockDetail'
        }
    },
    {
        path: '/searchFresh',
        name: '/searchFresh',
        component: searchFresh,
        meta: {
            title: 'PC_Result'
        }
    },
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
    routes, mode: 'history',
});

router.beforeEach((to, from, next) => {
    if (from.fullPath !== '/') {
        localStorage.setItem("beforeRouter",from.fullPath);
    }
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export {
    router
};
