<template>
  <div>
    <!-- <Header @goBack="goBack"></Header> -->
    <Loginlogo></Loginlogo>
    <div class="login">
      <h1 class="login_title">{{ $t("app.Welcome_back") }}</h1>
      <div class="login_input">
        <div class="line">{{ $t('app.line_login') }}</div>
        <div class="line_hover" @click="loginLine()"></div>
        <van-icon name="envelop-o" />
        <div id="email_login" class="email" @click="activeName === '1' ? activeName = null : activeName = '1' ">{{ $t('app.email_login') }}</div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <van-form ref="vForm" @submit="onSubmit">
              <p class="label">{{ $t("app.Email_address") }}</p>
              <van-cell-group inset style="margin-bottom: 2%">
                <van-field
                  :rules="[
                    {
                      required: true,
                      message: $t('app.Email_required'),
                      trigger: 'onBlur',
                    },
                    {
                      validator: (value) => {
                        return /.+@.+\..+/.test(value);
                      },
                      message: $t('app.Email_required_must'),
                      trigger: 'onBlur',
                    },
                  ]"
                  name="value"
                  v-model="inputvalue"
                  placeholder=""
                  id="ipt_email"
                />
              </van-cell-group>
              <p class="label">{{ $t("app.Password") }}</p>
              <van-cell-group inset style="margin-bottom: 2%">
                <van-field
                  type="password"
                  :rules="[{ required: true, message: $t('app.enter_pwd') }]"
                  name="value"
                  v-model="password"
                  placeholder=""
                  focus
                  id="ipt_pwd"
                />
              </van-cell-group>
              <span class="forgot_password" @click="forgetVisible = true">{{
                $t("app.Forgot_password")
              }}</span>
              <div style="margin-top: 30px">
                <van-button id="submit_login" round block type="info" native-type="submit"
                  >{{ $t("app.Sign_in") }}
                </van-button>
              </div>
            </van-form>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- <div class="thrid_login_div">
        <div @click="loginLine()" >
          <img class="item" src="../../assets/LINE_Brand_icon.png"/>
        </div>
      </div> -->
      <divider :type="0"></divider>
      <div class="connect">
        <p class="medin_ban" @click="registerVisible = true">{{ $t("app.medin_bank") }}</p>
        <p class="cookie_state">{{ $t("app.cookie_state") }}</p>
      </div>
    </div>
    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="forgetVisible"
      @close="resetFrom"
    >
      <forget-psw-dialog :preview="forgetVisible" @close="forgetClose" />
    </el-dialog>

    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="registerVisible"
      @close="resetFrom"
    >
      <register-dialog :preview="registerVisible" @close="registerVisible = false" />
    </el-dialog>
  </div>
</template>
<script>
import apis from "../../utils/apis";
import Vue from "vue";
import { NavBar, Icon, Form, Field, Button, Divider, Toast } from "vant";
import Header from "./Header.vue";
import divider from "./divider.vue";
import Loginlogo from "./Loginlogo.vue";
import ForgetPswDialog from './ForgetPswDialog.vue';
import RegisterDialog from "./RegisterDialog.vue";

Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Toast);
let { login } = apis;
let { getLineUrl } = apis

export default {
  props: ['preview'],
  components: {
    Header,
    divider,
    Loginlogo,
    ForgetPswDialog,
    RegisterDialog,
  },
  data() {
    return {
      activeName: null,
      callbackUrl: '',
      forgetVisible: false,
      registerVisible: false,
      value1:
        localStorage.getItem("language") == null
          ? "en"
          : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      inputvalue: "",
      password: "",
      telRules: [
        {
          required: true,
          message: "E-mail is required",
          trigger: "onBlur",
        },
        {
          // 自定义校验规则
          validator: (value) => {
            return /.+@.+\..+/.test(value);
          },
          message: "E-mail must be valid",
          trigger: "onBlur",
        },
      ],
    };
  },

  watch: {
    preview: {
      handler(val) {
        if(val) {
          this.$nextTick(() => {
            this.$refs.vForm.resetValidation();
            this.password = '';
            this.inputvalue = '';
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    loginLine(){
      let path = window.location.pathname;
      let search = window.location.search;
      this.callbackUrl = path+search
      this.callbackUrl = this.callbackUrl.substring(1);
      this.callbackUrl = this.callbackUrl.replace('&','||')
      let param = {
        endPath:this.callbackUrl
      };
      getLineUrl(param,data=>{
        window.location.href = data.data
      })
    },
    forgetClose(code) {
      this.forgetVisible = false;
      if(code === 500) {
        this.registerVisible = true;
      }
    },
    resetFrom() {
      this.$refs.vForm.resetValidation();
      this.inputvalue = '';
      this.password = '';
    },
    toJoin() {
      this.$router.push("/register");
    },
    onSubmit(values) {
    //   console.log("submit", values);
      let params = {
        pwd: this.password || localStorage.getItem("email"),
        mail: this.inputvalue,
      };
      login({ ...params }, (data) => {
        // console.log(data, "datadatadatadatadata");
        if (data.code == 200) {
          Toast.success(data.msg);
          this.$emit('loginSuccess')
        //   this.$router.replace({
        //     path: "/homePage",
        //   });
        }
        window.location.reload()
      });
    },
    forget() {
      this.$router.push("/forget");
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped lang="less">
.thrid_login_div{
  margin-top: 0.27rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .item{
    width: .8rem;
    height: .8rem;
    padding: 0.27rem;
  }
}

.login {
//   padding: 0 0.5rem;
  .login_title {
    text-align: left;
    margin: 30px 0;
    color: #000000;
  }

  .line_hover {
    height: .5rem;
    background: #000000;
    opacity: 8%;
    margin-top: -.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
  }
  .line {
    height: .5rem;
    color: #FFFFFF;
    background: #06C755;
    line-height: .5rem;
    text-align: center;
    border-radius: 0.5rem;
  }
  .line_hover:hover {
    opacity: 10%;
  }
  .line_hover:active {
    opacity: 30%;
  }
  .email {
    height: .5rem;
    border: 2px solid #000000;
    line-height: .5rem;
    border-radius: .5rem;
    color: #000000;
    text-align: center;
    cursor: pointer;
  }
  .van-icon {
    top: .42rem;
    left: .2rem;
    font-size: .3rem;
    color: #000000;
  }
}
/deep/ .van-button--block {
    width: 322px;
    margin: 0 auto;
}
/deep/ .van-cell-group--inset {
  margin: 0;
}
/deep/ .van-cell {
  padding: 0;
}
/deep/.van-field__body {
//   height: 0.8rem;
  height: 47px;
  border: 3px solid #e0e0e0;
//   border-radius: 0.16rem;
  border-radius: 8px;
  padding-left: 0.24rem;
}
.label {
  display: block;
  margin: 20px 0 15px 0;
  text-align: left;
  font-size: 18px;
  font-weight: 540;
}
.forgot_password {
  display: block;
  margin: 20px 0 15px 0;
  text-align: left;
  font-size: 18px;
  font-weight: 540;
  width: 70%;
  text-decoration: underline;
  cursor: pointer;
}
.van-button {
  background-color: #000000;
  border: none;
  height: 54px;
}
.van-button--normal {
  font-size: 17px;
  font-weight: 600;
}
.connect {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .medin_ban {
    margin: 0;
    font-size: 17px;
    font-weight: bold;
    cursor: default;
    width: 340px;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
  }
  .cookie_state {
    font-size: 15px;
    font-weight: bold;
    width: 280px;
    margin-top: 30px;
    line-height: 20px;
    text-align: center;
  }
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 32px;
}
/deep/ .el-dialog--center{
  border-radius: 8px;
}
/deep/ .el-collapse {
  border-top: none;
  border-bottom: none;
  .el-collapse-item__header {
    display: none;
  }
}
</style>
