<template>
  <div class="homeImg">
    <img :src="src" alt="" srcset="" >
  </div>
</template>

<script>
export default {
  props:['src']
}
</script>

<style scoped lang="less">
.homeImg{
    height: 498px;
    width:560px;
     img{
        display: block;
        width: 100%;
        height:100%;
        object-fit: scale-down;
     }
}
</style>