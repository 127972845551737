import { render, staticRenderFns } from "./orderBk.vue?vue&type=template&id=7a8df0a3&scoped=true&"
import script from "./orderBk.vue?vue&type=script&lang=js&"
export * from "./orderBk.vue?vue&type=script&lang=js&"
import style0 from "./orderBk.vue?vue&type=style&index=0&id=7a8df0a3&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a8df0a3",
  null
  
)

export default component.exports