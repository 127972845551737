<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ dataList[0].columnName }}</div>
    </div>
    <div class="distDiv" v-if="dataList.length > 0">
      <el-carousel :interval="3000" arrow="never" type="card" indicator-position="none">
        <el-carousel-item v-for="item in dataList" v-bind:key="item.columnImageUrl">
          <img :src="item.columnImageUrl" class="car-img" @click="toDetail(tab, item)"/>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    dataList: {
      type: Array,
    },
  },
  data() {
    return {}
  },
  methods: {
    toDetail(tab, item) {
      if(tab < 5) {
        this.$router.push({
          path: "/essayDetail",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else if (tab == 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      }
    },
  }
}
</script>

<style scoped lang="less">
.districtDiv {
  text-align: start;
  width: 1136px;
  margin: 0 auto;

  .flow-row {
    margin-top: 0;
  }

  .distTitle1 {
    color: black;
    font-size: .20rem;
    font-weight: 600;
    margin: 18px 0;
  }

  .distTitle2 {
    color: #707070;
    font-size: .16rem;
    padding: 12px 0;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .distItem:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin-right: 16px;
    width: 272px;
    height: 270px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .imgDiv {
      position: relative;
      width: 272px;
      border-radius: .16rem;

      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.6) 75%,rgba(0, 0, 0,.0) 100%);
        width: 100%;
        color: white;
        text-align: start;
        padding: .08rem .18rem;
        box-sizing: border-box;
        min-width: 0;
        border-bottom-left-radius: .16rem;
        border-bottom-right-radius: .16rem;

        .desc {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:first-child {
            font-size: .2rem;
          }

          &:last-child {
            font-size: .15rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
            overflow: hidden;
            margin-top: .1rem;
            min-height: 0.34rem;
          }
        }
      }
    }
  }
}
.car-img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: .16rem;
}
.is-in-stage {
  cursor: grab !important;
}
/deep/ .el-carousel__container {
  height: 300px;
}

.el-carousel__item.is-active {
  height: 90%;
  top: 5%;
}

//.el-carousel__item.is-active::before {
//  content: "";
//  position: absolute;
//  top: 0;
//  height: 100%;
//  width: 30%;
//  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0));
//}
//
//.el-carousel__item.is-active::after {
//  content: "";
//  position: absolute;
//  right: 0;
//  top: 0;
//  height: 100%;
//  width: 30%;
//  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
//}
</style>
