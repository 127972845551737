export default {
    app: {
        hello: '你好,世界!!!!!!!',
        cn: '中文（简体)',
        hk: '中文（繁体)',
        en: 'English',
        jp: '日本語',

        language: '語言',
        signIn: '登錄',
        personal: '個人主頁',
        editProfile: '修改資料',
        orderList: '預訂清單',
        changePwd: '更改密碼',
        personalInformation:'個人信息',
        logOut: '登出',
        collectionList: '收藏清單',
        register: '注册',

        search: '検索',

        introduction: '攻略',
        area: '地區',
        scenicSpot: '景點',
        shop: '店鋪',
        ticket: '票券',

        whereTo: '去哪',

        enterBasicInfo: '基本資訊填寫',
        userName: '用戶名',
        submit: '提交',

        all_area: '全部地區',
        other_area: '其他地區',
        recommendedScenicSpots: '推薦景點',

        Welcome_back: '歡迎回來',
        Email_address: '電子郵件地址',
        Password: '密碼',
        Forgot_password: '忘記密碼?',
        restPsw: '兩次密碼不一致',
        Sign_in: "登錄",
        line_login: '使用LINE登錄',
        email_login: '使用電子郵件登錄',
        Not_a_member: "還不是會員?",
        medin_bank: "加入以解鎖Media Bank Inc的最佳服務",
        cookie_state: "通過繼續，您同意我們的使用條款，並確認您已閱讀我們的隱私和Cookie聲明",
        forget_pwd: "忘記密碼?",
        enter_your_pwd: "沒問題。只需在下面輸入您的電子郵件地址 - 我們將向您發送重置密碼的代碼。",
        login_code: "請輸入驗證碼",
        code: "驗證碼",
        reset_pwd: "重置密碼",
        new_pwd: "新密碼",
        new_pwd_confirmation: "確認新密碼",
        nike_name: "暱稱",
        Create_Account: "創建帳戶",
        create_pwd: "創建密碼",
        displayed_title: "僅顯示Media Bank的預訂。",
        All: "全部",
        Success: "成功",
        Failure: "失敗",
        Processing: "處理中",
        Forest_zoo: "森林動物園",
        Business_Hours: "營業時間",
        Address: "地址",
        Notice: "注意事項",
        Peripheral_Selection: "周邊選擇",
        Explore_now: "立即探索",
        space_for_lese: "廣告位出租",
        we_got: "我們提供您旅遊日本所需的一切",
        Guideline: "攻略",
        Area: "地區",
        Scenic_spot: "景點",
        Shop: "店鋪",
        Ticket: "門票",
        topResult: "在所有類別中匹配 “{key}” 的前幾個結果",
        topResult1: "在所有類別中匹配的前幾個結果",
        collectResult: "在所有收藏中匹配 “{key}” 的前幾個結果",
        collectResult1: "在所有收藏中匹配的前幾個結果",
        All_Category: "在{value}中的前幾個結果",
        book: "預訂",
        Zoo_ticket: "動物園門票",
        Products: "符合要求的產品",
        Ticket_type: "門票類型",
        Children: "兒童",
        Date_selection: "日期選擇",
        Purchase_quantity: "購買數量",
        Next: "下一步",
        pay_successful: "支付成功，請等待門票發放",
        ticket_issued_successful: "出票已成功。請檢查您提供的電子郵件以獲取詳細信息",
        Complete: "完成",
        My_Order: "我的訂單",
        need_people: "需要填寫2人的信息",
        Traveler: "旅行者 ",
        Traveler_wo: "旅行者2",
        Contact_person: "联系人",
        Surname: "姓 ：",
        Given_name: "名 ：",
        Full_name: "姓名 ：",
        Phone: "電話號碼+86 ：",
        Email: "電子郵件 ：",
        Please_surname: "請填寫您的姓氏",
        Please_name: "請填寫名稱",
        Please_number: "請輸入您的手機號碼",
        Please_email: "請填寫電子郵件",
        Email_required: "電子郵件必填",
        Email_required_must: "電子郵件必須有效",
        enter_pwd: "輸入一個用戶密碼",
        UserName_required: '用戶名是必填項',
        Address_required: '地址是必填項',
        verification_code: "請輸入驗證碼",
        Please_pwd: "請填寫新密碼",
        Please_newpwd: "請再次填寫新密碼",
        There_is_present: "目前沒有此類型的訂單",
        To_explore: "探索",
        money: "￥",
        bookNow: "現在預定",
        Traveler_Details: "旅行者詳細資訊",
        No_data:"暫無數據",
        Required:"需要填寫",
        human:"人資訊",
        Order_Status:"訂單狀態",
        Payment_Status:"付款狀態",
        Type:"類型",
        Date:"日期",
        Quantity:"量",
        reviews:"流覽量",
        TrendingTravel:"旅行趨勢",
        Places_Go:"要去的地方",
        Adventure:"探險之旅",
        about:'關於',
        Rating:"評估",
        DateList:"獲取價格行事曆失敗",
        calendarConfirmText: '確定',
        noInventory: '暫無庫存',
        change_language_null_tips: '所選語言下沒有數據，是否返回上一頁面',
        data_null_tips: '暫時沒有數據',

        noticeList: '消息清單',
        noticeDetail: '消息詳情',
        noticeTitle: '消息標題：',
        sendTime: '發佈時間：',
        noticeContent: '消息內容：',
        read: '已讀',
        unRead: '未讀',
        delete: '删除',
        cancel: '取消',
        allNotice: '共{total}條消息',
        allUnRead: '共{total}條未讀消息',
        allRead: '共{total}條已讀消息',


        productDescription: '產品描述',
        detailsIntro: '詳情介紹',
        reserveNotice: '預定說明',
        productUseFlow: '【使用流程】',
        productLanguage: '【服務語言】',
        productAudienceAsk: '【產品人群要求】',
        travelDuration: '【行程時長】',
        groupSize: '【成團人數】',
        proAddress: '【地址】',
        businessHours: '【營業時間說明】',
        productCostInclude: '【費用包含】',
        productCostExclusive: '【費用不包含】',
        advanceDays: '【提前天數】',
        confirmWay: '【確認管道】',
        effectiveDate: '【有效日期】',
        refundInstructions: '【退款說明】',
        predetermineNotice: '【預定須知】',
        automaticConfirm: '自動確認',
        secondaryConfirm: '二次確認',
        English: '英文',
        Chinese: '中文',
        Japanese: '日文',
        Korean: '韓文',
        advanceDaysText: '{day}天前的{time}之前預定',
        orderValidText0: '選擇的遊玩日期當前有效',
        orderValidText1: '預定日期延後{day}天有效',
        orderValidText2: '預訂日期截止到{date}有效',
        orderValidText3: '選定日期延後{day}天有效',
        orderValidText4: '選定日期截止到{date}有效',
        orderValidText5: '{startDate}到{endDate}内有效',
        orderValidText6: '指定使用日期的{startDay}前到指定日期的{endDay}後為止有效',

        About_MEDIA_BANK: '關於Media Bank',
        MEDIA_BANK_Sites: 'Media Bank網站',
        Privacy: '隱私和Cookie聲明',
        Contactus: '聯繫我們',
        termsOfUse: '使用條款',

        howToContact: '如果您遇到任何問題，請使用以下方法聯繫我們：',
        tel: '電話',
        mail: '郵件',
        translate: '中國語(繁体)翻譯',
        translateFrom: '中國語(繁体)的翻譯',
        originalContent: '顯示原文',
        translateContent: '譯文',

        exploreNow: '立即瀏覽',
        exploreAll: '瀏覽全部',

        thingsToDoIn: '做點什麼在',
        thingsToDo: '做點什麼',

        OrderDetails: '訂單詳情',
        orderExcption: '訂單數據异常',

        total: '合計',
        Contact_information: '連絡人資訊',
        name: '姓名:',
        eMail: '郵箱:',
        More_Detail: '更多',
        More: '更多',
        System_Order_ID: '系統訂單號:',
        Payment_Order_ID: '付款訂單號:',
        Ticket_Order_ID: '票務訂單號:',
        System_Order_Status: '系統訂單狀態:',
        Ticket_PDF_Url: '票據PDF:',

        homeTitle1: '遊覽日本的方式',
        homeSubtitle1: '旅行者必去之地排行榜',
        homeTitle2: '店鋪',
        homeSubtitle2: '預訂這些體驗，近距離瞭解日本.',
        homeTitle3: '推薦目的地',
        homeSubtitle3: '旅行者必去之地排行榜',
        homeTitle4: '特色門票',
        homeSubtitle4: '預訂這些體驗，近距離瞭解日本.',
        homeTitle5: '旅行故事',
        homeSubtitle5: '人氣觀光景點、美食信息、示範路綫等的介紹.',

        priceVaries: '價格因集團規模而异',

        joininMEDIN_BANK: '使用您的Media Bank Inc帳戶登入.',
        Already_a_member: '已經是會員?',

        secureCheckout: '安全結帳',
        secureCheckoutText: '安全結帳 - 只需要幾分鐘',
        PayNow: '立即支付',
        trade_tips:'請在兩分鐘內完成支付，如果中途中斷支付訂單會被取消。',
        paymentProtection: '支付保護',
        non_refundable: '不可退款',
        minimum_order: '一張起購',

        liaisonDetail: '聯絡詳情',
        customerName: '姓名',
        customerTelephone: '手機',
        customerEmail: '郵箱',
        customerWechat: '微訊號',
        customerSex: '性別',
        man: '男',
        woman: '女',
        customerUseDate: '使用日期',
        customerCode: '郵編',
        customerAddress: '住址',
        customerCreditType: '證件類型',
        customerCreditNo: '證件號',
        customerReceiveDate: '領取日期',
        customerNameRequired: '請輸入姓名',
        customerTelephoneRequired: '請輸入手機號',
        customerWechatRequired: '請輸入微訊號',
        customerPassportRequired: '請輸入護照',
        customerSexRequired: '請選擇性別',
        customerUseDateRequired: '請選擇使用日期',
        customerCodeRequired: '請輸入郵編',
        customerAddressRequired: '請輸入住址',
        customerCreditTypeRequired: '請選擇證件類型',
        customerCreditNoRequired: '請輸入證件號',
        customerReceiveDateRequired: '請選擇領取日期',
        pleaseEnter: '請輸入',
        reservation: '預定須知',
        identityCard: '身份證',
        studentIDCard: '學生證',
        militaryIDCard: '軍官證',
        passport: '護照',
        residenceBooklet: '戶口本',
        hongKongAndMacaoPass: '港澳通行證',
        taiwanSyndrome: '臺胞證',

        aboutPlace: '關於',
        aboutJTA:'關於 Japan Tourism Agency',
        aboutJED:'關於 \"Japan. Endless Discovery.\"logo',

        tickets:'張',
        tickets_s:'張',

        videoList:'推薦視頻',
        videoList_sub:'分享大家喜歡的',
        allVideos: '全部視頻',

        readMore: '更多',
        Check_availability: '價格日曆',
        copyright:'Copyright © 2023 Media Bank Inc. All rights reserved.',

        plz_login: '請先登錄系統',
        showAllAreaContent: '查看地區相關全部內容',

        emailEntryHint: '該郵箱將用於接收電子票憑證，請認真填寫。 如填寫錯誤將無法獲取電子票。',
        auth_loading:'授權中，請稍後...',
        auth_failed:'授權失敗，請稍後重試。',
        back:'返回',
        pay_failed:'支付失敗，請檢查您輸入的支付信息是否正確',
        verygood:'非常棒',
        createdOrderFailed: '訂單創建失敗',

        website:'網址',
        position:'位置',
        go_order:'去點單',
        noOperation: '不營業',
        businessWeeks:{ week1:'星期一',week2:'星期二',week3:'星期三',week4:'星期四',week5:'星期五',week6:'星期六',week7:'星期日'},
        store_operation:'營業時間',
        store_introduction:'店鋪介紹',
        store_Information:'店鋪博客',
        store_periphery:'店鋪周邊',
        store_comment:'店鋪評價',
        store_comment_addBtn:'評價',
        store_comment_placeholder: '請輸入評價内容',
        store_comment_title: '用戶評價',
        store_comment_null: '評價内容不能爲空',
        store_comment_success: '評價成功',
        store_comment_del: '刪除成功',
        confirm: '確認',
        store_comment_delTips: '是否確認刪除該評價',
        copy_div:"已經複製到剪切板",
        copy_entry_div:"該店鋪沒有相關資訊",

        fresh_news_title:"新鮮事",
        fresh_news_subtitle:"店鋪熱搜諮詢",
        fresh_news_detail_title:"熱搜諮詢",

        new_nike_name:'新昵稱',
        Please_new_nike_pwd: "請填寫昵稱",
        user_pic:"頭像",
        user_info:'個人資料',

        image_require: '請上傳圖片文件',
        image_max_2: '圖片大小不能超過 2MB!',

        plan_go: '計劃去',
        user_plan_go: ' 人計劃去哦！',
        plan_added: '已添加計劃',
        come_plan: '暫無人計劃去，快來加入吧！',
        more_plan: '更多 ...',
        add_plan_success: '已添加計劃',
        cancel_plan_success: '取消成功',

        pop_introduction_recommend: "熱門攻略推薦",
        pop_area_recommend: "熱門地區推薦",
        pop_scenicSpot_recommend: "熱門景點推薦",
        pop_shop_recommend: "熱門店鋪推薦",
        guess_you_like: "猜你喜歡",
        everyone_plans_go: "大家計畫去",
        introduction_list: "攻略清單",
        introduction_collect_list: "攻略收藏清單",
        area_list: "地區清單",
        area_collect_list: "地區收藏清單",
        scenicSpot_list: "景點清單",
        scenicSpot_collect_list: "景點收藏清單",
        shop_list: "店鋪清單",
        shop_collect_list: "店鋪收藏清單",
        ticket_list: '票券清單',
        ticket_collect_list: '票券收藏清單',

        merchant_enter: '商家入駐',
        discount_price: '折扣價',


        SCTAI_title:'特定商業交易法資訊 ',
        SCTAI_col1_title:'營運主體',
        SCTAI_col1_val:'株式會社 Media Bank ',
        SCTAI_col2_title:'公司所在地',
        SCTAI_col2_val:'郵遞區號 105-0001 東京都港區虎之門 1-10-5 KDX 虎之門 1 丁目大樓 11 樓',
        SCTAI_col3_title:'公司代表或營運總監',
        SCTAI_col3_val:'李 婭寅',
        SCTAI_col4_title:'代表者または運営統括責任者（フリガナ）',
        SCTAI_col4_val:'リ アイン',
        SCTAI_col5_title:'聯繫方式/公司主頁',
        SCTAI_col5_val:'https://www.media-bank.co/',
        SCTAI_col6_title:'聯繫方式/e-mail(客服信箱)',
        SCTAI_col6_val:'info@media-bank.info',
        SCTAI_col7_title:'聯繫方式/TEL (客服電話)',
        SCTAI_col7_val:'+81-3-4400-0707',
        SCTAI_col8_title:'聯繫方式/產品網站',
        SCTAI_col8_val:'https://gotoja.media-bank.info/',
        SCTAI_col9_title:'網站名稱',
        SCTAI_col9_val:'日本深度遊',
        SCTAI_col10_title:'商品最低單價',
        SCTAI_col10_val:'1,000 日元',
        SCTAI_col11_title:'商品最大單價',
        SCTAI_col11_val:'50,000 日元 ',
        SCTAI_col12_title:'商品（含服務）交付時間（天數）、發貨方式',
        SCTAI_col12_val1:'【交貨時間】完成支付後立即交付',
        SCTAI_col12_val2:'消費者購買網站商品後，相關電子券將通過電子郵件發送至您註冊的電子郵件地址。',
        SCTAI_col12_val3:'部分商品需要二次確認，具體詳情請參考每個商品的使用說明。',
        SCTAI_col13_title:'貨款支付時間和方式',
        SCTAI_col13_val1:' 支付時間:確認下單後',
        SCTAI_col13_val2:' 支付方式: 網站上展示的所有圖標所代表的支付方式都可以使用。',
        SCTAI_col14_title:'',
        SCTAI_col14_val:'',
        SCTAI_col15_title:'商品（包括服務）價款以外的必要費用/運費、消費稅等',
        SCTAI_col15_val:'與使用互聯網相關的通訊費、支付手續費由顧客承擔。',
        SCTAI_col16_title:'退貨的處理條件/退貨期限、退貨時的運費承擔或者解約和退會條件/解約·退會的規約',
        SCTAI_col16_val:'由於商品特性，本品不能退貨，因此確定購買後無法進行退貨或退款。',
        SCTAI_col17_title:'不良品的處理條件',
        SCTAI_col17_val:'如果本網站提供的兌換券有缺陷，請聯繫我司客服提供無缺陷的兌換券，請注意我司不接受退款。',
        time_out: '請求超時',

        favorite: {
            share: '分享',
            collect: '收藏',
            collected: '已收藏'
        },
        merchant_closed: '不營業'
    }
}
