<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ dataList[0].columnName }}</div>
    </div>

    <div class="distDiv" v-if="nums == 2 && dataList.length > 0">
      <div class="distItem-2" v-for="(item, index) in dataList" :key="index" v-show="index<2">
        <div class="imgDiv" @click="toDetail(tab, item)">
          <img :src="item.columnImageUrl" class="img" alt="" style=""/>
          <div class="title">
            <div class="desc">{{ item.columnRefAdvName }}</div>
            <div class="desc" v-if="item.content != null">{{ item.content | filtersText }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="distDiv" v-else-if="nums == 3 && dataList.length > 0">
      <div class="distItem-3" v-for="(item, index) in dataList" :key="index" v-show="index<3">
        <div class="imgDiv" @click="toDetail(tab, item)">
          <img :src="item.columnImageUrl" class="img" alt="" />
          <div class="title">
            <div class="desc">{{ item.columnRefAdvName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="distDiv" v-else-if="nums == 4 && dataList.length > 0">
      <div class="distItem-4" v-for="(item, index) in dataList" :key="index" v-show="index<4">
        <div class="imgDiv" @click="toDetail(tab, item)">
          <img :src="item.columnImageUrl" class="img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    nums: {
      type: Number,
      default: 0,
    },
    dataList: {
      type: Array,
    }
  },
  data() {
    return {}
  },
  filters: {
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
      }
    },
  },
  methods: {
    toDetail(tab, item) {
      if(tab < 5) {
        this.$router.push({
          path: "/essayDetail",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else if (tab == 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      }
    },
  }
}
</script>

<style scoped lang="less">
.districtDiv {
  text-align: start;
  width: 1136px;
  margin: 0 auto;

  .flow-row {
    margin-top: 0;
  }

  .distTitle1 {
    color: black;
    font-size: .20rem;
    font-weight: 600;
    margin: 18px 0;
  }

  .distTitle2 {
    color: #707070;
    font-size: .16rem;
    padding: 12px 0;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    padding-top: 0.04rem;
  }

  .distItem-2:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem-3:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem-4:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem-2 {
    display: inline-flex;
    background: transparent;
    width: 560px;
    height: 365px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:not(:first-child) {
      margin-left: 16px;
    }

    .imgDiv {
      position: relative;
      width: 560px;
      border-radius: .16rem;
      cursor: grab;

      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.6) 75%,rgba(0, 0, 0,.0) 100%);
        width: 100%;
        color: white;
        text-align: start;
        padding: .08rem .18rem;
        box-sizing: border-box;
        min-width: 0;
        border-bottom-left-radius: .16rem;
        border-bottom-right-radius: .16rem;

        .desc {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:first-child {
            font-size: .15rem;
            font-weight: bold;
          }

          &:last-child {
            font-size: .15rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            white-space: normal;
            overflow: hidden;
            margin-top: .05rem;
            min-height: 0.22rem;
          }
        }
      }

      .img {
        display: flex;
        width: 560px;
        height: 365px;
        object-fit: cover;
        border-radius: .16rem;
      }
    }
  }

  .distItem-3 {
    display: inline-flex;
    background: transparent;
    width: 368px;
    height: 270px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:not(:first-child) {
      margin-left: 16px;
    }

    .imgDiv {
      position: relative;
      width: 368px;
      border-radius: .16rem;
      cursor: grab;

      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.6) 75%,rgba(0, 0, 0,.0) 100%);
        width: 100%;
        color: white;
        text-align: start;
        padding: .08rem .18rem;
        box-sizing: border-box;
        min-width: 0;
        border-bottom-left-radius: .16rem;
        border-bottom-right-radius: .16rem;

        .desc {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:first-child {
            font-size: .2rem;
          }

          &:last-child {
            font-size: .15rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
            overflow: hidden;
            margin-top: .2rem;
            min-height: 0.34rem;
            text-align: center;
          }
        }
      }

      .img {
        display: flex;
        width: 368px;
        height: 270px;
        object-fit: cover;
        border-radius: .16rem;
      }
    }
  }

  .distItem-4 {
    display: inline-flex;
    background: transparent;
    width: 272px;
    height: 270px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:not(:first-child) {
      margin-left: 16px;
    }

    .imgDiv {
      position: relative;
      width: 272px;
      border-radius: .16rem;
      cursor: grab;

      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.6) 75%,rgba(0, 0, 0,.0) 100%);
        width: 100%;
        color: white;
        text-align: start;
        padding: .08rem .18rem;
        box-sizing: border-box;
        min-width: 0;
        border-bottom-left-radius: .16rem;
        border-bottom-right-radius: .16rem;

        .desc {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:first-child {
            font-size: .2rem;
          }

          &:last-child {
            font-size: .15rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
            overflow: hidden;
            margin-top: .2rem;
            min-height: 0.34rem;
            text-align: center;
          }
        }
      }

      .img {
        display: flex;
        width: 272px;
        height: 270px;
        object-fit: cover;
        border-radius: .16rem;
      }
    }
  }
}
.grid-content-2 {
  border-radius: 4px;
  height: 365px;
  width: 100%;
}
.grid-content-3 {
  border-radius: 4px;
  height: 270px;
  width: 100%;
}
.grid-content-4 {
  border-radius: 4px;
  height: 270px;
  width: 100%;
}
</style>
