<template>
    <div class="flow-col">
    </div>
</template>

<script>
import Vue from 'vue';
import { Button, Toast } from 'vant';
import apis,{hostUrl} from "../../utils/apis";

let {getLineAuthTokenAndLogin} = apis

Vue.use(Button);
export default {
    data(){
        return{
            result:{
                idToken:{
                    sub:'',
                    name:'',
                    email:'',
                    picture:''
                },
                endPath:''
            },
            params:'',
            authTokenParam:{
                code:'',
                state:'',
                nonce:'',
                scope :'',
                liffRedirectUri :'',
                error :'',
                errorCoder:'',
                errorMessage :'',
                url :''
            },
        }
    },
    created(){
        let userAgentInfo = navigator.userAgent  
        let Agents = [  'Android',   'iPhone',   'SymbianOS',   'Windows Phone',   'iPad',   'iPod']
        let isPc = true
        for (var v = 0; v < Agents.length; v++) {   
            if (userAgentInfo.indexOf(Agents[v]) > 0) {    
                isPc = false
                break
            }  
        }
        this.params = window.location.search;
        // if(!isPc){
        //     window.location.href = hostUrl+'/linecallback'+this.params;
        // }
        this.authTokenParam.code = this.getParams('code');
        //state`s value means redirect_url paht. the params containded in the path divded by '||'which need to replace by '&'.then append to the hostname.
        this.authTokenParam.state = this.getParams('state');
        this.authTokenParam.nonce = this.getParams('nonce');
        this.authTokenParam.scope = this.getParams('scope');
        this.authTokenParam.liffRedirectUri = this.getParams('liffRedirectUri');
        this.authTokenParam.error = this.getParams('error');
        this.authTokenParam.errorCoder = this.getParams('errorCoder');
        this.authTokenParam.errorMessage = this.getParams('errorMessage');
        getLineAuthTokenAndLogin(this.authTokenParam,data=>{
            const path = localStorage.getItem('lastPath');
            console.info("get lastPath" + path)
            if(path != "" && path != undefined && path != null) {
                window.location.href = path;
            } else {
                if(this.authTokenParam.nonce != null && this.authTokenParam.nonce != ''){
                    this.authTokenParam.nonce = this.authTokenParam.nonce.replaceAll('\|\|','&')
                    window.location.href = hostUrl + this.authTokenParam.nonce
                }else if(this.authTokenParam.state != null && this.authTokenParam.state != ''){
                    this.authTokenParam.state = this.authTokenParam.state.replaceAll('\|\|','&')
                    window.location.href = hostUrl + this.authTokenParam.state
                }else{
                    this.closeTab();
                }
            }
        })
    },
  methods:{
    closeTab(){
        this.$router.go(-1);
    },
    complete(){
        
    },    
    getParams(key) {
        let search = this.params.replace(/^\?/, "");
        let pairs = search.split("&");
        let paramsMap = pairs.map(pair => {
            let [key, value] = pair.split("=");
            return [decodeURIComponent(key), decodeURIComponent(value)];
        }).reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
        return paramsMap[key] || "";
    } ,

  }
}

    

</script>

<style scoped lang="less">
/deep/.van-button--default{
padding: 0 .4rem;
color: #000000;
font-size: .36rem;
border:.02rem solid #c9c9c9;
border-radius: .12rem;
}
.paySuccess {
    position: relative;
    height: 100vh;
    width: 100%;
    // background: url('../../assets/img/paySuccess.png') no-repeat;
    // background-size: contain;
    .pay_box {
        height: 11rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 1.1rem .4rem;
        .pay_logo {
            height: 1.8rem;
            width: 80%;
            object-fit: cover;
            img {
                width: 100%;
                height: 1.5rem;
                object-fit: cover;
            }
        }
        .pay_title{
            font-size: .44rem;
            color: #000000;
            font-weight: bold;
            padding: 0 .8rem;
        }
        .pay_center{
            height: 3.2rem;
            width: 3rem;
            img{
                width: 100%;
            }
        }
        .pay_list{
            display: flex;
            flex-direction: column;
            width: 100%;
            .pay_list_flex{
                display: flex; 
                width: 100%;
                padding: .2rem 0;
                justify-content: space-between;
                border-bottom: .04rem solid #fafafa;
                .pay_lf{
                    font-size: 0.23rem;
                    color: #9fa0a0;
                }
                .pay_rf{
                    font-weight: 540;
                    font-size: 0.23rem;
                    color: #000000;
                }
            }
        }
    }
}
</style>