var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Loginlogo'),_c('div',{staticClass:"login"},[_c('h1',{staticClass:"login_title"},[_vm._v(_vm._s(_vm.$t("app.Create_Account")))]),_c('div',{staticClass:"login_input"},[_c('van-form',{ref:"vForm",on:{"submit":_vm.onSubmit}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("app.nike_name")))]),_c('van-cell-group',{staticStyle:{"margin-bottom":"2%"},attrs:{"inset":""}},[_c('van-field',{attrs:{"name":"value","placeholder":"","rules":[{ required: true, message: _vm.$t('app.Please_name') }],"format-trigger":"onBlur"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("app.Email_address")))]),_c('van-cell-group',{staticStyle:{"margin-bottom":"2%"},attrs:{"inset":""}},[_c('van-field',{attrs:{"rules":[
              {
                required: true,
                message: _vm.$t('app.Email_required'),
                trigger: 'onBlur',
              },
              {
                validator: (value) => {
                  return /.+@.+\..+/.test(value);
                },
                message: _vm.$t('app.Email_required_must'),
                trigger: 'onBlur',
              },
            ],"name":"value","placeholder":""},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}})],1),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("app.create_pwd")))]),_c('van-cell-group',{staticStyle:{"margin-bottom":"2%"},attrs:{"inset":""}},[_c('van-field',{attrs:{"type":"password","name":"password","placeholder":"","rules":[{ required: true, message: _vm.$t('app.enter_pwd') }]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticStyle:{"margin":"36px 0"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('app.register'))+" ")])],1)],1)],1),_c('divider',{attrs:{"type":1}}),_c('div',{staticClass:"connect"},[_c('p',{staticClass:"medin_ban",on:{"click":_vm.toLogin}},[_vm._v(" "+_vm._s(_vm.$t("app.joininMEDIN_BANK"))+" ")]),_c('p',{staticClass:"cookie_state"},[_vm._v(_vm._s(_vm.$t("app.cookie_state")))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }