<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ dataList[0].columnName }}</div>
    </div>
    <div class="distDiv">
      <div class="distItem">
        <div class="distLeft">
          <div class="imgDiv" @click="toDetail(tab, dataList[0].pageType, dataList[0].columnRefId)">
            <img v-if="dataList[0].columnImageUrl !== null" :src="dataList[0].columnImageUrl" class="img" alt="" />
            <div class="title" v-if="dataList[0].columnRefAdvName !== null">
              <div class="desc">
                <img src="../../../assets/img/area.png" class="desc-icon"/>
                {{ dataList[0].columnRefAdvName }}
              </div>
            </div>
          </div>
        </div>
        <div class="distRight">
          <div class="imgDiv" @click="toDetail(tab, dataList[1].pageType, dataList[1].columnRefId)">
            <img v-if="dataList[1].columnImageUrl !== null" :src="dataList[1].columnImageUrl" class="img" alt="" />
            <div class="title" v-if="dataList[1].columnRefAdvName !== null">
              <div class="desc">
                <img src="../../../assets/img/area.png" class="desc-icon"/>
                {{ dataList[1].columnRefAdvName }}
              </div>
            </div>
          </div>
          <div class="imgDiv" @click="toDetail(tab, dataList[2].pageType, dataList[2].columnRefId)">
            <img v-if="dataList[2].columnImageUrl !== null" :src="dataList[2].columnImageUrl" class="img" alt="" />
            <div class="title" v-if="dataList[2].columnRefAdvName !== null">
              <div class="desc">
                <img src="../../../assets/img/area.png" class="desc-icon"/>
                {{ dataList[2].columnRefAdvName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      tab: {
        type: Number,
        default: 0,
      },
      dataList: {
        type: Array,
      }
    },
    data(props) {
      return {}
    },
    methods: {
      toDetail(tab, type, id) {
        if(tab < 5) {
          this.$router.push({
            path: "/essayDetail",
            query: {
              type: type,
              id: id,
            },
          });
        } else if (tab == 5) {
          this.$router.push({
            path: "/detailsPage",
            query: {
              type: type,
              id: id,
            },
          });
        } else {
          this.$router.push({
            path: "/orderXq",
            query: {
              type: type,
              id: id,
            },
          });
        }
      },
    }
  }
</script>

<style scoped lang="less">
.districtDiv {
  text-align: start;
  width: 1136px;
  margin: 0 auto;

  .flow-row {
    margin-top: 0;
  }

  .distTitle1 {
    color: black;
    font-size: .20rem;
    font-weight: 600;
    margin: 18px 0;
  }

  .distTitle2 {
    color: #707070;
    font-size: .16rem;
    padding: 12px 0;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 0.04rem;
  }

  .distItem {
    display: inline-table;
    background: transparent;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .distLeft {
      display: inline-block;
      width: 752px;

      .imgDiv {
        position: relative;
        width: 752px;
        border-radius: .16rem;
        cursor: grab;

        .title {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          background: linear-gradient(to top, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .6) 75%, rgba(0, 0, 0, .0) 100%);
          width: 100%;
          color: white;
          text-align: start;
          padding: .08rem .18rem;
          box-sizing: border-box;
          min-width: 0;
          border-bottom-left-radius: .16rem;
          border-bottom-right-radius: .16rem;

          .desc {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:first-child {
              font-size: .2rem;
            }

            &:last-child {
              font-size: .15rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              white-space: normal;
              overflow: hidden;
              margin-top: .2rem;
              min-height: 0.34rem;
            }

            .desc-icon {
              width: 0.15rem;
              height: 0.15rem;
              position: relative;
              top: 0.02rem;
            }
          }
        }

        .img {
          display: flex;
          width: 752px;
          height: 416px;
          object-fit: cover;
          border-radius: .16rem;
        }
      }
    }

    .distRight {
      display: inline-table;
      width: 368px;
      height: 200px;
      margin-left: 16px;

      .imgDiv {
        position: relative;
        width: 368px;
        border-radius: .16rem;
        cursor: grab;

        &:last-child {
          margin-top: 16px;
        }

        .title {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          background: linear-gradient(to top, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .6) 75%, rgba(0, 0, 0, .0) 100%);
          width: 100%;
          color: white;
          text-align: start;
          padding: .08rem .18rem;
          box-sizing: border-box;
          min-width: 0;
          border-bottom-left-radius: .16rem;
          border-bottom-right-radius: .16rem;

          .desc {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:first-child {
              font-size: .2rem;
            }

            &:last-child {
              font-size: .15rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              white-space: normal;
              overflow: hidden;
              margin-top: .2rem;
              min-height: 0.34rem;
            }

            .desc-icon {
              width: 0.15rem;
              height: 0.15rem;
              position: relative;
              top: 0.02rem;
            }
          }
        }

        .img {
          display: flex;
          width: 368px;
          height: 200px;
          object-fit: cover;
          border-radius: .16rem;
        }
      }
    }
  }
}
.imgDiv:hover {
  transform: translateY(-.04rem);
  transition-duration: 0.5s;
}
</style>
