<template>
  <div class="orderDetails">
    <div class="ticketDiv">
      <div class="ticket1">
        <img :src="productInfo?.img ? productInfo.img : ticketImg" />
        <div class="ticket_header">
          <div class="title1">
            {{ productInfo.productName }} 
          </div>
          <div class="title2">
            {{ productInfo.priceTypeName }} / {{ productInfo.count}} {{ getTicket(productInfo.count) }}
          </div>
          <div class="title2">{{ $t('app.non_refundable') }}</div>
          <div class="ticketDate">{{ productInfo.usingDate }}</div>
          <div style="display: flex; justify-content: space-between; width: 616px;">
            <div>{{ $t("app.total") }}</div>
            <div style="font-weight: 600">￥{{ priceFormat(productInfo.price) }}</div>
        </div>
        </div>
      </div>
    </div>

    <div class="bodyDiv">
      <div class="title">{{ $t("app.liaisonDetail") }}</div>
      <!-- <div class="infoDiv">
        <div class="infoType">{{ $t("app.name") }}</div>
        <div class="infoValue">{{ name }}</div>
      </div> -->
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.eMail") }}</div>
        <div class="infoValue">{{ eMail }}</div>
      </div>
    </div>

    <div class="allIdsDiv">
      <div class="title">{{ $t("app.More_Detail") }}</div>
      <el-row>
        <el-col :span="8" class="infoDiv">
            <div class="infoType">{{ $t("app.System_Order_ID") }}</div>
            <div class="infoValue">{{ System_Order_ID }}</div>
        </el-col>
        <el-col :span="8" class="infoDiv">
            <div class="infoType">{{ $t("app.Payment_Order_ID") }}</div>
            <div class="infoValue">{{ Payment_Order_ID }}</div>
        </el-col>
        <el-col :span="8" class="infoDiv">
            <div class="infoType">{{ $t("app.Ticket_Order_ID") }}</div>
            <div class="infoValue">{{ Ticket_Order_ID }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8" class="infoDiv">
            <div class="infoType">{{ $t("app.System_Order_Status") }}</div>
            <div class="infoValue">{{ System_Order_Status }}</div>
        </el-col>
        <el-col :span="16" class="infoDiv">
            <div class="infoType">{{ $t("app.Ticket_PDF_Url") }}</div>
            <div class="infoValue">{{ Ticket_PDF_Url }}</div>
        </el-col>
      </el-row>
    </div>

    <div class="footer_btn">
      <el-button round @click="$emit('closeDialog')">{{ $t('app.close') }}</el-button>
    </div>
  </div>
</template>

<script>
import apis from "../../../utils/apis";
import { NavBar } from "vant";
import Vue from "vue";
import NoPicture from "../../../assets/img/no_picture.png";

Vue.use(NavBar);

let { login } = apis;
let { OrderDetail } = apis;

export default {
//   components: { ContactUs },
  props: ["orderId"],
  data() {
    return {
      title: this.$t("app.OrderDetails"),

      ticketImg: NoPicture,
        // "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/27/79/12/10/caption.jpg?w=600&h=-1&s=1",

      name: "",
      eMail: "",
      System_Order_ID: "",
      Payment_Order_ID: "",
      Ticket_Order_ID: "",
      System_Order_Status: "",
      Ticket_PDF_Url: "",

      productInfo: {},
      orderInfo: {},
    };
  },

  // watch: {
  //   orderId: {
  //     handler(val) {
  //       this.getOrderDetails();
  //     }, immediate: true
  //   }
  // },

  methods: {
    getTicket(num){
      if (!isNaN(num)) {
        return Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets')
      } else {
        return this.$t('app.tickets');
      }
    },
    getOrderDetails() {

      this.productInfo = {};
      this.orderInfo = {};
      this.name = '';
      this.eMail = '';
      this.System_Order_ID = '';
      this.Payment_Order_ID = '';
      this.Ticket_Order_ID = '';
      this.System_Order_Status = '';
      this.Ticket_PDF_Url = '';

      let params = {
        mail: localStorage.getItem("email"),
        orderId: this.orderId,
      };
      OrderDetail({ ...params }, (data) => {
        this.orderInfo = data.data.orderInfo;
        this.productInfo = data.data.productInfo;

        this.Ticket_Order_ID = this.productInfo.easygoId;
        this.name = this.productInfo.contactUserInfo ? this.productInfo.contactUserInfo.userName : "";
        this.eMail = this.productInfo.contactUserInfo ? this.productInfo.contactUserInfo.email : "";

        this.System_Order_ID = this.productInfo.orderId;
        this.Payment_Order_ID = this.productInfo.qfpayId;
        this.Ticket_Order_ID = this.productInfo.easygoId;
        this.System_Order_Status =
          this.orderInfo.orderStatus == 1
            ? this.$t("app.Success")
            : this.orderInfo.orderStatus == 2
            ? this.$t("app.Failure")
            : this.orderInfo.orderStatus == 3
            ? this.$t("app.Processing")
            : this.$t("app.Processing");
        this.Ticket_PDF_Url = this.productInfo.pdfurl;
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar__content {
  height: 1rem;
}

/deep/ .van-icon {
  color: black;
  font-size: 0.36rem;
}

/deep/ .van-nav-bar__title {
  font-weight: 700;
}

.orderDetails {
  background: #f8f8f8;
  font-size: 0.26rem;

  .ticketDiv {
    border-bottom: 1px solid #000000;
    background: white;
    padding: 30px;

    .ticket1 {
      display: flex;
      text-align: left;
      width: 100%;
      align-items: center;

      .ticket_header {
        display: flex;
        flex-direction: column;
        height: 222px;
      }

      img {
        border-radius: 0.08rem;
        height: 222px;
        width: 290px;
        margin-right: 0.2rem;
      }

      .title1 {
        color: black;
        font-weight: 500;
        font-size: 0.3rem;
        flex: 1;
      }

      .title2 {
        color: #9a9a9a;
        font-size: 0.26rem;
        flex: 1;
      }

      .ticketDate {
        font-weight: 600;
        text-align: left;
        flex: 1;
      }
    }

  }

  .infoType {
    font-weight: bold;
  }

  .allIdsDiv {
    padding: 30px;
    background: #ffffff;
    align-items: start;
    font-size: 0.2rem;
    text-align: left;
    color: #000000;

    .title {
      font-size: 0.28rem;
    }

    .infoDiv {
      margin-top: 0.2rem;
      .infoValue {
        margin-top: 0.1rem;
        font-size: 0.18rem;
      }
    }
  }

  .payBtn {
    margin-top: 2rem;
    background: #f2b203;
    color: black;
    width: 6rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 0.2rem;
    margin-left: 0.75rem;
    margin-bottom: 0.56rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.46rem;
  }

  .bodyDiv {
    padding: 30px;
    background: #ffffff;
    align-items: start;
    font-size: 0.2rem;
    text-align: left;
    color: #000000;
    border-bottom: 1px solid #000000;

    .title {
      font-size: 0.28rem;
    }

    .infoDiv {
      display: flex;
      padding: 0.1rem 0;

      .infoType {
        width: 15%;
      }
    }
  }

  /deep/ .footer_btn {
    background: #fff;
    display: flex;
    justify-content: center;
    .el-button {
      background: #000000;
      color: #fff;
      width: 321px;
      height: 54px;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>
