<template>
    <div>
        <Loginlogo></Loginlogo>
        <div class="basic">
          <h1 class="basic_title">{{ $t('app.enterBasicInfo') }}</h1>
          <van-form ref="vForm" @submit="onSubmit">
            <p class="label">{{ $t('app.userName') }}</p>
            <van-cell-group inset style="margin-bottom: 2%">
                <van-field :rules="[{ required: true, message: $t('app.UserName_required') }]" name="value"
                        v-model="userName" placeholder="" focus />
            </van-cell-group>
            <p class="label">{{ $t('app.Email_address') }}</p>

            <van-cell-group inset style="margin-bottom: 2%">
                <van-field :rules="[{ required: true, message: $t('app.Email_required'), trigger: 'onBlur' }, {
                validator: (value) => {
                return /.+@.+\..+/.test(value);
                }, message: $t('app.Email_required_must'),
                trigger: 'onBlur',
                }]" name="value" v-model="email" placeholder="" />
            </van-cell-group>

            <p class="label">{{ $t('app.Address') }}</p>
            <van-cell-group inset style="margin-bottom: 2%">
                <van-field :rules="[{ required: true, message: $t('app.Address_required') }]" name="value"
                        v-model="address" placeholder="" focus />
            </van-cell-group>

            <div style="margin-top: 0.5rem;">
                <van-button round block type="info" native-type="submit">{{ $t('app.submit') }}</van-button>
            </div>
          </van-form>
        </div>
        <div class="connect">
          <p class="cookie_state">{{ $t("app.cookie_state") }}</p>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Toast } from 'vant';
import Loginlogo from "../../components/Login/Loginlogo.vue";
import apis from "../../utils/apis";
Vue.use(Toast)

let { updateUser } = apis;

export default {
    props: {
        preview: {
            type: Boolean,
            default: false,
        }
    },
    components: { Loginlogo },
    data() {
        return {
            userName: localStorage.getItem('name') === 'null' ? '' : localStorage.getItem('name'),
            email: localStorage.getItem('email') === 'null' ? '' : localStorage.getItem('email'),
            address: '',
        }
    },
    watch: {
      preview: {
        handler(val) {
          if(val) {
            this.$nextTick(() => {
              this.userName = localStorage.getItem('name') === 'null' ? '' : localStorage.getItem('name');
              this.email = localStorage.getItem('email') === 'null' ? '' : localStorage.getItem('email');
            })
          }
        }, 
        immediate: true
      }
    },
    methods: {
        onSubmit() {
          let params = {
            userId: localStorage.getItem('userId'),
            userName: this.userName,
            email: this.email,
            address: this.address,
          }
          updateUser(params, (data) => {
            if(data.code == 200) {
                Toast.success(data.msg);
                localStorage.setItem("isFirstLogin", 'b');
                this.$emit('close');
            }
          })
        }
    }
}
</script>
<style lang="less" scoped>
.basic {
  .basic_title {
    text-align: left;
    margin: 30px 0;
    color: #000000;
  }
  .label {
    text-align: left;
  }
  .van-button {
    background-color: #333;
    border: none;
    margin-bottom: .3rem;
  }
}
/deep/ .van-cell-group--inset {
  margin: 0;
}
/deep/ .van-cell {
  padding: 0;
}
/deep/.van-field__body {
  height: 47px;
  border: 1px solid #E0E0E0;
  border-radius: .16rem;
  padding-left: .24rem;
}
.connect {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .cookie_state {
    font-size: 15px;
    font-weight: bold;
    width: 280px;
    margin-top: 30px;
    line-height: 20px;
    text-align: center;
  }
}
</style>